export enum APP_NAVIGATE_DIRECTION {
	NEXT,
	PREVIOUS,
	REPLACE,
}

export const APP_NAVIGATE_TRANSITION_DURATION = 1000; // ms

// App path (React Router syntax)
export const APP_PATH = {
	watchlist: {
		index: "/watchlist",
		edit: "/watchlist/edit/:watchlistId",
	},
	fx: {
		index: "/fx/:symbol",
		calculator: "/currency-calculator",
		order: {

			form: "/order/form/step-1",
			account: "/order/form/step-2",
			confirmation: "/order/confirmation",
			placement: "/order/place",
			details: "/order/details",
			statusList: "/order/status"
		},
	},
	news: {
		index: "/news",
	},
	chart:{
		index: "/fx",
	}
};

// App header controls
// MUST same with route in App.tsx
export enum APP_HEADER_BUTTON_TYPE {
	BACK,
	CLOSE,
	BACK_TO_ENTRY_POINT,
	MENU,
}
export const APP_HEADER_HIDDEN_PATH = ["/order/place"];
export const APP_HEADER_TITLE_SPECIAL_HANDLING_PATH = [
	APP_PATH.fx.order.form,
	APP_PATH.fx.order.account,
];
export const APP_HEADER_END_BUTTON_PATH_MAP = {
	[APP_PATH.watchlist.index]: [APP_HEADER_BUTTON_TYPE.MENU],
	[APP_PATH.fx.index]: [APP_HEADER_BUTTON_TYPE.MENU],

	[APP_PATH.fx.order.form]: [APP_HEADER_BUTTON_TYPE.BACK_TO_ENTRY_POINT],
	[APP_PATH.fx.order.account]: [APP_HEADER_BUTTON_TYPE.BACK_TO_ENTRY_POINT],
	[APP_PATH.fx.order.confirmation]: [
		APP_HEADER_BUTTON_TYPE.BACK_TO_ENTRY_POINT,
	],
	[APP_PATH.fx.order.placement]: [APP_HEADER_BUTTON_TYPE.BACK_TO_ENTRY_POINT],
};

export const DEBUG_MODE_TOGGLE_COUNT = 5;
export const MOCK_USER_PASSWORD = "123456";
export const SHOW_MYT_TIMEZONE_SYMBOL = false
