import { useEffect, useState } from "react";
import { createTheme } from "@mui/material";

import {
	CurrencyType,
	FXOrderFormType,
	FXTradeFormType,
	FXTradeType,
	FXType,
} from "../types/fx.type";
import {
	FX_TRADE_CURRENCY_TYPE,
	MAX_FX_TRADE_AMOUNT,
} from "../constants/fx.constant";
import { useAppDispatch, useAppSelector } from "../utils/store.utils";
import { selectUser } from "../slice/user";
import {
	selectCurrencies,
	selectFXCalculatorForm,
	selectFXList,
	selectFXOrderForm,
	updateFXTradeForm,
	updateFXTradeFormAmount,
} from "../slice/fx";
import { exchange, swapCurrencyType } from "../formatters/fx";
import {
	formatNumPadInput,
	formatNumPadValueToInput,
} from "../formatters/common";

const deepEqual = require("deep-equal");

/**
 * This hook provides functionality for the FXCalculator component.
 *
 * @param {Object} options - The options object.
 * @param {string} options.form - The form type ("calculator" or "order").
 * @param {HTMLInputElement|null} options.sourceInputElement - The source input element.
 * @param {HTMLInputElement|null} options.targetInputElement - The target input element.
 *
 */
const useFXCalculator = ({
							 form,
							 sourceInputElement,
							 targetInputElement,
						 }: {
	form: FXTradeFormType;
	sourceInputElement?: HTMLInputElement | null;
	targetInputElement?: HTMLInputElement | null;
}) => {
	const user = useAppSelector(selectUser);
	const currencies = useAppSelector(selectCurrencies);
	const fxList = useAppSelector(selectFXList);
	const fxTradeForm = useAppSelector(
		form === "calculator" ? selectFXCalculatorForm : selectFXOrderForm
	);
	const fxOrderForm = useAppSelector(selectFXOrderForm);
	const dispatch = useAppDispatch();
	const muiTheme = createTheme();
	const [availableCurrencies, setAvailableCurrencies] = useState<
		CurrencyType[]
	>([]);
	const [focusingCurrencySelector, setFocusingCurrencySelector] =
		useState<FX_TRADE_CURRENCY_TYPE>(FX_TRADE_CURRENCY_TYPE.SOURCE);
	const [focusingInput, setFocusingInput] = useState<Element | null>(null);
	const [isFooterButtonContainerShow, setIsFooterButtonContainerShow] =
		useState<boolean>(true);
	const [isNumPadOpen, setIsNumPadOpen] = useState<boolean>(false);
	const [isCurrencySelectorDialogOpen, setIsCurrencySelectorDialogOpen] =
		useState<boolean>(false);

	/**
	 * Handles the opening of the currency selector dialog.
	 *
	 * @param {FX_TRADE_CURRENCY_TYPE} currencyType - The currency type being focused.
	 * @param {CurrencyType[]} availableCurr - The list of available currencies.
	 * @returns {void}
	 */
	const handleCurrencySelectorOpen = (
		currencyType: FX_TRADE_CURRENCY_TYPE,
		availableCurr: CurrencyType[]
	) => {
		setFocusingCurrencySelector(currencyType);
		setAvailableCurrencies(availableCurr);
		setIsCurrencySelectorDialogOpen(true);
	};

	/**
	 * Handles the closing of the currency selector dialog.
	 * Updates the state to set the currency selector dialog as closed.
	 * Resets the available currencies to the user's available currencies.
	 *
	 * @returns {void}
	 */
	const handleCurrencySelectorClose = () => {
		setIsCurrencySelectorDialogOpen(false);
		setAvailableCurrencies(user.availableCurrencies);
	};

	/**
	 * Handles the click event for the number pad input.
	 *
	 * @param {HTMLInputElement | null} element - The input element to scroll into view.
	 * @returns {Function} - A function that is called when the click event occurs.
	 */
	const handleNumPadInputClick = (element: HTMLInputElement | null) => () => {
		setTimeout(() => {
			setIsFooterButtonContainerShow(false);
			setIsNumPadOpen(true);
		}, 1);
		if (element)
			setTimeout(
				() => {
					element.scrollIntoView({
						behavior: "smooth",
						block: "start",
						inline: "nearest",
					});
				},
				isNumPadOpen ? 1 : muiTheme.transitions.duration.enteringScreen
			);
	};

	/**
	 * Updates the focusing input amount based on the provided type and number.
	 *
	 * @param {string} type - The type of update, can be "add" or "remove".
	 * @param {number} [num] - The number to be added or removed from the input amount.
	 * @returns {void}
	 */
	const updateFocusingInputAmount = (type: "add" | "remove", num?: number) => {
		let updatedFXTradeForm: FXTradeType = JSON.parse(
			JSON.stringify(fxTradeForm)
		);
		let selectingKey: FX_TRADE_CURRENCY_TYPE | null = null;

		if (focusingInput === sourceInputElement) {
			selectingKey = FX_TRADE_CURRENCY_TYPE.SOURCE;
		} else if (focusingInput === targetInputElement) {
			selectingKey = FX_TRADE_CURRENCY_TYPE.TARGET;
		} else return;

		// Prevent prepend 0 into input text
		if (
			type === "add" &&
			fxTradeForm[selectingKey].amount.input === "" &&
			num === 0
		)
			return;

		// Handle the selecting currency
		const selectingCurrency = currencies.find(
			(v) =>
				v.currency ===
				fxTradeForm[selectingKey as FX_TRADE_CURRENCY_TYPE].currency
		);
		const newInput =
			type === "add"
				? fxTradeForm[selectingKey].amount.input + num?.toString()
				: fxTradeForm[selectingKey].amount.input.slice(0, -1);
		const newValueText = formatNumPadInput(newInput);
		const newValue = Number.parseFloat(newValueText);

		if (!selectingCurrency || isNaN(newValue) || newValue > MAX_FX_TRADE_AMOUNT)
			return; // skip if currency not found or non-number input or greater than default max amount

		updatedFXTradeForm[selectingKey].amount = {
			...fxTradeForm[selectingKey].amount,
			input: newInput,
			value: newValue,
		};


		dispatch(updateFXTradeForm({ form, data: updatedFXTradeForm }));
		// console.log('updateFXTradeForm  from useFXCalculator 1')
		// Call exchange out of this hook if needed
	};


	/**
	 * Handles the change in future input for the FX order form.
	 *
	 * @param {FXOrderFormType} fxOrderForm - The current FX order form.
	 * @param {number} targetRate - The target rate for the future input.
	 */
	const handleFutureInputChange = (fxOrderForm: FXOrderFormType, targetRate: number) => {
		const invertedTargetRate = 1 / targetRate;
		const userPrevEditedField: FX_TRADE_CURRENCY_TYPE = fxOrderForm.selectingCurrencyType; // "source" or "target"
		const oppositeOfUserPrevEditedField = swapCurrencyType(userPrevEditedField);
		const isSourceFirstInPair = fxOrderForm.source.currency === fxOrderForm.fx?.currencies[0];
		let calculatedAmount: number;
		if (userPrevEditedField === FX_TRADE_CURRENCY_TYPE.SOURCE) {
			calculatedAmount = fxOrderForm.source.amount.value * (isSourceFirstInPair ? invertedTargetRate : targetRate);
		} else {
			calculatedAmount = fxOrderForm.target.amount.value * (isSourceFirstInPair ? targetRate : invertedTargetRate);
		}
		const newAmount = {
			...fxOrderForm[oppositeOfUserPrevEditedField].amount,
			input: formatNumPadValueToInput(calculatedAmount),
			value: calculatedAmount
		};

		dispatch(updateFXTradeFormAmount({
			form,
			data: {
				type: oppositeOfUserPrevEditedField,
				amount: newAmount,
			},
		}));
	};

	/**
	 * Handles the exchange of currencies in the FXTradeForm.
	 *
	 * @param {FXType} fxObject - The FX object.
	 * @param {any} fxOrderFormTemp - The temporary FX order form.
	 */
	const handleExchange = (fxObject?: FXType, fxOrderFormTemp?: any) => {
		let updatedFXOrderForm: FXTradeType = fxOrderFormTemp || JSON.parse(
			JSON.stringify(fxTradeForm)
		);

		//if (fxObject) {
		if (
			fxTradeForm.selectingCurrencyType === FX_TRADE_CURRENCY_TYPE.TARGET &&
			!updatedFXOrderForm.target.currency
		)
			return;
		const oppositeKey = swapCurrencyType(fxTradeForm.selectingCurrencyType);
		const exchanged = exchange(
			updatedFXOrderForm[fxTradeForm.selectingCurrencyType].amount.value,
			updatedFXOrderForm.source.currency,
			fxTradeForm.selectingCurrencyType,
			fxObject,
			Number(fxOrderForm?.futureTargetRate && fxOrderForm?.future) ? Number(fxOrderForm.futureTargetRate) : undefined
		);

		// console.log("before that", fxOrderForm?.futureTargetRate)

		// console.log("here", Number(fxOrderForm?.futureTargetRate && fxOrderForm?.future) ? Number(fxOrderForm.futureTargetRate) : undefined)


		const newAmount = {
			...fxTradeForm[oppositeKey].amount,
			input: formatNumPadValueToInput(exchanged),
			value: exchanged,
		};

		// console.log('exchanged', exchanged)

		/*console.log(
			"handleExchange: ",
			updatedFXOrderForm[fxTradeForm.selectingCurrencyType].amount.value,
			updatedFXOrderForm[oppositeKey].amount,
			updatedFXOrderForm.source.currency,
			fxTradeForm.selectingCurrencyType,
			fxObject
		);*/

		dispatch(
			updateFXTradeFormAmount({
				form,
				data: {
					type: oppositeKey,
					amount: newAmount,
				},
			})
		);
		//}
		// console.log('updateFXTradeFormAmount2 from useFXCalculator')
	};

	return {
		availableCurrencies,
		setAvailableCurrencies,

		focusingCurrencySelector,
		setFocusingCurrencySelector,

		focusingInput,
		setFocusingInput,

		isFooterButtonContainerShow,
		setIsFooterButtonContainerShow,

		isNumPadOpen,
		setIsNumPadOpen,

		isCurrencySelectorDialogOpen,
		setIsCurrencySelectorDialogOpen,

		handleCurrencySelectorOpen,
		handleCurrencySelectorClose,
		handleNumPadInputClick,
		updateFocusingInputAmount,
		handleExchange,
		handleFutureInputChange
	};
};

export default useFXCalculator;
