import * as React from 'react';
import Layout from "../../../components/common/Layout";
import Helmet from "react-helmet";
import {useIntl} from "react-intl";
import styled from "styled-components";
import {capitalizeFirstLetter} from "../../../formatters/common/capitalizeFirstLetter";
import {
    cancelFutureOrderSnackbar,
    determineCurrencies,
    extractNonOverlap, getCurrencyPart,
    OrderStatusColorizedOval
} from "./OrderCardContent";
import {OrderStatusEnum} from "../enums/OrderStatusEnum";
import Button from "../../../components/styled/buttons/Button";
import useNavigateThrottle from "../../../hooks/useNavigateThrottle";
import {selectFxOrderStatusDetailPage} from "../../../slice/fx";
import {useAppDispatch, useAppSelector} from "../../../utils/store.utils";
import OrderDetailDataListFO from "./OrderDetailDataListFO";
import fxAPI from "../../../api/fx.api";
import {useEffect, useState} from "react";
import AlertDialog from "../../../components/common/dialog/AlertDialog";
import {formatURL} from "../../../formatters/common";
import OrderDetailDataListMO from "./OrderDetailDataListMO";
import {cleanRET_APPandRET_DCCookie} from "../../../utils/cleanCookieRET_APPandRET_DCCookie";
import {formatNumberWithThousandSeparator} from "../../../formatters/common/formatNumberWithThousandSeparator";
import {OrderStatusInfoReal} from "../interface/OrderStatusInfo";
import {ACTIVATED_ACCOUNT_ROLE} from "../../../constants/common.constant";
import {clearPasswordTimeoutInterval, requestPasswordAuthentication} from "../../../utils/integration.utils";
import {IntegrationMessageDataType} from "../../../types/integration.type";
import {MAE_APP_RESPONSE_EVENT_TYPE} from "../../../constants/integration.constant";
import {updateAPIAxiosInstanceToken} from "../../../utils/api.utils";
import {closeSnackbar} from "notistack";
import {selectUser} from "../../../slice/user";
import {DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT} from "../../../constants/fx.constant";
import {updateIsAfterPressingCancelOrder} from "../../../slice/app";
import {CANCEL_FUTURE_ORDER_FAIL} from "../../../constants/errorMsg.constant";
import {ErrorObjForToastBar} from "../../../types/errorObjType.type";
import {processApiError} from "../../../utils/errorHandling.utils";

type Props = {};
export const OrderDetail = (props: Props) => {
    const intl = useIntl();
    const navigate = useNavigateThrottle();
    const [isCancelFutureOrderDialogOpen, setIsCancelFutureOrderDialogOpen] =
        useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const orderDetailObj = useAppSelector(selectFxOrderStatusDetailPage)
    const isFuture: boolean = orderDetailObj.orderType === 2
    const sellOrBuyCurr = determineCurrencies(
        orderDetailObj.dealtCurr,
        extractNonOverlap(
            orderDetailObj.symbol,
            orderDetailObj.dealtCurr
        ),
        orderDetailObj.buy
    )
    const errorTimeout = useAppSelector((state) => state.errorTimeout);
    const user = useAppSelector(selectUser);
    const dispatch = useAppDispatch()

    useEffect(() => {
        cleanRET_APPandRET_DCCookie()
    }, []);

    const handleButtonClick = (status: OrderStatusEnum) => {
        switch (status) {
            case OrderStatusEnum.PENDING:
                setIsCancelFutureOrderDialogOpen(true)
                break
            case OrderStatusEnum.COMPLETED:
            case OrderStatusEnum.EXPIRED:
            case OrderStatusEnum.UNSUCCESSFUL:
                handleOrderFutureOrderAgain()
                break
            case OrderStatusEnum.CANCELLED:
                navigate(-1)
        }
    }

    const handleOrderFutureOrderAgain = () => {
        if (ACTIVATED_ACCOUNT_ROLE.includes(user.type)) {
            window.addEventListener("message", handleAuthenticate); // iOS
            document.addEventListener("message", onMessageHandler); // Android
            requestPasswordAuthentication();
        }
    }

    const handleAuthenticate = (message: MessageEvent<string>) => {
        try {
            const formattedData: IntegrationMessageDataType = JSON.parse(
                message.data
            );
            switch (formattedData.type) {
                case MAE_APP_RESPONSE_EVENT_TYPE.PASSWORD:
                    if (errorTimeout.passwordResponseRemainTime > 0) {
                        const token = formattedData.data //this is L3 token, according to the DRS
                        if (token) {
                            updateAPIAxiosInstanceToken(token)
                        }
                        navigate(
                            formatURL("/order/form/step-1", {
                                from: sellOrBuyCurr.sellCurr,
                                to: sellOrBuyCurr.buyCurr
                            })
                        );
                        clearPasswordTimeoutInterval()
                        closeSnackbar()
                        window.removeEventListener("message", handleAuthenticate); // iOS
                        document.removeEventListener("message", onMessageHandler); // Android
                    }
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onMessageHandler: EventListener = (event: any) => {
        if (
            event.type &&
            event.type === "message" &&
            event.data &&
            typeof event.data === "string"
        ) {
            handleAuthenticate(event);
        }
    };

    const handleCancelFutureOrder = async () => {
        if(isButtonDisabled) return;
        setIsButtonDisabled(true);
        setTimeout(()=>{
            setIsCancelFutureOrderDialogOpen(false);
        }, 1000)
        try {
            const response = await fxAPI.cancelFutureOrder(orderDetailObj.orderId);
            if (
                !response.data ||
                response.data.responseCode !== "0" ||
                !response.data.data
            ) throw {
                errorMsg: CANCEL_FUTURE_ORDER_FAIL,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar;
            setTimeout(()=>{
                cancelFutureOrderSnackbar();
                navigate(-1);
                dispatch(updateIsAfterPressingCancelOrder(true));
            }, 2000)
        } catch (error) {
            processApiError(error, undefined); // this is non-FATAL
        } finally {
        }
    };

    const showAmountOrContraAmount = (orderDetailObj: OrderStatusInfoReal, isBuyCurr: boolean) => {
        const sellOrBuyCurr = determineCurrencies(
            orderDetailObj.dealtCurr,
            extractNonOverlap(
                orderDetailObj.symbol,
                orderDetailObj.dealtCurr
            ),
            orderDetailObj.buy
        )

        let limit: number; // this will be used only if it is a  future order that does not have contraAmount

        if (getCurrencyPart(orderDetailObj.symbol, true) === sellOrBuyCurr.buyCurr) {
            limit = Number(orderDetailObj.limit)
        } else {
            limit = 1 / Number(orderDetailObj.limit)
        }

        if (orderDetailObj.buy === true) {
            if (isBuyCurr) {
                return orderDetailObj.amount
            } else {
                if (orderDetailObj.contraAmount) {
                    return orderDetailObj.contraAmount
                } else {
                    return (Number(orderDetailObj.amount) * limit).toFixed(DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT)
                }
            }
        } else {
            if (isBuyCurr) {
                if (orderDetailObj.contraAmount) {
                    return orderDetailObj.contraAmount
                } else {
                    return (Number(orderDetailObj.amount) / limit).toFixed(DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT)
                }
            } else {
                return orderDetailObj.amount
            }
        }
    }
    return (
        <Layout
            title={intl.formatMessage({
                id: getTitle(isFuture),
            })}
        >
            <Helmet>
                <title>
                    Order Details
                </title>
            </Helmet>
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <div style={{fontWeight: 700, fontSize: 24, marginBottom: 5}}>
                    {sellOrBuyCurr.sellCurr} {formatNumberWithThousandSeparator(showAmountOrContraAmount(orderDetailObj, false))}
                </div>
                <div style={{fontWeight: 400, fontSize: 14, marginBottom: 12}}>
                    {sellOrBuyCurr.buyCurr} {formatNumberWithThousandSeparator(showAmountOrContraAmount(orderDetailObj, true))}
                </div>
                <OrderStatusColorizedOval
                    style={{marginBottom: 20}}
                    status={orderDetailObj.orderStatus}
                >
                    {capitalizeFirstLetter(orderDetailObj.orderStatus)}
                </OrderStatusColorizedOval>
            </div>
            <ScrollableContainer>
                {isFuture &&
                    <OrderDetailDataListFO
                        orderDetailObj={orderDetailObj}
                    />
                }
                {!isFuture &&
                    <
                        OrderDetailDataListMO orderDetailObj={orderDetailObj}
                    />
                }
            </ScrollableContainer>
            <div style={{
                display: 'flex',
                justifyContent: "center",
                padding: "44px 0px 36px"
            }}>
                <Button
                    style={{width: "90%", height: 48,}}
                    theme="primary"
                    onClick={() => handleButtonClick(orderDetailObj.orderStatus)}
                >
                    {intl.formatMessage({
                        id: `${getButtonText(orderDetailObj.orderStatus)}`,
                    })}
                </Button>
            </div>
            <AlertDialog
                titleComp={intl.formatMessage({
                    id: "app.page.orderStatus.button.title.cancelFutureOrder",
                })}

                contentComp={intl.formatMessage({
                    id: "app.page.orderStatus.button.text.sureToCancelFutureOrder",
                })}
                confirmButtonText={intl.formatMessage({
                    id: "app.page.orderStatus.button.text.confirmDelete",
                })}
                open={isCancelFutureOrderDialogOpen}
                onClose={() => setIsCancelFutureOrderDialogOpen(false)}
                onConfirm={handleCancelFutureOrder}
            />
        </Layout>
    )
        ;
};

const ScrollableContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
`;

const getButtonText = (orderStatus: OrderStatusEnum) => {
    let buttonText: string = ""
    switch (orderStatus) {
        case OrderStatusEnum.PENDING:
            buttonText = "app.page.orderStatus.details.button.cancelOrder"
            break
        case OrderStatusEnum.COMPLETED:
        case OrderStatusEnum.EXPIRED:
        case OrderStatusEnum.UNSUCCESSFUL:
            buttonText = "app.page.orderStatus.details.button.orderAgain"
            break

        case OrderStatusEnum.CANCELLED:
            buttonText = "app.page.orderStatus.details.button.done"

    }
    return buttonText
}

const getTitle = (isFutureOrder: boolean): string => {
    if (isFutureOrder) {
        return "app.page.orderStatus.orderDetail.header.title.futureOrder"
    } else {
        return "app.page.orderStatus.orderDetail.header.title.marketOrder"
    }
}
