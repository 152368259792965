import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ClickAwayListener, Drawer, backdropClasses } from "@mui/material";
import styled from "styled-components";

import NumPadKey from "./NumPadKey";
import SlideUpTransition from "../transition/SlideUpTransition";

import { NumPadProps } from "../../../types/common.type";

import { ReactComponent as NumPadDoneIcon } from "../../../assets/icons/numpad-done.svg";
import { ReactComponent as NumPadDeleteIcon } from "../../../assets/icons/numpad-delete.svg";

enum NUMPAD_KEY_MAP {
	ONE = "1",
	TWO = "2",
	THREE = "3",
	FOUR = "4",
	FIVE = "5",
	SIX = "6",
	SEVEN = "7",
	EIGHT = "8",
	NINE = "9",
	BACKSPACE = "backspace",
	ZERO = "0",
	CONFIRM = "confirm",
}

const NON_NUMBER_KEY_MAP = {
	[NUMPAD_KEY_MAP.BACKSPACE]: <NumPadDeleteIcon />,
	[NUMPAD_KEY_MAP.CONFIRM]: <NumPadDoneIcon />,
};

const StyledNumPadContainer = styled.div`
	display: flex;
	justify-content: center;
	background-color: var(--theme-color-primary);
`;

const StyledNumPad = styled.div`
	display: flex;
	flex-wrap: wrap;
	max-width: 375px;
	flex-grow: 1;
	padding: 18px;
	padding-bottom: calc(18px + constant(safe-area-inset-bottom));
	padding-bottom: calc(18px + env(safe-area-inset-bottom));
`;

const NumPad = ({
	open,
	onNumberKeyClick,
	onBackspaceKeyClick,
	onClose,
	onEntered,
	onExited,
}: NumPadProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(open);

	useEffect(() => {
		setIsOpen(open);
	}, [open]);

	/**
	 * A function that handles a click event on a numeric keypad key.
	 *
	 * @param {NUMPAD_KEY_MAP} key - The key that was clicked on the numeric keypad.
	 * @returns {Function} A function that handles the click event.
	 */
	const handleClick =
		(key: NUMPAD_KEY_MAP) => (event: React.MouseEvent<HTMLDivElement>) => {
			switch (key) {
				case NUMPAD_KEY_MAP.BACKSPACE:
					onBackspaceKeyClick();
					break;
				case NUMPAD_KEY_MAP.CONFIRM:
					onClose();
					break;
				default:
					const num = Number(key);
					if (!isNaN(num)) onNumberKeyClick(num);
			}
		};

	/**
	 * Closes the handle based on the given event and reason.
	 *
	 * @param {Object} event - The event object.
	 * @param {string} reason - The reason for closing the handle. Possible values are "backdropClick" or "escapeKeyDown".
	 */
	const handleClose = (
		event: {},
		reason: "backdropClick" | "escapeKeyDown"
	) => {
		onClose();
	};

	/**
	 * Handles the click away event.
	 *
	 * @param {MouseEvent | TouchEvent} event - The click away event.
	 * @returns {void}
	 */
	const handleClickAway = (event: MouseEvent | TouchEvent) => {
		/*if (!(event.target instanceof HTMLInputElement) && open) {
			setIsOpen(false);
			onClose();
		}*/
	};

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
			<SlideUpTransition
				in={isOpen}
				onEntered={onEntered}
				onExited={onExited}
				mountOnEnter
				unmountOnExit
			>
				<StyledNumPadContainer
					className={"numpad-main"}
				>
					<StyledNumPad>
						{Object.keys(NUMPAD_KEY_MAP).map((v, i) => (
							<NumPadKey
								key={`numpad-key-${v}`}
								hasHighlight
								onClick={handleClick(
									NUMPAD_KEY_MAP[v as keyof typeof NUMPAD_KEY_MAP]
								)}
							>
								{NON_NUMBER_KEY_MAP[
									NUMPAD_KEY_MAP[
										v as keyof typeof NUMPAD_KEY_MAP
									] as keyof typeof NON_NUMBER_KEY_MAP
								] || NUMPAD_KEY_MAP[v as keyof typeof NUMPAD_KEY_MAP]}
							</NumPadKey>
						))}
					</StyledNumPad>
				</StyledNumPadContainer>
			</SlideUpTransition>
		</ClickAwayListener>
	);
};

export default NumPad;
