import {useEffect, useMemo, useRef, useState} from "react";
import {useIntl} from "react-intl";
import Helmet from "react-helmet";
import styled from "styled-components";
import {enqueueSnackbar} from "notistack";

import Layout from "../../components/common/Layout";
import Button from "../../components/styled/buttons/Button";
import FooterButtonContainer from "../../components/styled/buttons/FooterButtonContainer";
import FXAmountSection from "../../components/order/FXAmountSection";
import BankAccountSelectorSection from "../../components/order/account/BankAccountSelectorSection";
import TNCSection from "../../components/order/account/TNCSection";

import {UserBankAccountType} from "../../types/user.type";
import {FXOrderFormBankAccountType} from "../../types/fx.type";
import {ALLOWED_RECEIVING_ROLE, BASE_CURRENCY} from "../../constants/common.constant";
import {useAppDispatch, useAppSelector} from "../../utils/store.utils";
import {
    selectFXOrderForm,
    updateFXOrderFormBankAccount,
    updateFXOrderFormBankAccountByType, updateFXOrderFormSourceBankAccount, updateFXOrderFormTargetBankAccount,
} from "../../slice/fx";
import {emitErrorToApp} from "../../utils/integration.utils";
import {selectUser} from "../../slice/user";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import {formatURL} from "../../formatters/common";
import {
    findBankAccountByCurrency,
    findBankAccountByTypes,
} from "../../formatters/fx/order";
import {isEmptyObject} from "./services/isEmptyObject";

interface ScrollableContainerProps {
    disableScroll: boolean;
}

const ScrollableContainer = styled.div<ScrollableContainerProps>`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    ${({disableScroll}) =>
            disableScroll ? `overflow-y: hidden;` : `overflow-y: auto;`}
`;

/**
 * Represents the Order Form Account Page.
 *
 * This component displays the order form for the account page. It allows the user to select the source and target bank accounts, and provides functionality to handle account changes
 *, navigate to the confirmation page, and handle slider movements.
 *
 * @component
 *
 */
const OrderFormAccountPage = () => {
    const navigate = useNavigateThrottle();
    const intl = useIntl();
    const user = useAppSelector(selectUser);
    const fxOrderForm = useAppSelector(selectFXOrderForm);
    const dispatch = useAppDispatch();
    const [isDisabledScroll, setIsDisabledScroll] = useState<boolean>(false);
    const sourceBankAccounts = useMemo(
        () => findBankAccountByCurrency(user.accounts, fxOrderForm.source.currency),
        [fxOrderForm.source.currency]
    );
    const targetBankAccounts = useMemo(
        () =>
            fxOrderForm.target.currency
                ? fxOrderForm.target.currency === BASE_CURRENCY
                    ? findBankAccountByTypes(
                        findBankAccountByCurrency(
                            user.accounts,
                            fxOrderForm.target.currency
                        ),
                        ALLOWED_RECEIVING_ROLE
                    )
                    : findBankAccountByCurrency(
                        user.accounts,
                        fxOrderForm.target.currency
                    )
                : [],
        [fxOrderForm.target.currency]
    ); // Filter by account type if From Currency is base currency or MYR (Please see DRS for more detail)
    const isInitialized = useRef<boolean>(false);
    let resetScrollTimer: NodeJS.Timeout;

    useEffect(() => {
        if (!fxOrderForm.target.currency) {
            //navigate(formatURL("/order/form/step-1"), { replace: true });
            emitErrorToApp();
        }

        // console.log("sourceBankAccounts", sourceBankAccounts)
        // console.log("targetBankAccounts", targetBankAccounts)

        if (isEmptyObject(fxOrderForm.bankAccount)) { //Is fxOrderFrom is {}, which means no record have ever created
            // console.log("there is nothing")
            dispatch(
                updateFXOrderFormBankAccount({
                    source: sourceBankAccounts[0],
                    target: targetBankAccounts[0],
                })
            );
        }else{
            // console.log("there is something")
        }

        if (fxOrderForm!.bankAccount!.source) {
            if (sourceBankAccounts.map(account => account.key).includes(fxOrderForm!.bankAccount!.source.key)) {
                // console.log("for SOURCE account, there is already one, so no change")
            } else {
                // console.log("need to change SOURCE account")
                dispatch(
                    updateFXOrderFormSourceBankAccount(
                        sourceBankAccounts[0]
                    )
                )

            }
        }
        if (fxOrderForm!.bankAccount!.target) {
            if (targetBankAccounts.map(account => account.key).includes(fxOrderForm!.bankAccount!.target.key)) {
                // console.log("for TARGET account, there is already one, so no change")
            } else {
                // console.log("need to change TARGET account")
                dispatch(
                    updateFXOrderFormTargetBankAccount(
                        targetBankAccounts[0]
                    )
                )

            }
        }

        // if (!fxOrderForm.bankAccount.source || !fxOrderForm.bankAccount.target) {
        // 	dispatch(
        // 		updateFXOrderFormBankAccount({
        // 			source: sourceBankAccounts[0],
        // 			target: targetBankAccounts[0],
        // 		})
        // 	);
        // }

        // console.log("sourceBankAccounts[0]",sourceBankAccounts[0].currency)
        // console.log("targetBankAccounts[0]", targetBankAccounts[0].currency)

        if (isInitialized.current) {
        } else isInitialized.current = true;
    }, []);

    /**
     * Function to handle account change.
     *
     * @param {string} type - The type of the bank account.
     * @param {Object} account - The user bank account.
     */
    const handleAccountChange =
        (type: keyof FXOrderFormBankAccountType) =>
            (account: UserBankAccountType) => {
                dispatch(updateFXOrderFormBankAccountByType({type, account}));
            };

    /**
     * Function to handle the click event on the 'Next' button.
     * Executes necessary checks and navigates to the confirmation page.
     *
     * @function handleNextClick
     */
    const handleNextClick = () => {
        // Only check balance when it is a market order
        if (!fxOrderForm.future) {
            // No selected from / to account
            if (!fxOrderForm.bankAccount.source || !fxOrderForm.bankAccount.target) {
                enqueueSnackbar(
                    intl.formatMessage({
                        id: "app.common.message.error",
                    }),
                    {variant: "general", mode: "info"}
                );
                return;
            }

            // Insufficient funds
            if (
                fxOrderForm.bankAccount.source.balance < fxOrderForm.source.amount.value
            ) {
                enqueueSnackbar(
                    intl.formatMessage({
                        id: "app.page.orderAccount.snackbar.submit.message.error.balanceCheck",
                    }),
                    {variant: "general", mode: "info"}
                );
                return;
            }
        }

        navigate(formatURL("/order/confirmation"));
    };

    /**
     * Handle the event when the slider is moved.
     *
     * This function is called when the slider is moved during the interaction. It performs the necessary operations to handle the event.
     *
     * @function handleSliderMove
     * @memberOf {Object}
     * @returns {void}
     */
    const handleSliderMove = () => {
        //this is commented out, as after chaning from swipe > select to click > select, this is no longer meaningful

        // setIsDisabledScroll(true);
        // if (resetScrollTimer) clearTimeout(resetScrollTimer);
        // resetScrollTimer = setTimeout(() => {
        // 	setIsDisabledScroll(false);
        // }, 1);
    };

    // Show empty page if target currency or FX is not set (Target currency should be set in previous page)
    if (!fxOrderForm.target.currency || !fxOrderForm.fx) {
        return null;
    }

    return (
        <Layout
            title={intl.formatMessage({id: "app.page.orderAccount.header.title"})}
        >


            <Helmet>
                <title>
                    {intl.formatMessage({
                        id: "app.page.orderAccount.header.title",
                    })}
                </title>
            </Helmet>
            <ScrollableContainer disableScroll={isDisabledScroll}>
                <FXAmountSection
                    page="account"
                    source={{
                        currency: fxOrderForm.source.currency,
                        amount: fxOrderForm.source.amount.value,
                    }}
                    target={{
                        currency: fxOrderForm.target.currency,
                        amount: fxOrderForm.target.amount.value,
                    }}
                    selectingCurrencyType={fxOrderForm.selectingCurrencyType}
                    fxObject={fxOrderForm.fx}
                />
                <BankAccountSelectorSection
                    title={intl.formatMessage({
                        id: "app.page.orderAccount.section.account.label.from",
                    })}
                    accounts={sourceBankAccounts}
                    selectedAccountKey={fxOrderForm.bankAccount.source?.key}
                    onSelect={handleAccountChange("source")}
                    onSliderMove={handleSliderMove}
                />
                <BankAccountSelectorSection
                    title={intl.formatMessage({
                        id: "app.page.orderAccount.section.account.label.to",
                    })}
                    accounts={targetBankAccounts}
                    selectedAccountKey={fxOrderForm.bankAccount.target?.key}
                    onSelect={handleAccountChange("target")}
                    onSliderMove={handleSliderMove}
                />
                <TNCSection/>
            </ScrollableContainer>
            <FooterButtonContainer>
                <Button theme="primary" size="lg" onClick={handleNextClick}>
                    {fxOrderForm.future
                        ? intl.formatMessage({
                            id: "app.page.orderAccount.button.placeOrder",
                        })
                        : intl.formatMessage({
                            id: "app.page.orderAccount.button.getQuote",
                        })}
                </Button>
            </FooterButtonContainer>
        </Layout>
    );
};

export default OrderFormAccountPage;

// <div>{"fxOrderForm.bankAccount.source.balance < fxOrderForm.source.amount.value"}</div>
// {/*@ts-ignore*/
// }
// <div style={{
// 	fontWeight: (
// 		/*@ts-ignore*/
// 		fxOrderForm.bankAccount?.source?.balance < fxOrderForm?.source?.amount?.value) ? 'bold' : 'normal',
// 	// @ts-ignore
// 	color: (fxOrderForm.bankAccount?.source?.balance < fxOrderForm?.source?.amount?.value) ? 'red' : 'inherit'
// }}>
// 	{/*//@ts-ignore*/}
// 	{(fxOrderForm.bankAccount?.source?.balance < fxOrderForm?.source?.amount?.value) && "NOT ENOUGH FUND"}
// </div>
