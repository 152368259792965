import {FXOrderFormType, FXRateAlertType} from "../../../../../types/fx.type";
import {PROFIT_TOLERANCE_DOWN, PROFIT_TOLERANCE_UP} from "../../../../../constants/profit.tolerance.constant";
import {roundNumber} from "./roundNumber";
import {store} from "../../../../../store/store"
/**
 * Represents the result of a threshold comparison.
 * @typedef {Object} ThresholdResult
 * @property {number} upperThreshold - The upper threshold value.
 * @property {number} lowerThreshold - The lower threshold value.
 */
interface ThresholdResult {
    upperThreshold: number;
    lowerThreshold: number;
}

/**
 * Calculates the upper and lower threshold for a given FXOrderForm.
 * The thresholds are based on the default rate and the maximum decimal places.
 *
 * @param {FXOrderFormType} fxOrderForm - The FXOrderForm to calculate the thresholds for.
 * @param {number} defaultRate - The default rate to calculate the thresholds from.
 * @param {number} maxDecimalPlaces - The maximum decimal places to round the thresholds to.
 *
 * @returns {ThresholdResult} - An object containing the upper and lower thresholds.
 */
export const calculateUpperLowerThreshold = (fxOrderForm: FXOrderFormType|FXRateAlertType, defaultRate: number, maxDecimalPlaces: number): ThresholdResult => {
    //get two currency
    const currency1: string = fxOrderForm.fx?.currencies[0] as string
    const currency2: string = fxOrderForm.fx?.currencies[1] as string

    const sourceCurrency: string = fxOrderForm.source.currency as string
    const targetCurrency: string = fxOrderForm.target.currency as string

    const ifTargetGoUpMakesProfit: boolean = (currency1 === sourceCurrency) && (currency2 === targetCurrency)
    const ifTargetGoDownMakesProfit: boolean = (currency1 === targetCurrency) && (currency2 === sourceCurrency)

    let upperThreshold: number;
    let lowerThreshold: number;

    const state = store.getState()
    const profitToleranceUp = 1+ state.app.fo_profit_limitation
    const profitToleranceDown = 1 - state.app.fo_profit_limitation
    if (ifTargetGoUpMakesProfit && !ifTargetGoDownMakesProfit) {
        upperThreshold = roundNumber(defaultRate * profitToleranceUp, maxDecimalPlaces, 'down')
        lowerThreshold = defaultRate
        // console.log(`Go UP makes profit, upper limit is ${upperThreshold}, lower limit is ${lowerThreshold}`)
    } else if (ifTargetGoDownMakesProfit && !ifTargetGoUpMakesProfit) {
        upperThreshold = defaultRate
        lowerThreshold = roundNumber(defaultRate * profitToleranceDown, maxDecimalPlaces, 'up')
        // console.log(`Go DOWN makes profit, lower limit is ${lowerThreshold}, upper limit is ${upperThreshold}`)
    } else {
        // alert("SOMETHING IS WRONG!!")
        upperThreshold = 0
        lowerThreshold = 0
    }
    return {upperThreshold, lowerThreshold};
};

