import { Navigate, useLocation } from "react-router-dom";

import { ACCOUNT_ROLE } from "../../constants/common.constant";
import { getURLQueryValueByKey } from "../../utils/common.utils";
import { useAppSelector } from "../../utils/store.utils";
import { selectUser } from "../../slice/user";

const RequireAuthenticate = ({
	children,
}: {
	children: JSX.Element;
}): JSX.Element => {
	const location = useLocation();
	const token = getURLQueryValueByKey("token") as string;
	const user = useAppSelector(selectUser);

	if (!token) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return (
			<Navigate
				to={`/watchlist?token=${ACCOUNT_ROLE.NOMFCA}`}
				state={{ from: location }}
				replace
			/>
		);
	} else if (!user || !user.type) {
		return (
			<Navigate
				to={`/watchlist?token=${token}`}
				state={{ from: location }}
				replace
			/>
		);
	}

	return children;
};

export default RequireAuthenticate;
