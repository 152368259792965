// Localization
import en from "../locales/en.json";

export const DEFAULT_LANGUAGE = "en";
export const AVAILABLE_LANGUAGE = ["en"];
export const DEFAULT_LOCALIZATION = {
	en,
};

export const DEFAULT_DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const LOCALE_LOCAL_STORAGE_KEY = "locale";

export const MARKET_TOKEN_SESSION_STORAGE_KEY = "marketToken";
export const MARKET_TOKEN_EXPIRATION_SESSION_STORAGE_KEY =
	"marketTokenExpiration";

export const DEFAULT_TOKEN_RENEW_REMAIN_TIME = 600000; // 10 minutes

export const PER_PAGE = 20;
export const PAGINATION_PAGES_RANGE = 20;

export const MIN_APP_HEADER_HEIGHT = 63.5;

export const DEFAULT_SNACKBAR_OPTIONS = {
	type: "",
	isOpen: false,
	autoHideDuration: 3000,
	message: "",
	hasCloseButton: false,
};
export const MAX_SNACKBAR_STACKS = 1;

export const ERROR_DIALOG_CLOSE_DELAY = 350; //ms

export enum API_DATA_DIRECTION {
	OLD,
	NEW,
}

// Flag icon
export const DEFAULT_NATIONAL_FLAG_WIDTH = 28;
export const DEFAULT_NATIONAL_FLAG_HEIGHT = 28;
export const MIN_NATIONAL_FLAG_WIDTH = 16;
export const MIN_NATIONAL_FLAG_HEIGHT = 16;
export const DEFAULT_NATIONAL_FLAG_ICON_SIZE = 64;
export const NATIONAL_FLAG_ICON_SHADOW_PX = 30;
export const NATIONAL_FLAG_ICON_SHADOW_LEFT_PX = 15;
export const NATIONAL_FLAG_ICON_SHADOW_TOP_PX = 10;
export const DEFAULT_FX_NATIONAL_FLAG_OVERLAP = 10;

// Drawer picker
export const DEFAULT_SELECT_DRAWER_PICKER_HEIGHT = 246;
export const DEFAULT_SELECT_DRAWER_PICKER_VIEW_ITEMS = 5;

// MBB Account related
export const ACCOUNT_MODE_TEXT = "GAA";
export enum ACCOUNT_ROLE {
	MFCA = "MFCA",
	HNW = "HNW",
	NOMFCA = "NOMFCA",
	GAA = "GAA", //same thing
	G1P = "G1P", //same thing
	G1A = "G1A" //same thing
}
//todo
//there is user role
// MFCA, HNW, NOMFCA
// and account role
// MFCA, (GAA, G1P, G1A, 3 of them are the same thing), CA, SA..... whatever
export const ACTIVATED_ACCOUNT_ROLE = [ACCOUNT_ROLE.MFCA,ACCOUNT_ROLE.G1A, ACCOUNT_ROLE.GAA, ACCOUNT_ROLE.G1P];
export const NO_ACCOUNT_ROLE = [ACCOUNT_ROLE.NOMFCA, ACCOUNT_ROLE.HNW];
export const ALLOWED_STREAMING_ROLE = [ACCOUNT_ROLE.MFCA, ACCOUNT_ROLE.HNW];
export const NOT_ALLOWED_STREAMING_ROLE = [ACCOUNT_ROLE.NOMFCA];
export const ALLOWED_RECEIVING_ROLE: ACCOUNT_ROLE[] = [ACCOUNT_ROLE.MFCA, ACCOUNT_ROLE.GAA, ACCOUNT_ROLE.G1A, ACCOUNT_ROLE.G1P]

export const BASE_CURRENCY = "MYR";


export enum INPUT_FIELD_TEXT_COUNT_MODE {
	COUNT,
	LIMIT,
	COUNT_AND_LIMIT,
	REMAINING,
}

export enum INPUT_FIELD_HELPER_TEXT_TYPE {
	INFO,
	SUCCESS,
	ERROR,
}

// NumPad
export const DEFAULT_NUMPAD_INPUT_DECIMAL_POINT = 2;

export const DEFAULT_ORDER_CONVERSION_FEE = 0;
export const SMS_TAC = "SMS_TAC";
export enum TRANSACTION_TYPE {
	MARKET_ORDER = "Market Order",
	FUTURE_ORDER = "Future Order"
}
