import styled from "styled-components";

interface EmptyHeaderProps {
	marginTop?: number;
}

const EmptyHeader = styled.h6<EmptyHeaderProps>`
	margin-top: ${({ marginTop }) => marginTop || 0}px;
	margin-bottom: 8px;
	font-size: 18px;
	font-weight: var(--font-weight-semi-bold);
	text-align: center;
`;

export default EmptyHeader;
