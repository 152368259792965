import {useIntl} from "react-intl";
import ItemEndContainer from "../../../components/styled/items/ItemEndContainer";
import List from "../../../components/styled/List";
import {ORDER_DATA_LIST_ITEM_PADDING, OrderDataListItem} from "../../../components/order/confirmation/OrderDataList";
import ItemStartContainer from "../../../components/styled/items/ItemStartContainer";
import ItemStartContent from "../../../components/styled/items/ItemStartContent";
import {formatNumber} from "../../../utils/common.utils";
import {DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT} from "../../../constants/fx.constant";
import {getConversionFee} from "../../../formatters/fx/order";
import {OrderStatusInfoReal} from "../interface/OrderStatusInfo";
import {formatDateGMTToMYT} from "../services/formatDateToStringDDMonthYYYY";
import {OrderStatusEnum} from "../enums/OrderStatusEnum";
import {getCurrencyPart} from "./OrderCardContent";
import {formatAccountNumber} from "../../../formatters/account/formatAccountNumber";
import {renderRate} from "../../../components/order/CalculationCard";
import {showMYTText} from "../services/showMYTText";
import {SHOW_MYT_TIMEZONE_SYMBOL} from "../../../constants/app.constant";
import {TextWrapper} from "../../../components/styled/TextWrapper";
import {InvalidDateEnum} from "../../../services/InvalidDateEnum";

export interface OrderDetailDataListFOProps {
    orderDetailObj: OrderStatusInfoReal;
}

/**
 * Renders the order data list component.
 *
 export * @param {OrderDetailDataListProps} props - The props object containing the necessary data for rendering the component.
 * @returns {JSX.Element} The order data list component.
 */
const OrderDetailDataListFO = ({
                                   orderDetailObj,
                               }: OrderDetailDataListFOProps): JSX.Element => {
    const intl = useIntl();
    return (
        <List>
            {/*1. transaction type --------------------*/}
            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING} extraLargePadding={true}>
                <ItemStartContainer>
                    <ItemStartContent>
                        {intl.formatMessage({
                            id: "app.page.orderConfirmation.list.orderData.item.transactionType",
                        })}
                    </ItemStartContent>
                </ItemStartContainer>
                <ItemEndContainer>
                    {intl.formatMessage({
                        id: "app.page.transactionReceipt.transactionType.future.text",
                    })}
                </ItemEndContainer>
            </OrderDataListItem>

            {/*2. Target rate --------------------*/}
            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING} extraLargePadding={true}>
                <ItemStartContainer>
                    <ItemStartContent>
                        {intl.formatMessage({
                            id: "app.page.orderConfirmation.list.orderData.item.targetRate",
                        })}
                    </ItemStartContent>
                </ItemStartContainer>
                <ItemEndContainer>
                    {renderRate(
                        getCurrencyPart(orderDetailObj.symbol, true),
                        getCurrencyPart(orderDetailObj.symbol, false),
                        orderDetailObj.rate ? orderDetailObj.rate : orderDetailObj.limit,
                        orderDetailObj.dp,
                        orderDetailObj.uq,
                    )}
                </ItemEndContainer>
            </OrderDataListItem>

            {/*3. Conversion Fee --------------------*/}
            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING} extraLargePadding={true}>
                <ItemStartContainer>
                    <ItemStartContent>
                        {intl.formatMessage({
                            id: "app.page.orderConfirmation.list.orderData.item.conversionFee",
                        })}
                    </ItemStartContent>
                </ItemStartContainer>
                <ItemEndContainer>
                    {getCurrencyPart(orderDetailObj.symbol, true)}{" "}
                    {formatNumber(
                        getConversionFee(),
                        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
                    )}
                </ItemEndContainer>
            </OrderDataListItem>

            {/*4. Transfer from --------------------*/}
            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING} extraLargePadding={true}>
                <ItemStartContainer>
                    <ItemStartContent>
                        {intl.formatMessage({
                            id: "app.page.orderConfirmation.list.orderData.item.transferFrom",
                        })}
                    </ItemStartContent>
                </ItemStartContainer>
                <ItemEndContainer>
                    <div>
                        {orderDetailObj.fromAccName}
                    </div>
                    <div>
                        {formatAccountNumber(orderDetailObj.fromAccNo)}
                    </div>
                </ItemEndContainer>
            </OrderDataListItem>

            {/*5. transfer to --------------------*/}
            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING} extraLargePadding={true}>
                <ItemStartContainer>
                    <ItemStartContent>
                        {intl.formatMessage({
                            id: "app.page.orderConfirmation.list.orderData.item.transferTo",
                        })}
                    </ItemStartContent>
                </ItemStartContainer>
                <ItemEndContainer>
                    <div>
                        {orderDetailObj.toAccName}
                    </div>
                    <div>
                        {formatAccountNumber(orderDetailObj.toAccNo)}
                    </div>
                </ItemEndContainer>
            </OrderDataListItem>

            {/*6. Order created on --------------------*/}
            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING} extraLargePadding={true}>
                <ItemStartContainer>
                    <ItemStartContent>
                        {intl.formatMessage({
                            id: "app.page.orderStatus.details.orderCreatedOn",
                        })}
                    </ItemStartContent>
                </ItemStartContainer>
                <ItemEndContainer>
                    {(() => {
                        const formattedDate = formatDateGMTToMYT(orderDetailObj.inputDate as string, false).date;
                        return formattedDate !== InvalidDateEnum.INVALID_DATE ? (
                            <>{formattedDate}</>
                        ) : (
                            <div>-</div>
                        );
                    })()}
                </ItemEndContainer>

            </OrderDataListItem>

            {/*7. Expiry date DONT HAVE THIS IN MO --------------------*/}
            {
                <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING} extraLargePadding={true}>
                    <ItemStartContainer>
                        <ItemStartContent>
                            {intl.formatMessage({
                                id: "app.page.orderForm.form.order.label.expiration",
                            })}
                        </ItemStartContent>
                    </ItemStartContainer>
                    <ItemEndContainer>
                        <TextWrapper pushToRight={true}>
                            {(() => {
                                const formattedDateTime = formatDateGMTToMYT(orderDetailObj.expireDate as string, true);
                                if (formattedDateTime.date !== InvalidDateEnum.INVALID_DATE) {
                                    return (
                                        <>
                                            <div>
                                                {`${formattedDateTime.date},`}
                                            </div>
                                            <div>
                                                &nbsp;{formattedDateTime.time}
                                                {SHOW_MYT_TIMEZONE_SYMBOL ? `, ${showMYTText()}` : ""}
                                            </div>
                                        </>
                                    );
                                } else {
                                    return <div>-</div>;
                                }
                            })()}
                        </TextWrapper>

                    </ItemEndContainer>
                </OrderDataListItem>
            }

            {/*8. OrderId --------------------*/}
            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING} extraLargePadding={true}>
                <ItemStartContainer>
                    <ItemStartContent>
                        {intl.formatMessage({
                            id: "app.page.orderStatus.details.orderId",
                        })}
                    </ItemStartContent>
                </ItemStartContainer>
                <ItemEndContainer>
                    {orderDetailObj.orderId}
                </ItemEndContainer>
            </OrderDataListItem>
            {/*9 Order completed on OR Order canceled on Order or Order was unsuccessful on or Nothing--------------------*/}
            {
                (orderDetailObj.tradeDate || orderDetailObj.cancelDate) &&
                <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING} extraLargePadding={true}>
                    <ItemStartContainer
                        disableHidden={true}
                    >
                        <ItemStartContent>
                            {intl.formatMessage({
                                id: getFutureProcessedDateMsg(orderDetailObj.orderStatus),
                            })}
                        </ItemStartContent>
                    </ItemStartContainer>
                    <ItemEndContainer>
                        {
                            formatDateGMTToMYT((orderDetailObj.orderStatus === OrderStatusEnum.COMPLETED ? orderDetailObj.tradeDate! : orderDetailObj!.cancelDate!), false).date
                        }
                    </ItemEndContainer>
                </OrderDataListItem>
            }
        </List>
    );
};
export default OrderDetailDataListFO

const getFutureProcessedDateMsg = (status: OrderStatusEnum): string => {
    console.log(status)
    switch (status) {
        case OrderStatusEnum.COMPLETED:
            return "app.page.orderStatus.details.orderCompletedOn"
        case OrderStatusEnum.CANCELLED:
            return "app.page.orderStatus.details.orderCancelledOn"
        case OrderStatusEnum.UNSUCCESSFUL:
            return "app.page.orderStatus.details.orderWasUnsuccessfulOn"
        default:
            return "ERROR"
    }
}
