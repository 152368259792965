import {enqueueSnackbar,} from 'notistack';
import {generateErrorMsg} from "./generateErrorMsg.utilis";

/**
 * Shows a service unavailable snackbar.
 *
 * @param {string | null} errorStatusCode - The error status code.
 * @param {() => void} onCloseFunc - The function to be called when snackbar is closed.
 * @param {string} specificToastErrorMsg - The specific error message to be shown.
 * @returns {void}
 */
export const showServiceUnavailableSnackbar = (
        errorStatusCode?: string | null,
        onCloseFunc?: (responseCode?:string | null) => void,
        specificToastErrorMsg?: string
    ) => {
        enqueueSnackbar(
            generateErrorMsg(errorStatusCode, specificToastErrorMsg), {
                variant: "general",
                mode: 'info',
                persist: true, //it means it will never hide
                // onClose: postMae_error ? (event => emitErrorToApp()) : undefined
                onClose: onCloseFunc ? (event => onCloseFunc(errorStatusCode)) : undefined
            })
    }
;

// export const showServiceUnavailableSnackbar = (
//     errorStatusCode?: string | null,
//     onCloseFunc?: () => void,
//     specificToastErrorMsg?: string
// ) => {
//     const intlObject = getIntlObject();
//     const mainMessage: string = intlObject.formatMessage({id: "app.page.serviceUnavailable.text"});
//     let rejectCodeMessage: string = '';
//     if (errorStatusCode && showRejectCodeInToastBar) {
//         // Convert errorStatusCode to lowercase for case-insensitive comparison
//         const errorStatusCodeLower = errorStatusCode.toLowerCase();
//
//         // Check for specific codes where no action is needed
//         if (errorStatusCodeLower === "mdip001" || errorStatusCodeLower === "bts001") {
//             // Do nothing for these specific codes
//             return;
//         }
//
//         if (errorStatusCodeLower.includes("arts") ||
//             errorStatusCodeLower.includes("bts") ||
//             errorStatusCodeLower.includes("mdip")) {
//             rejectCodeMessage = `\nReject Code: ${errorStatusCode}`;
//         }
//     }
//
// // Final toast string
//     const toastString: string = specificToastErrorMsg ?? `${mainMessage}${rejectCodeMessage}`;
//     enqueueSnackbar(
//         toastString, {
//             variant: "general",
//             mode: 'info',
//             persist: true, //it means it will never hide
//             // onClose: postMae_error ? (event => emitErrorToApp()) : undefined
//             onClose: onCloseFunc ? (event => onCloseFunc()) : undefined
//
//         })
// };
