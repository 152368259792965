import {OrderStatusInfoReal} from "../interface/OrderStatusInfo";
import {OrderStatusEnum} from "../enums/OrderStatusEnum";

export function filterAndSortOrderStatusData(
    orderStatusData: OrderStatusInfoReal[],
    selectedStatusType: OrderStatusEnum | string
): OrderStatusInfoReal[] {
    return orderStatusData
        // Filter by order status
        .filter(order => order.orderStatus === selectedStatusType || selectedStatusType === OrderStatusEnum.ALL)
        // Sort by expiryDate in descending order
        .sort((a, b) => Number(getLastUpdateTime(b)) - Number(getLastUpdateTime(a)))
}
//
// The last update time means: •
// For market orders, this is the deal date/time. •
// For future orders, this is the last update date/time of the order, i.e. either executed date/time, cancelled date/time, expired date/time or rejected date/time (being unsuccessful).
const getLastUpdateTime = (status: OrderStatusInfoReal): string => {
    if (status.orderType === 1) { //Market Order
        return status.tradeDate as string
    } else{
        if (status.orderStatus=== OrderStatusEnum.COMPLETED) {
            return status.tradeDate as string
        } else if (status.orderStatus===OrderStatusEnum.EXPIRED){
            return status.expireDate as string
        }else if(status.orderStatus===OrderStatusEnum.PENDING){
            return status.inputDate as string
        }else if(status.orderStatus === OrderStatusEnum.CANCELLED || status.orderStatus === OrderStatusEnum.UNSUCCESSFUL){
            if(status.cancelDate){
                return status.cancelDate as string //it is supposed to be cancelled date.... but I don't have one...
            }else{
                return status.inputDate as string
            }
        }else{
            return ""
        }
    }
}
