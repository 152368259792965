import {useIntl} from "react-intl";
import {Drawer} from "@mui/material";
import styled from "styled-components";
import "swiper/css";
import Anchor from "../../styled/Anchor";
import Button from "../../styled/buttons/Button";
import {CalendarFutureOrder} from "./CalendarFutureOrder";
import {useState} from "react";
import {useAppSelector} from "../../../utils/store.utils";
import {selectFXOrderForm, selectFXRateAlert} from "../../../slice/fx";

interface PickerDrawerProps {
    open: boolean;
    onClose: () => void;
    onPick: (expiryDate:Date) => void;
    isRateAlert?:boolean
}

const StyledDrawer = styled(Drawer)`
    .MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
`;

const DrawerHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    font-size: 14px;
    font-weight: var(--font-weight-semi-bold);
`;


/**
 * DatePickerDrawer component for selecting a date in a drawer.
 *
 * @param {Object} PickerDrawerProps - The props for the DatePickerDrawer component.
 * @param {boolean} PickerDrawerProps.open - Determines if the drawer is open or closed.
 * @param {function} PickerDrawerProps.onClose - Function to be called when the drawer is closed.
 * @param {function} PickerDrawerProps.onPick - Function to be called when a date is picked.
 *
 * @returns {React.Component} - The DatePickerDrawer component.
 */
export const DatePickerDrawer = ({
                                     open,
                                     onClose,
                                     onPick,
                                     isRateAlert
                                 }: PickerDrawerProps) => {
    const intl = useIntl();
    const [submitEnabled,setSubmitEnabled] = useState<boolean>(false)
    const fxRateAlert = useAppSelector(selectFXRateAlert)
    const fxOrderForm = useAppSelector(selectFXOrderForm);
    const initialDate = isRateAlert? fxRateAlert.expiryDate: fxOrderForm.expiryDate
    const [selectedDate, setSelectedDate] = useState<Date>(new Date(initialDate as string));

    const handleSubmitEnabled = (haveSelectedDate:boolean) =>{
        setSubmitEnabled(haveSelectedDate)
    }

    const handleSelectedDateForDrawer = (selectedDate: Date) => {
        // console.log(selectedDate)
        setSelectedDate(selectedDate)
    }


    const handlePick = (expiryDate:Date) => {
        onPick(expiryDate);
        onClose();
    };

    return (
        <StyledDrawer anchor="bottom" open={open} onClose={onClose}>
            <DrawerHeader>
                <Anchor onClick={onClose}>
                    {intl.formatMessage({id: "app.common.cancel"})}
                </Anchor>
                <Button theme="primary"
                        size="sm"
                        onClick={() => handlePick(selectedDate)}
                        disabled={!submitEnabled}
                        id={"date-submit-done"}
                >
                    {intl.formatMessage({id: "app.common.done"})}
                </Button>
            </DrawerHeader>
            <CalendarFutureOrder
                handleSubmitEnabled={handleSubmitEnabled}
                handleSelectedDateForDrawer={handleSelectedDateForDrawer}
                isRateAlert={isRateAlert}
            />
        </StyledDrawer>
    );
};

