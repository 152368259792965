export function formatDateTime(dateTimeStr: string): string {
    // Convert the string to a Date object
    const date = new Date(dateTimeStr);

    // Define a helper function to ensure two digits for month, day, hour, minute, and second
    const padToTwoDigits = (num: number) => num.toString().padStart(2, '0');

    // Extract parts of the date
    const year = date.getFullYear();
    const month = padToTwoDigits(date.getMonth() + 1); // getMonth() is zero-indexed
    const day = padToTwoDigits(date.getDate());
    const hour = padToTwoDigits(date.getHours());
    const minute = padToTwoDigits(date.getMinutes());
    const second = padToTwoDigits(date.getSeconds());

    // Return the formatted string
    return `${year}${month}${day}${hour}${minute}${second}`;
}

// Example usage
const inputDateTime = "2024-04-23T17:23:41+08:00";
const formattedDateTime = formatDateTime(inputDateTime);
