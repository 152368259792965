import './CalendarFutureOrder.css'
import {useEffect, useState} from "react";
import {getDateRange, getDayName, getNextDay} from "./services/getDateRange";
import {DateRange} from "./DateRange";
import {toMalaysianTime} from "./services/toMalaysianTime";
import {useAppSelector} from "../../../utils/store.utils";
import {selectFXOrderForm, selectFXRateAlert} from "../../../slice/fx";
import {parseDateString} from "./services/parseDateString";
import {
    selectFoCalendarMaxFutureMonth,
    selectFoCalendarMaxPreviousMonth,
    selectRaCalendarMaxFutureMonth,
    selectRaCalendarMaxPreviousMonth
} from "../../../slice/app";

interface calendarFutureOrderProps{
    handleSubmitEnabled: (haveSelectedDate:boolean)=>void
    handleSelectedDateForDrawer: (selectedDate: Date) => void
    isRateAlert?:boolean
}

export function CalendarFutureOrder(props:calendarFutureOrderProps) {
    const fxOrderForm = useAppSelector(selectFXOrderForm);
    const fxRateAlert = useAppSelector(selectFXRateAlert);
    const raCalendarMaxPreviousMonth = useAppSelector(selectRaCalendarMaxPreviousMonth);
    const raCalendarMaxFutureMonth = useAppSelector(selectRaCalendarMaxFutureMonth);
    const foCalendarMaxPreviousMonth = useAppSelector(selectFoCalendarMaxPreviousMonth);
    const foCalendarMaxFutureMonth = useAppSelector(selectFoCalendarMaxFutureMonth);

    const generateInitializedDate = ():Date =>{
        if(props.isRateAlert){
            if(fxRateAlert.expiryDate){
                return parseDateString(fxRateAlert.expiryDate)
            }else{
                return nextDayOfCurrentMalaysiaTime
            }
        }
        else{
            if(fxOrderForm.expiryDate){
                return parseDateString(fxOrderForm.expiryDate)
            }else{
                return nextDayOfCurrentMalaysiaTime
            }
        }
    }
    const currentMalaysiaTime = toMalaysianTime(new Date());
    const nextDayOfCurrentMalaysiaTime = getNextDay(currentMalaysiaTime);
    // const initializedDate: Date = fxOrderForm.expiryDate ?
    //     parseDateString(fxOrderForm.expiryDate) : nextDayOfCurrentMalaysiaTime
    const initializedDate: Date = generateInitializedDate()
    const firstDateOfCurrentMonthV2 = toMalaysianTime(new Date(initializedDate.getFullYear(), initializedDate.getMonth(), 1));

    const [monthAndYear, setMonthAndYear] = useState<Date>(firstDateOfCurrentMonthV2);
    const dayRange = getDateRange(
    monthAndYear);
    const [selectedDate, setSelectedDate] = useState<Date | null>(initializedDate);

    useEffect(() => {
        props.handleSubmitEnabled(!!selectedDate);
        // console.log('selectedDate pending',selectedDate)
    }, [selectedDate]);

    /**
     * Handles the selected date.
     *
     * @param {Date} dateObj - The selected date.
     * @returns {void}
     */
    const handleSelectedDate = (dateObj: Date): void => {
        // console.log("handleSelectedDate dateObj: ", dateObj)
        setSelectedDate(dateObj);
        props.handleSelectedDateForDrawer(dateObj)
    }

    /**
     * Determines whether the month can be changed by the specified month delta.
     *
     * @param {number} monthDelta - The number of months by which to change the current month. Positive values indicate future months, negative values indicate past months.
     * @returns {boolean} - True if the month can be changed by the specified month delta, false otherwise.
     */
    const canChangeMonth = (monthDelta: number): boolean => {
        const newMonth = new Date(monthAndYear.getFullYear(), monthAndYear.getMonth() + monthDelta, 1);
        const monthDiff = (newMonth.getFullYear() - currentMalaysiaTime.getFullYear()) * 12 + newMonth.getMonth() - currentMalaysiaTime.getMonth();
        if(!props.isRateAlert){
            return monthDiff >= -foCalendarMaxPreviousMonth && monthDiff <= foCalendarMaxFutureMonth;
        }else{
            return monthDiff >= -raCalendarMaxPreviousMonth && monthDiff <= raCalendarMaxFutureMonth;
        }
        // return monthDiff >= -MAX_PREVIOUS_MONTHS && monthDiff <= MAX_FUTURE_MONTHS;
    };

    /**
     * Changes the month by the specified month delta if its changeable.
     *
     * @param {1|-1} monthDelta - The number to change the month by. 1 for next month, -1 for previous month.
     * @returns {void}
     */
    const changeMonth = (monthDelta: (1 | -1)) => {
        if (canChangeMonth(monthDelta)) {
            const newDate = toMalaysianTime(new Date(
                monthAndYear.getFullYear(),
                monthAndYear.getMonth() + monthDelta,
                1
            ));
            setMonthAndYear(newDate);
        }
    };

    const [animation, setAnimation] = useState<string>('');
    const [incomingAnimation, setincomingAnimation] = useState<string>('');

    let startX: number;
    let endX: number;

    /**
     * Handles swipe event.
     *
     * @returns {void}
     */
    const handleSwipe = (): void => {
        const swipeDistance: number = endX - startX;
        if (swipeDistance > 50 && canChangeMonth(-1)) {
            setAnimation('animate-right');
            setTimeout(() => {
                changeMonth(-1);
                setincomingAnimation('incoming-right');
            }, 62.5);
            setTimeout(() => {
                setAnimation('');
                setincomingAnimation('');
            }, 125);
        } else if (swipeDistance < -50  && canChangeMonth(+1)) {
            setAnimation('animate-left');
            setTimeout(() => {
                changeMonth(1);
                setincomingAnimation('incoming-left');
            }, 62.5);
            setTimeout(() => {
                setAnimation('');
                setincomingAnimation('');
            }, 125);
        }
    }

    /**
     * Handles touch start event and sets starting x coordinate.
     *
     * @param {TouchEvent} e - The touch event.
     * @returns {void}
     */
    const handleTouchStart = (e: TouchEvent) => {
        startX = e.touches[0].clientX;
    }

    /**
     * Handles touch end event, sets ending x coordinate and calls handleSwipe to process swipe event.
     *
     * @param {TouchEvent} e - The touch event.
     * @returns {void}
     */
    const handleTouchEnd = (e: TouchEvent) => {
        endX = e.changedTouches[0].clientX;
        handleSwipe();
    }

    useEffect(() => {
        const calendarContainer = document.getElementById('calendarContainer');

        if (calendarContainer) {
            calendarContainer.addEventListener('touchstart', handleTouchStart, false);
            calendarContainer.addEventListener('touchend', handleTouchEnd, false);
        }

        return () => {
            if (calendarContainer) {
                calendarContainer.removeEventListener('touchstart', handleTouchStart);
                calendarContainer.removeEventListener('touchend', handleTouchEnd);
            }
        }
    }, [monthAndYear, handleTouchEnd, handleTouchStart]);

    return (
        <div className={"master-container"}>
            <div className={"container-ex"}>
                <div className={"monthYearContainer"}>
                    <div
                        className={"time-oval-container"}
                    >
                        <div className={"month-date-font"}>
                            {monthAndYear.toLocaleString('en-US', {month: 'short'}).toUpperCase()}
                        </div>
                    </div>
                    <div
                        className={"time-oval-container"}
                    >
                        <div className={"month-date-font"}>
                            {monthAndYear.toLocaleString('en-MY', {year: 'numeric', timeZone: 'Asia/Kuala_Lumpur'})}
                        </div>
                    </div>
                </div>
            </div>
            <div id={"calendarContainer"} className={`${animation} ${incomingAnimation} calendar-container`}>
                <DateRange
                    dayRange={dayRange}
                    selectedDate={selectedDate}
                    handleSelectedDate={handleSelectedDate}
                    currentDate={currentMalaysiaTime}
                    isRateAlert={props.isRateAlert}
                />
            </div>
        </div>
    )
}
