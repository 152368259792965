import {
	FXOrderAPIResponseType,
	FXOrderType,
} from "../../../../../types/fx.type";

/**
 * Format create FX order API response.
 * @param {FXOrderAPIResponseType} response - The FX order API response
 * @return {FXOrderType} The formatted FX order object.
 */
const formatCreateFXOrder = (response: FXOrderAPIResponseType): FXOrderType => {
	return {
		orderId: response.orderId,
		rate: parseFloat(response.quote),
		contraAmount: parseFloat(response.contraAmount),
		transactionDate: response.tradeDate,
		timeout: response.quoteTimeout * 1000,
	};
};

export default formatCreateFXOrder;
