import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import appReducer from "../slice/app";
import userReducer from "../slice/user";
import fxListReducer from "../slice/fx";
import watchlistReducer from "../slice/watchlist";
import newsReducer from "../slice/news";
import errorTimeoutReducer from "../slice/errorTimeout";
import fxConfigReducer from "../slice/fxConfig";

export const store = configureStore({
	reducer: {
		app: appReducer,
		user: userReducer,
		fx: fxListReducer,
		watchlist: watchlistReducer,
		news: newsReducer,
        errorTimeout: errorTimeoutReducer,
		fxConfig:fxConfigReducer
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
