import { useIntl } from "react-intl";
import styled from "styled-components";

import Section from "../../styled/sections/Section";
import SectionContent from "../../styled/sections/SectionContent";
import Checkbox from "../../common/Checkbox";
import {useAppSelector} from "../../../utils/store.utils";
import {selectFXOrderForm} from "../../../slice/fx";

interface AgreementSectionProps {
	isTNCChecked: boolean;
	onTNCCheck: (checked: boolean) => void;
}


const StyledAgreementSection = styled(Section)`
	font-size: 12px;
	font-weight: var(--font-weight-semi-light);
	line-height: 18px;
	letter-spacing: -0.2px;
`;


const AgreementTitle = styled.div`
	margin-bottom: 8px;
	font-weight: var(--font-weight-semi-bold);
`;


const TNCContainer = styled.label`
	display: flex;
	margin: 0;

	label {
		margin-left: 8px;
	}
`;


const DisclaimerContainer = styled.div`
	margin-top: 24px;
	margin-bottom: 24px;
	color: var(--text-color-secondary);

	ol {
		padding-left: 0;
		list-style: none;

		li {
			padding-left: 16px;
			counter-increment: nums;
			position: relative;

			&:before {
				content: counter(nums) ".";
				position: absolute;
				left: 0px;
			}

			&:not(:last-child) {
				margin-bottom: 6px;
			}
		}
	}
`;

/**
 * Represents the Agreement Section component.
 * @typedef AgreementSection
 * @param {AgreementSectionProps} props - The component props.
 * @returns {JSX.Element} The rendered component.
 */
const AgreementSection = ({
	isTNCChecked,
	onTNCCheck,
}: AgreementSectionProps) => {
	const intl = useIntl();
	const fxOrderForm = useAppSelector(selectFXOrderForm);

	const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
		onTNCCheck(e.currentTarget.checked);
	};

	return (
		<StyledAgreementSection hasMarginBottom>
			<SectionContent hasPaddingX>
				<AgreementTitle>
					{intl.formatMessage({
						id: "app.page.orderConfirmation.section.agreement.tnc.title",
					})}
				</AgreementTitle>
				<TNCContainer htmlFor="checkbox-order-confirmation-tnc">
					<Checkbox
						id="checkbox-order-confirmation-tnc"
						checked={isTNCChecked}
						onChange={handleCheck}
					/>
					<label htmlFor="checkbox-order-confirmation-tnc">
						{intl.formatMessage({
							id: "app.page.orderConfirmation.section.agreement.tnc.content",
						})}
					</label>
				</TNCContainer>
				<DisclaimerContainer>
					<AgreementTitle>
						{intl.formatMessage({
							id: "app.page.orderConfirmation.section.agreement.disclaimer.title",
						})}
					</AgreementTitle>
					{fxOrderForm.future ?
						<div>
							{intl.formatMessage({
								id: `app.page.orderConfirmation.section.agreement.disclaimer.futureContent`,
							})}
						</div>
						:
						<ol>
							{[...Array(2)].map((v, i) => (
								<li key={`order-confirmation-disclaimer-${i}`}>
									{intl.formatMessage({
										id: `app.page.orderConfirmation.section.agreement.disclaimer.content.${i}`,
									})}
								</li>
							))}
						</ol>
					}
				</DisclaimerContainer>
			</SectionContent>
		</StyledAgreementSection>
	);
};

export default AgreementSection;
