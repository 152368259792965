import {
    updateFXOrderFormFutureTargetRate,
    updateFXOrderFormFutureTargetRateString,
    updateRateAlertFutureTargetRate, updateRateAlertFutureTargetRateString
} from "../../../../../slice/fx";
import {FXOrderFormType, FXRateAlertType} from "../../../../../types/fx.type";
import {store} from "../../../../../store/store";
import {hasFutureProperty} from "./hasFutureProperty";
import {roundToPrecision} from "./roundToPrecision";
import {getDpUqBySymbol} from "../../../../fx/service/getDpUqBySymbol";
import {isNumber} from "./isNumber";
import {countDecimals} from "./countDecimals";

export const handleInputChangeV2 = (event: React.ChangeEvent<HTMLInputElement>,
                                    fxOrderForm: FXOrderFormType | FXRateAlertType,
                                    handleFutureInputChange: Function,
) => {
    const isFuture = hasFutureProperty(fxOrderForm);
    const validNumberOrDecimal = /^[0-9]*\.?[0-9]*$/;
    const inputValue = event.target.value;
    const uq: number = isNumber(getDpUqBySymbol(fxOrderForm.fx!.symbol).uq) ? getDpUqBySymbol(fxOrderForm.fx!.symbol).uq : 0;
    const dp: number = isNumber(getDpUqBySymbol(fxOrderForm.fx!.symbol).uq) ? getDpUqBySymbol(fxOrderForm.fx!.symbol).dp : Math.max(countDecimals(fxOrderForm.fx!.ask as number), countDecimals(fxOrderForm.fx!.bid as number));
    const uqMultiplier = 10 ** uq;
    const maxDecimalPlaces: number = dp - uq;

    // Check for invalid leading zero pattern
    if (/^0[0-9]+/.test(inputValue)) {
        return;
    }

    // Check for invalid "00" pattern
    if (/^00(\.\d+)?$/.test(inputValue)) {
        return;
    }

    // Check for input starting with "."
    if (/^\./.test(inputValue)) {
        return;
    }

    if (validNumberOrDecimal.test(inputValue)) {
        if (countDecimals(inputValue) <= maxDecimalPlaces) {
            const roundedValue = roundToPrecision(Number(inputValue) / uqMultiplier, fxOrderForm.fx?.decimalPoint as number);
            if (isFuture) {
                store.dispatch(updateFXOrderFormFutureTargetRate(roundedValue));
                store.dispatch(updateFXOrderFormFutureTargetRateString(inputValue as string));
                handleFutureInputChange(fxOrderForm, roundedValue);
            } else { // is rate alert
                store.dispatch(updateRateAlertFutureTargetRate(roundedValue));
                store.dispatch(updateRateAlertFutureTargetRateString(inputValue as string));
            }
        }
    }
};
