export function uqAndPreserveDecimals(value: number, uq:number): string {
    const multiplier:number = 10**uq
    // Convert the input value to a string
    const valueStr = value.toString();

    // Find the position of the decimal point
    const decimalIndex = valueStr.indexOf('.');

    // Calculate the number of decimal places in the input value
    const decimalPlaces = decimalIndex === -1 ? 0 : valueStr.length - decimalIndex - 1;

    // Multiply the value by the multiplier
    const multipliedValue = value * multiplier;

    // Return the result as a string with the same number of decimal places as the input value
    return multipliedValue.toFixed(decimalPlaces);
}
