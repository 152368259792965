import styled from "styled-components";
import {
    INPUT_FIELD_HELPER_TEXT_TYPE,
    INPUT_FIELD_TEXT_COUNT_MODE,
} from "../../../../constants/common.constant";
import {useEffect} from "react";
import {useAppSelector, useAppDispatch} from "../../../../utils/store.utils";
import {
    selectFutureTargetRateString,
    updateFXOrderFormFutureTargetRate, updateFXOrderFormFutureTargetRateString,
    updateRateAlertFutureTargetRate, updateRateAlertFutureTargetRateString
} from "../../../../slice/fx";
import {getRateByDealt} from "../../../../formatters/fx";
import useFXCalculator from "../../../../hooks/useFXCalculator";
import {selectApp} from "../../../../slice/app";
import { isWentBackAndToggledFalseEnum} from "../../../../types/app.type";
import {isComeBackFromLatterPage} from "../../../../pages/order/services/isComeBackFromLatterPage";
import {onEnterAndUnfocusToCheckBound} from "./services/onEnterAndUnfocusToCheckBound";
import {FXOrderFormType, FXRateAlertType} from "../../../../types/fx.type";
import {hasFutureProperty} from "./services/hasFutureProperty";
import {handleInputChangeV2} from "./services/handleInputChangeV2";
import {renderRate} from "../../CalculationCard";
import {getDpUqBySymbol} from "../../../fx/service/getDpUqBySymbol";

export const FutureInputField = ({
                                     fullWidth,
                                     inputProps,
                                     helperText,
                                     helperTextProps,
                                     textCount,
                                     fxDataForm,
                                 }: InputFieldProps) => {

    const dispatch = useAppDispatch();
    const isFuture: boolean = hasFutureProperty(fxDataForm)// If this is true, it means is future, if not then rate alert
    const defaultRate: number = (getRateByDealt(fxDataForm.source.currency, fxDataForm.fx) as number) ?
        (getRateByDealt(fxDataForm.source.currency, fxDataForm.fx) as number) : 888;
    const app = useAppSelector(selectApp);
    const futureTargetRateString = useAppSelector(selectFutureTargetRateString)
    const {
        handleFutureInputChange
    } = useFXCalculator({
        form: "order",
    });
    const timer = 0
    const updateRates = () => {
        const defaultRate = getRateByDealt(fxDataForm.source.currency, fxDataForm.fx);
        const defaultRateForDisplayString = defaultRate
            ? renderRate("ccy1", "ccy2", defaultRate, getDpUqBySymbol(fxDataForm.fx?.symbol!).dp, getDpUqBySymbol(fxDataForm.fx?.symbol!).uq, true)
            : "";
        dispatch(updateFXOrderFormFutureTargetRate(defaultRate?.toString() || ""));
        dispatch(updateFXOrderFormFutureTargetRateString(defaultRateForDisplayString));
    };
    const resetRates = () => {
        dispatch(updateFXOrderFormFutureTargetRate(""));
        dispatch(updateFXOrderFormFutureTargetRateString(""));
    };
    const handleInitialLoad = () => {
        // console.log("%cinit rate", "color: red; font-weight: bold;");
        resetRates();
        setTimeout(updateRates, timer);
    };
    const handleWentBackAndToggledFalse = () => {
        // console.log("%cinit rate, as went back and toggled false", "color: red; font-weight: bold;");
        resetRates();
        setTimeout(updateRates, timer);
    };
    const isFirstOpenOrMayGoBack = [
        isWentBackAndToggledFalseEnum.FIRST_OPEN_PAGE,
        isWentBackAndToggledFalseEnum.MAY_GO_BACK_AGAIN_BUT_HAVE_NOT_TOGGLE
    ].includes(app.isWentBackAndToggledFalse);

    useEffect(() => {
        if (!isFuture) return;
        if (!isComeBackFromLatterPage(app.backFromPath.path) && isFirstOpenOrMayGoBack) {
            handleInitialLoad();
        } else if (
            isComeBackFromLatterPage(app.backFromPath.path)
            && app.isWentBackAndToggledFalse === isWentBackAndToggledFalseEnum.WENT_BACK_AND_TOGGLE_FALSE
        ) {
            handleWentBackAndToggledFalse();
        }else if (!futureTargetRateString || futureTargetRateString===""){
            handleInitialLoad();
        }
    }, [fxDataForm.source.currency, fxDataForm.target.currency, fxDataForm.fx?.symbol]);

    // this is only for rate alert!!!
    useEffect(() => {
        if (!isFuture) {
            setTimeout(() => {
                const defaultRate = getRateByDealt(fxDataForm.source.currency, fxDataForm.fx);
                const defaultRateForDisplayString = defaultRate
                    ? renderRate("ccy1", "ccy2", defaultRate, getDpUqBySymbol(fxDataForm.fx?.symbol!).dp, getDpUqBySymbol(fxDataForm.fx?.symbol!).uq, true)
                    : "Not avail!!";
                dispatch(updateRateAlertFutureTargetRate(defaultRate?.toString() as string));
                dispatch(updateRateAlertFutureTargetRateString(defaultRateForDisplayString))
            }, 0);
        }
    }, [fxDataForm.fx?.symbol, fxDataForm.source, fxDataForm.target ])

    const handleBlur = () => {
        // console.log('blur')
        onEnterAndUnfocusToCheckBound(fxDataForm, defaultRate, handleFutureInputChange)
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onEnterAndUnfocusToCheckBound(fxDataForm, defaultRate, handleFutureInputChange)
        }
    };

    return (
        <InputFieldContainer>
            <FutureTargetPriceContainer
                fullWidth={fullWidth}
                helperTextProps={helperTextProps}
                fxDataForm={fxDataForm}
            >
                <StaticText>{fxDataForm.fx && fxDataForm.target.currency ?
                    renderRate(
                        fxDataForm.fx.currencies[0],
                        fxDataForm.fx.currencies[1],
                        "not applicable",
                        getDpUqBySymbol(fxDataForm.fx.symbol).dp,
                        getDpUqBySymbol(fxDataForm.fx.symbol).uq,
                        false,
                        true,
                    )
                    :
                    ``
                }
                </StaticText>
                <StyledInputField
                    value={fxDataForm.futureTargetRateString}
                    type="number"
                    {...inputProps}
                    onChange={(event) =>
                        handleInputChangeV2(event, fxDataForm, handleFutureInputChange
                        )}
                    fullWidth={fullWidth}
                    helperTextProps={helperTextProps}
                    disabled={!fxDataForm.target.currency}
                    onBlur={handleBlur}
                    onKeyPress={handleKeyPress}
                    inputType="number"
                    pattern="[0-9]*[.]?[0-9]*"
                    inputMode="decimal"
                    id={"future-input-field"}
                    fxDataForm={fxDataForm}
                />
            </FutureTargetPriceContainer>
        </InputFieldContainer>
    );
};

export const InputFieldContainer = styled.div``;

const StyledInputField = styled.input<InputFieldProps>`
    border: 0;
    //border-bottom: 1px solid var(--input-border-color);
    border-radius: 0;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 26px;
    background: none;
    outline: none;
    appearance: none;
    padding: 0px;

    ${({fullWidth}) => fullWidth && `width: 100%;`}

    ${({helperTextProps}) => {
        if (helperTextProps?.type) {
            switch (helperTextProps?.type) {
                case INPUT_FIELD_HELPER_TEXT_TYPE.SUCCESS:
                    return `border-color: var(--input-success-border-color);`;
                case INPUT_FIELD_HELPER_TEXT_TYPE.ERROR:
                    return `border-color: var(--input-error-border-color);`;
            }
        }
    }} // Add onBlur event


    ${({fullWidth}) => fullWidth && `width: 100%;`} ${({helperTextProps}) => {
        if (helperTextProps?.type) {
            switch (helperTextProps?.type) {
                case INPUT_FIELD_HELPER_TEXT_TYPE.SUCCESS:
                    return `border-color: var(--input-success-border-color);`;
                case INPUT_FIELD_HELPER_TEXT_TYPE.ERROR:
                    return `border-color: var(--input-error-border-color);`;
            }
        }
    }}

    ${({inputType}) => inputType && `type: ${inputType};`}

`;

const StaticText = styled.span`
    font-size: 20px;
    color: #cccccc;
    margin-right: 8px;
    font-weight: bold;
    white-space: nowrap;
    padding: 0px;
`;

const FutureTargetPriceContainer = styled.div<InputFieldProps>`
    display: flex;
    margin-bottom: 8px;
    border: 0;
    border-bottom: 1px solid var(--input-border-color);
    border-radius: 0;
    padding: 0 0 8px 0;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 26px;
    background: none;
    outline: none;
    appearance: none;

    ${({fullWidth}) => fullWidth && `width: 100%;`}
`;

interface HelperTextProps {
    helperText?: React.ReactNode;
    helperTextProps?: {
        align?: "left" | "between" | "right";
        type?: INPUT_FIELD_HELPER_TEXT_TYPE;
    };
    textCount?: {
        mode?: INPUT_FIELD_TEXT_COUNT_MODE;
        trim?: boolean;
        limit?: number;
    };
}

interface InputFieldProps extends HelperTextProps {
    fullWidth?: boolean;
    inputProps?: Omit<React.ComponentPropsWithRef<"input">, "value">;
    inputType?: string
    fxDataForm: FXOrderFormType | FXRateAlertType
}

