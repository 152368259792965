import fxAPI from "../../../api/fx.api";
import {AxiosResponse} from "axios";
import {GetRateAlertListResponseType} from "../../../types/fx.type";
import {GET_ORDER_STATUS_LIST_INVALID} from "../../../constants/errorMsg.constant";
import {ErrorObjForToastBar} from "../../../types/errorObjType.type";
import {store} from "../../../store/store"
import {ListRateAlertStateEnum} from "../enums/RateAlertTabEnum";

export const countActiveRateAlertByAPI = async () => {
    const maxRetries = store.getState().app.ra_retry + 1
    let attempt = 0;

    while (attempt < maxRetries) {
        try {
            const response: AxiosResponse<GetRateAlertListResponseType> = await fxAPI.getRateAlertList(ListRateAlertStateEnum.active)
            if (
                !response.data ||
                response.data.responseCode !== "0" ||
                !response.data.data
            ) throw {
                errorMsg: GET_ORDER_STATUS_LIST_INVALID,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar
            const rateAlertList = response.data.data;
            return rateAlertList.length;
        } catch (error) {
            attempt++;
            if (attempt >= maxRetries) {
                throw error;
            }
        }
    }
};
