import {store} from "../../../store/store";
export function getDpUqBySymbol(symbol: string): { dp: number, uq: number }  {
    const fxConfigDpUqConfig = store.getState().fxConfig.dpUqConfig
    const item = fxConfigDpUqConfig.find(item => item.symbol === symbol);
    if (item) {
        return { dp: item.dp, uq: item.uq };
    }else{
        console.error("Cannot find symbol!!")
        return { dp:4, uq:0 };
    }
}
