import { DialogTitle, dialogClasses } from "@mui/material";
import styled from "styled-components";

interface AlertDialogProps {
	hasBackgroundColor?: boolean;
}

const AlertDialogTitle = styled(DialogTitle)<AlertDialogProps>`
	.${dialogClasses.root} & {
		margin-bottom: 16px;
		padding: 16px 16px 4px 16px;
		font-family: inherit;
		font-size: 14px;
		font-weight: var(--font-weight-semi-bold);
		letter-spacing: normal;
		line-height: 21px;
	}
`;

export default AlertDialogTitle;
