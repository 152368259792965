import styled from "styled-components";

import { ReactComponent as MagniferIcon } from "../../assets/icons/magnifer.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";

interface StyledSearchBarProps {
	fullWidth?: boolean;
}

interface SearchBarProps extends StyledSearchBarProps {
	value: string;
	onClear?: () => void;
	inputProps?: Omit<React.ComponentPropsWithRef<"input">, "value">;
}

const StyledMagniferIcon = styled(MagniferIcon)``;
const StyledCloseIcon = styled(CloseIcon)``;

const StyledSearchBar = styled.div<StyledSearchBarProps>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 16px 24px 24px;
	border: 1px solid var(--secondary-border-color);
	border-radius: 9999px;
	padding-left: 20px;
	padding-right: 20px;
	background-color: var(--bordered-select-background-color);
	box-shadow: var(--card-shadow);
	${({ fullWidth }) => fullWidth && `width: 100%;`}

	input {
		width: 100%;
		height: 100%;
		border: 0;
		padding: 14px 0;
		font-family: var(--font-family);
		font-size: 14px;
		font-weight: var(--font-weight-semi-bold);
		outline: none;
		appearance: none;

	}

	${StyledMagniferIcon} {
		margin-right: 16px;
	}

	${StyledCloseIcon} {
		margin-left: 16px;
	}
`;

const SearchBar = ({
	value,
	fullWidth,
	onClear,
	inputProps,
}: SearchBarProps) => {
	/**
	 * Handles input change event.
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event - The input change event.
	 */
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (inputProps?.onChange) inputProps?.onChange(event);
	};

	return (
		<StyledSearchBar fullWidth={fullWidth}>
			{value.length < 1 && <StyledMagniferIcon className="mbb-icon" />}
			<input
				type="text"
				value={value}
				onChange={handleInputChange}
				{...inputProps}
			/>
			{value.length > 0 && onClear && (
				<StyledCloseIcon className="mbb-icon" onClick={onClear} />
			)}
		</StyledSearchBar>
	);
};

export default SearchBar;
