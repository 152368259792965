const genericErrorMsg: string = "Service is currently unavailable. Please try again later.";
const rejectCodePlaceHolder: string = "%ERRORCODE%";
const rejectCodeMsg: string = `[Reject Code: ${rejectCodePlaceHolder}]`;

interface ErrorMsgMap {
    [key: string]: string | undefined;
}

const errorMsgMap: ErrorMsgMap = {
    "default": genericErrorMsg,
    "MDIP": `${genericErrorMsg} ${rejectCodeMsg}`,
    "MDIP001": genericErrorMsg,
    // "MDIP3": undefined,
    "BTS": `${genericErrorMsg} ${rejectCodeMsg}`,
    "BTS001": genericErrorMsg,
    "ARTS": undefined,
    "ARTS001": undefined,
    // "PAYMENT": undefined, //comment out due to it is example
};

const SVR_APIS: string[] = ["MDIP", "BTS", "ARTS"]; // "PAYMENT"
const DEFAULT_ERRORCODE: string = "default";
export const mapErrorCodeToErrorMsg = (errorCode: string | undefined | null): string => {
    if(!errorCode) return <string>errorMsgMap[DEFAULT_ERRORCODE]
    let errorMsg: string | undefined = errorMsgMap[errorCode];
    if (!errorMsg) {
        SVR_APIS.forEach((api) => {
            if (errorCode.startsWith(api)) errorMsg = errorMsgMap[api];
        });
    }
    if (!errorMsg) errorMsg = errorMsgMap[DEFAULT_ERRORCODE]
    errorMsg = errorMsg?.replace(rejectCodePlaceHolder, errorCode) || genericErrorMsg; // Fallback to generic message if undefined
    return errorMsg;
};
