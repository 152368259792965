import { FX_CURRENCY_PAIR_AMOUNT } from "../../../constants/fx.constant";
import { FXType } from "../../../types/fx.type";

/**
 * Find the valid FX by two currency symbol
 * @param {Array<FXType>} fxList - FX list for search.
 * @param {Array<string>} currenciesText - Array of two currency symbol, e.g. ["JPY", "USD"].
 * @return {FXType|undefined} The found FX object OR `undefined`.
 */
const findFXByCurrenciesText = (
	fxList: FXType[],
	currenciesText: string[]
): FXType | undefined => {
	if (currenciesText.length !== FX_CURRENCY_PAIR_AMOUNT) return undefined;

	const possible = [
		`${currenciesText[0]}${currenciesText[1]}`,
		`${currenciesText[1]}${currenciesText[0]}`,
	];
	return fxList.find((v) => possible.indexOf(v.symbol) > -1);
};

export default findFXByCurrenciesText;
