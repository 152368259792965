import {createRef, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import styled from "styled-components";
import moment from "moment";

import Section from "../styled/sections/Section";
import SectionHeader from "../styled/sections/SectionHeader";
import SectionContent from "../styled/sections/SectionContent";
import SectionHeaderStartContainer from "../styled/sections/SectionHeaderStartContainer";
import SectionHeaderEndContainer from "../styled/sections/SectionHeaderEndContainer";
import GradientContainer from "../common/GradientContainer";
import SubText from "../styled/SubText";
import EmptyHeader from "../styled/EmptyHeader";
import EmptyMessage from "../styled/EmptyMessage";
import WatchlistFXCard, {StyledWatchlistFXCard} from "./WatchlistFXCard";
import AlertDialog from "../common/dialog/AlertDialog";

import {
    ACTIVATED_ACCOUNT_ROLE,
    ALLOWED_STREAMING_ROLE,
    NOT_ALLOWED_STREAMING_ROLE,
    NO_ACCOUNT_ROLE,
} from "../../constants/common.constant";
import {useAppSelector} from "../../utils/store.utils";
import {selectUser} from "../../slice/user";
import {
    selectSelectedWatchlistIndex,
    selectWatchlist,
} from "../../slice/watchlist";
import {formatURL} from "../../formatters/common";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import {
    formatDateGMTToMYT
} from "../../pages/order/services/formatDateToStringDDMonthYYYY";

interface WatchlistFXSectionProps {
    rateLastUpdateTime?: number;
}

const StyledWatchlistFXSection = styled(Section)`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;

    ${SectionContent} {
        overflow-y: auto;
    }

    ${StyledWatchlistFXCard} {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const WatchlistFXSection = ({
                                rateLastUpdateTime,
                            }: WatchlistFXSectionProps) => {
    const navigate = useNavigateThrottle();
    const intl = useIntl();
    const user = useAppSelector(selectUser);
    const watchlists = useAppSelector(selectWatchlist);
    const selectedWatchlistIndex = useAppSelector(selectSelectedWatchlistIndex);
    const [isAccountAlertDialogOpen, setIsAccountAlertDialogOpen] =
        useState<boolean>(false);
    const watchlistFXCardContainerRef = createRef<HTMLDivElement>();
    const isDDMonthYYYYHHmmss = rateLastUpdateTime!.toString().length === 14

    useEffect(() => {
        watchlistFXCardContainerRef.current?.scrollTo({
            top: 0,
        });
    }, [selectedWatchlistIndex]);

    /**
     * Handles the click event for the chart button.
     *
     * @param {string} fx - The FX value to navigate to.
     */
    const handleChartButtonClick = (fx: string) => {
        if (NO_ACCOUNT_ROLE.indexOf(user.type) > -1)
            setIsAccountAlertDialogOpen(true);
        else navigate(formatURL(`/fx/${fx}`));
    };

    return (
        <>
            <StyledWatchlistFXSection hasMarginBottom>
                <SectionHeader textWrap>
                    <SectionHeaderStartContainer>
                        {watchlists &&
                            watchlists[selectedWatchlistIndex] &&
                            watchlists[selectedWatchlistIndex].name}
                    </SectionHeaderStartContainer>
                    {ACTIVATED_ACCOUNT_ROLE.indexOf(user.type) > -1 &&
                        selectedWatchlistIndex !== 0 && (
                            <SectionHeaderEndContainer>
                                <a
                                    onClick={() =>
                                        navigate(
                                            formatURL(`/watchlist/edit/${selectedWatchlistIndex}`)
                                        )
                                    }
                                >
                                    {intl.formatMessage({
                                        id: "app.page.watchlist.watchlist.button.edit",
                                    })}
                                </a>
                            </SectionHeaderEndContainer>
                        )}
                </SectionHeader>
                <SectionContent hasPaddingX>
                    <>
                        {watchlists[selectedWatchlistIndex] &&
                        watchlists[selectedWatchlistIndex].fxList.length > 0 ? (
                            <GradientContainer ref={watchlistFXCardContainerRef}>
                                {watchlists[selectedWatchlistIndex] &&
                                    watchlists[selectedWatchlistIndex].fxList.map((v, i) => (
                                        <WatchlistFXCard
                                            key={`watchlist-fx-card-${i}`}
                                            fxObject={v}
                                            onChartClick={handleChartButtonClick}
                                        />
                                    ))}
                                {NOT_ALLOWED_STREAMING_ROLE.indexOf(user.type) > -1 &&
                                    rateLastUpdateTime && (
                                        <SubText>
                                            {
                                                isDDMonthYYYYHHmmss ? intl.formatMessage(
                                                        {
                                                            id: "app.page.watchlist.message.rateLastUpdateDatetime",
                                                        },
                                                        formatDateGMTToMYT(rateLastUpdateTime.toString(), true),
                                                    )
                                                    :
                                                    intl.formatMessage(
                                                        {
                                                            id: "app.page.watchlist.message.rateLastUpdateDatetime",
                                                        },
                                                        {
                                                            date: moment(12).format(
                                                                "DD MMM YYYY"
                                                            ),
                                                            time: moment(12).format("H:mm:ss A"),
                                                        }
                                                    )
                                            }
                                        </SubText>
                                    )}
                                {ALLOWED_STREAMING_ROLE.indexOf(user.type) > -1 && (
                                    <SubText>
                                        <b>
                                            {intl.formatMessage({
                                                id: "app.page.watchlist.message.disclaimer.header",
                                            })}
                                        </b>{" "}
                                        {intl.formatMessage({
                                            id: "app.page.watchlist.message.disclaimer.content",
                                        })}
                                    </SubText>
                                )}
                            </GradientContainer>
                        ) : (
                            <>
                                <EmptyHeader marginTop={50}>
                                    {intl.formatMessage({
                                        id: "app.page.watchlist.message.empty.header",
                                    })}
                                </EmptyHeader>
                                <EmptyMessage>
                                    {intl.formatMessage({
                                        id: "app.page.watchlist.message.empty.content",
                                    })}
                                </EmptyMessage>
                            </>
                        )}
                    </>
                </SectionContent>
            </StyledWatchlistFXSection>
            <AlertDialog
                titleComp={intl.formatMessage({
                    id: "app.page.watchlist.dialog.account.title",
                })}
                contentComp={intl.formatMessage({
                    id: "app.page.watchlist.dialog.account.content",
                })}
                confirmButtonText={intl.formatMessage({
                    id: "app.page.watchlist.dialog.account.button.ok",
                })}
                open={isAccountAlertDialogOpen}
                onConfirm={() => {
                    setIsAccountAlertDialogOpen(false);
                    navigate(formatURL("/account/product"));
                }}
                onClose={() => {
                    setIsAccountAlertDialogOpen(false);
                }}
            />
        </>
    );
};

export default WatchlistFXSection;
