import { useIntl } from "react-intl";
import styled from "styled-components";
import Helmet from "react-helmet";

import Layout from "../../components/common/Layout";
import Section from "../../components/styled/sections/Section";
import SectionContent from "../../components/styled/sections/SectionContent";
import Tag from "../../components/styled/Tag";
import Button from "../../components/styled/buttons/Button";
import FooterButtonContainer from "../../components/styled/buttons/FooterButtonContainer";

import { openAccount } from "../../utils/integration.utils";

import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import AccountProductImage from "../../assets/images/account-product.svg";

const AccountProductImageContainer = styled.div`
	background-image: url(${AccountProductImage});
	background-repeat: no-repeat;
	background-size: cover;

	&::before {
		content: "";
		display: block;
		padding-top: 56%;
	}
`;

const AccountProductTagContainer = styled.div`
	margin-top: 24px;
	padding-left: var(--common-layout-padding-x);
	padding-right: var(--common-layout-padding-x);
`;

const AccountProductSection = styled(Section)`
	flex: 1 0 auto;
`;

const AccountProductTitle = styled.h3`
	margin-top: 26px;
	margin-bottom: 8px;
	font-size: 20px;
	font-weight: var(--font-weight-semi-bold);
	line-height: 28px;
`;

const AccountProductDescription = styled.div`
	margin-top: 8px;
	margin-bottom: 24px;
	font-weight: var(--font-weight-light);
	line-height: 24px;
`;

const AccountAdvantageListItem = styled.div`
	display: flex;
	align-items: center;
	margin-top: 12px;
	margin-bottom: 12px;
	font-size: 14px;
	font-weight: var(--font-weight-light);
	line-height: 18px;

	.mbb-icon {
		margin-right: 8px;
	}
`;

const AccountAdvantageList = styled.div`
	margin-top: 24px;

	> ${AccountAdvantageListItem} {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
`;

const AccountProductPage = () => {
	const intl = useIntl();

	/**
	 * Handles the apply button click event.
	 * @param {React.MouseEvent} event - The click event object.
	 */
	const handleApply = (event: React.MouseEvent) => {
		openAccount();
	};

	return (
		<Layout title="">
			<Helmet>
				<title> </title>
			</Helmet>
			<AccountProductImageContainer />
			<AccountProductTagContainer>
				<Tag varient="info">Current Account</Tag>
			</AccountProductTagContainer>
			<AccountProductSection>
				<SectionContent hasPaddingX>
					<AccountProductTitle>
						{intl.formatMessage({
							id: "app.page.account.product.title",
						})}
					</AccountProductTitle>
					<AccountProductDescription>
						{intl.formatMessage({
							id: "app.page.account.product.description",
						})}
					</AccountProductDescription>
					<AccountAdvantageList>
						{Array(4)
							.fill(0)
							.map((v, i) => (
								<AccountAdvantageListItem
									key={`account-advantage-list-item-${i}`}
								>
									<CheckIcon className="mbb-icon" />
									<div>
										{intl.formatMessage({
											id: `app.page.account.product.advantage.${i}`,
										})}
									</div>
								</AccountAdvantageListItem>
							))}
					</AccountAdvantageList>
				</SectionContent>
			</AccountProductSection>
			<FooterButtonContainer>
				<Button theme="primary" size="lg" onClick={handleApply}>
					{intl.formatMessage({ id: "app.page.account.product.button.apply" })}
				</Button>
			</FooterButtonContainer>
		</Layout>
	);
};

export default AccountProductPage;
