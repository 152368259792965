import * as React from 'react';
import {useState} from 'react';
import {OrderStatusEnum} from "../order/enums/OrderStatusEnum";
import LoadingContainer from "../../components/styled/LoadingContainer";
import LoadingSpinner from "../../components/styled/LoadingSpinner";
import Layout from "../../components/common/Layout";
import {TypeSelectBar} from "./TypeSelectBar";
import {BoxContainer} from "./BoxContainer";
import {ListRateAlertStateEnum, RateAlertTabEnum} from "../rateAlert/enums/RateAlertTabEnum";
import {OrderStatusInfoReal} from "../order/interface/OrderStatusInfo";
import {RateAlertInfoReal} from "../rateAlert/interface/RateAlertInfo";
import {Helmet} from "react-helmet";
import {ListOrderAPIStateEnum} from "../../api/fx.api";
import {selectLastOrderStatusTab, selectTabBarPosition, updateLastOrderStatusTab} from "../../slice/fx";
import {useAppDispatch, useAppSelector} from "../../utils/store.utils";

type Props = {
    title: string,
    data: RateAlertInfoReal[] | OrderStatusInfoReal[] | undefined
    defaultSelectedType: RateAlertTabEnum | OrderStatusEnum,
    types: RateAlertTabEnum[] | OrderStatusEnum[],
    triggerFetch?: (state:ListOrderAPIStateEnum| ListRateAlertStateEnum) => void
    filterFunc: (...args: any[]) => any[]
};
export const PageAndTypeSelector = (props: Props) => {
    const dispatch = useAppDispatch();
    const lastOrderStatusTab = useAppSelector(selectLastOrderStatusTab)
    let defaultSelectedTypeHandling:RateAlertTabEnum | OrderStatusEnum | any= null
    if (props.defaultSelectedType in OrderStatusEnum) {
        defaultSelectedTypeHandling = lastOrderStatusTab
    }else{
        defaultSelectedTypeHandling = props.defaultSelectedType
    }

    const [selectedType, setSelectedType]
        = useState<RateAlertTabEnum | OrderStatusEnum>(defaultSelectedTypeHandling)
    const [currentPage, setCurrentPage] = useState<number>(1)

    const handleSelectType = (type: RateAlertTabEnum | OrderStatusEnum) => {
        // if (type === selectedType) {
        //     props.triggerFetch && props.triggerFetch()
        // }

        const combinedStateMap: Record<string, ListOrderAPIStateEnum | ListRateAlertStateEnum> = {
            // OrderStatusEnum mappings
            [OrderStatusEnum.ALL]: ListOrderAPIStateEnum.All,
            [OrderStatusEnum.PENDING]: ListOrderAPIStateEnum.Pending,
            [OrderStatusEnum.COMPLETED]: ListOrderAPIStateEnum.Completed,
            [OrderStatusEnum.EXPIRED]: ListOrderAPIStateEnum.Expired,
            [OrderStatusEnum.UNSUCCESSFUL]: ListOrderAPIStateEnum.Unsuccessful,
            [OrderStatusEnum.CANCELLED]: ListOrderAPIStateEnum.Cancelled,

            // RateAlertTabEnum mappings
            [RateAlertTabEnum.ACTIVE]: ListRateAlertStateEnum.active,
            [RateAlertTabEnum.HISTORY]: ListRateAlertStateEnum.history,
        };

        const param = combinedStateMap[type as keyof typeof combinedStateMap]
        props.triggerFetch && props.triggerFetch(param)
        setSelectedType(type)
        if (type in OrderStatusEnum) {
            dispatch(updateLastOrderStatusTab(type as OrderStatusEnum))
        }
    }

    const changePage = (change: number): void => {
        setCurrentPage((prevPage: number) => prevPage + change)
    }
    const resetCurrentPage = () => {
        setCurrentPage(1)
    }

    return (
        <Layout
            title={props.title}
        >
            <Helmet>
                <title>
                    {props.title}
                </title>
            </Helmet>
            <TypeSelectBar
                types={props.types}
                selectedType={selectedType}
                handleSelectType={handleSelectType}
                resetCurrentPage={resetCurrentPage}
            />
                {
                    !props.data ? (
                            <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: 700}}>
                                <LoadingContainer>
                                    <LoadingSpinner display="block" $centered/>
                                </LoadingContainer>
                            </div>
                        ) :
                        (
                            <>
                                <BoxContainer
                                    data={props.filterFunc(props.data, selectedType)}
                                    currentPage={currentPage}
                                    onChangePage={changePage}
                                    selectedType={selectedType}
                                />
                            </>
                        )
                }

        </Layout>
    );
};
