import styled from "styled-components";

import { ReactComponent as ChrevonDownIcon } from "../../assets/icons/chervon-down.svg";

interface HelperTextProps {
	helperText?: React.ReactNode;
	helperTextProps?: {
		align?: "left" | "between" | "right";
	};
}

interface SelectFieldProps
	extends React.ComponentProps<"div">,
		HelperTextProps {
	fullWidth?: boolean;
}

export const SelectFieldContainer = styled.div``;

const StyledSelectField = styled.div<SelectFieldProps>`
	font-size: 20px;
	font-weight: var(--font-weight-semi-bold);
	line-height: 26px;

	${({ fullWidth }) => fullWidth && `width: 100%;`}
`;

const SelectFieldRow = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	border-bottom: 1px solid #000;
	padding-bottom: 8px;

	${StyledSelectField} {
		flex: 1;
	}

	.mbb-icon {
		flex-shrink: 0;
		margin-left: 8px;
		margin-right: 2px;
	}
`;

const HelperText = styled.div<HelperTextProps>`
	display: flex;
	margin-bottom: 8px;
	font-size: 12px;
	font-weight: var(--font-weight-light);

	${({ helperText, helperTextProps }) => {
		if (helperTextProps?.align) {
			switch (helperTextProps?.align) {
				case "between":
					return `justify-content: space-between`;
				case "right":
					return `justify-content: flex-end;`;
			}
		}
	}}
`;

const SelectField = ({
	children,
	onClick,
	fullWidth,
	helperText,
	helperTextProps,
}: SelectFieldProps) => {
	return (
		<SelectFieldContainer>
			<SelectFieldRow onClick={onClick}>
				<StyledSelectField fullWidth={fullWidth}>{children}</StyledSelectField>
				<ChrevonDownIcon className="mbb-icon" />
			</SelectFieldRow>
			<HelperText helperText={helperText} helperTextProps={helperTextProps}>
				{helperText}
			</HelperText>
		</SelectFieldContainer>
	);
};

export default SelectField;
