type RoundMethod = 'up' | 'down' | 'off';

/**
 * Rounds a number to a specific number of decimal places using the specified rounding method.
 *
 * @param {number} value - The number to round.
 * @param {number} numberOfDecimalsToRound - The number of decimal places to round to.
 * @param {RoundMethod} roundMethod - The rounding method to use. Valid options are 'up', 'down', and 'off'.
 * @returns {number} - The rounded number.
 */
export function roundNumber(value: number, numberOfDecimaltoRound: number, roundmethod: RoundMethod): number {
    const factor = Math.pow(10, numberOfDecimaltoRound);

    if (roundmethod === 'up') {
        return Math.ceil(value * factor) / factor;
    } else if (roundmethod === 'down') {
        return Math.floor(value * factor) / factor;
    } else { // 'off'
        return Math.round(value * factor) / factor;
    }
}

