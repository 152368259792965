import { forwardRef, useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import FieldLabel from "../styled/FieldLabel";
import FormField from "../styled/FormField";
import FormFieldRow from "../styled/FormFieldRow";
import InputField, { InputFieldContainer } from "../common/InputField";
import BorderedSelect from "../styled/BorderedSelect";
import CalculationCard from "./CalculationCard";

import { FX_TRADE_CURRENCY_TYPE } from "../../constants/fx.constant";
import formatNumPadInputToField from "../../formatters/common/formatNumPadInputToField";
import { useAppDispatch, useAppSelector } from "../../utils/store.utils";
import { selectFXOrderForm } from "../../slice/fx";

interface TargetFormFieldProps {
	onTargetInputRefChange: (sourceInputElement: Element) => void;
	onCurrencyClick: (type: FX_TRADE_CURRENCY_TYPE) => void;
	onAmountClick: () => void;
}

const TargetCurrencyLabel = styled.div`
	font-size: 20px;
	font-weight: var(--font-weight-semi-bold);
	line-height: 26px;
`;

const TargetFormFieldRow = styled(FormFieldRow)`
	${TargetCurrencyLabel} {
		width: 83px;
		margin-right: 5px;
	}

	${InputFieldContainer} {
		flex: 1;
	}
`;

const TargetFormFields = forwardRef<HTMLInputElement, TargetFormFieldProps>(
	({ onTargetInputRefChange, onCurrencyClick, onAmountClick }, ref) => {
		const intl = useIntl();
		const fxOrderForm = useAppSelector(selectFXOrderForm);
		const dispatch = useAppDispatch();
		const targetCurrencyInputRef = useRef<HTMLInputElement | null>(null);

		useEffect(() => {
			const handleTargetInputFocus = () => {
				onTargetInputRefChange(targetCurrencyInputRef.current as Element);
			};

			targetCurrencyInputRef.current?.addEventListener(
				"focusin",
				handleTargetInputFocus
			);

			return () => {
				targetCurrencyInputRef.current?.removeEventListener(
					"focusin",
					handleTargetInputFocus
				);
			};
		}, [targetCurrencyInputRef.current]);

		/**
		 * Handles the click event of the currency select element.
		 *
		 * @param {React.MouseEvent<HTMLElement>} event - The click event.
		 */
		const handleCurrencySelectClick = (
			event: React.MouseEvent<HTMLElement>
		) => {
			event.preventDefault();
			event.stopPropagation();
			onCurrencyClick(FX_TRADE_CURRENCY_TYPE.TARGET);
		};

		/**
		 * Handles the click event for the amount element.
		 * Invokes the onAmountClick callback function.
		 *
		 * @function
		 */
		const handleAmountClick = () => {
			onAmountClick();
		};

		return (
			<>
				<FormField>
					<FieldLabel hasMarginBottom>
						{intl.formatMessage({
							id: "app.page.orderForm.form.order.label.to",
						})}
					</FieldLabel>
					<BorderedSelect onClick={handleCurrencySelectClick}>
						{fxOrderForm.target.currency ||
							intl.formatMessage({
								id: "app.page.orderForm.form.order.field.targetCurrency.placeholder",
							})}
					</BorderedSelect>
				</FormField>
				{fxOrderForm.selectingCurrencyType && fxOrderForm.target.currency && (
					<>
						{fxOrderForm.fx && (
							<CalculationCard
								source={{
									currency: fxOrderForm.source.currency,
									amount: fxOrderForm.source.amount.value,
								}}
								target={{
									currency: fxOrderForm.target.currency,
									amount: fxOrderForm.target.amount.value,
								}}
								selectingCurrencyType={fxOrderForm.selectingCurrencyType}
								fxObject={fxOrderForm.fx}
								fee={fxOrderForm.fee}
							/>
						)}
						<FormField>
							<FieldLabel hasMarginBottom>
								{intl.formatMessage({
									id: "app.page.orderForm.form.order.label.receive",
								})}
							</FieldLabel>
							<TargetFormFieldRow>
								<TargetCurrencyLabel>
									{fxOrderForm.target.currency}
								</TargetCurrencyLabel>
								<InputField
									id={"to-currency-input"}
									value={formatNumPadInputToField(
										fxOrderForm.target.amount.input,
										true
									)}
									fullWidth
									inputProps={{
										type: "text",
										ref: (node) => {
											targetCurrencyInputRef.current = node;
											if (typeof ref === "function") {
												ref(node);
											} else if (ref) {
												ref.current = node;
											}
										},
										readOnly: true,
										onClick: handleAmountClick,
									}}
									helperText={fxOrderForm.target.amount.helper?.text}
									helperTextProps={{
										type: fxOrderForm.target.amount.helper?.type,
									}}
								/>
							</TargetFormFieldRow>
						</FormField>
					</>
				)}
			</>
		);
	}
);

export default TargetFormFields;
