import styled from "styled-components";
import {
	DEFAULT_NATIONAL_FLAG_HEIGHT,
	DEFAULT_NATIONAL_FLAG_ICON_SIZE,
	DEFAULT_NATIONAL_FLAG_WIDTH,
	MIN_NATIONAL_FLAG_HEIGHT,
	MIN_NATIONAL_FLAG_WIDTH,
	NATIONAL_FLAG_ICON_SHADOW_LEFT_PX,
	NATIONAL_FLAG_ICON_SHADOW_PX,
	NATIONAL_FLAG_ICON_SHADOW_TOP_PX,
} from "../../constants/common.constant";
import { importAllImages } from "../../utils/common.utils";

interface StyledNationalFlagProps {
	width?: number;
	height?: number;
}

interface NationalFlagProps extends StyledNationalFlagProps {
	country: string;
}

const flagImages = importAllImages(
	require.context("../../assets/icons/countries", false, /\.png/)
);

export const StyledNationalFlag = styled.div<StyledNationalFlagProps>`
	position: relative;
	flex-shrink: 0;
	width: ${({ width }) =>
		width
			? width < MIN_NATIONAL_FLAG_WIDTH
				? `${MIN_NATIONAL_FLAG_WIDTH}px`
				: `${width}px`
			: `${DEFAULT_NATIONAL_FLAG_WIDTH}px`};
	height: ${({ height }) =>
		height
			? height < MIN_NATIONAL_FLAG_HEIGHT
				? `${MIN_NATIONAL_FLAG_HEIGHT}px;`
				: `${height}px;`
			: `${DEFAULT_NATIONAL_FLAG_HEIGHT}px;`};
	border-radius: 50%;

	> img {
		position: absolute;

		${({ width, height }) => {
			const w = width
				? width < MIN_NATIONAL_FLAG_WIDTH
					? MIN_NATIONAL_FLAG_WIDTH
					: width
				: DEFAULT_NATIONAL_FLAG_WIDTH;
			const h = height
				? height < MIN_NATIONAL_FLAG_HEIGHT
					? MIN_NATIONAL_FLAG_HEIGHT
					: height
				: DEFAULT_NATIONAL_FLAG_HEIGHT;

			return `
				top: calc(-${NATIONAL_FLAG_ICON_SHADOW_TOP_PX}px * (${h} / ${DEFAULT_NATIONAL_FLAG_ICON_SIZE}));
				left: calc(-${NATIONAL_FLAG_ICON_SHADOW_LEFT_PX}px * (${w} / ${DEFAULT_NATIONAL_FLAG_ICON_SIZE}));
				width: calc(${w}px + (${NATIONAL_FLAG_ICON_SHADOW_PX}px * (${w} / ${DEFAULT_NATIONAL_FLAG_ICON_SIZE})));
				height: calc(${h}px + (${NATIONAL_FLAG_ICON_SHADOW_PX}px * (${h} / ${DEFAULT_NATIONAL_FLAG_ICON_SIZE})));
			`;
		}}
	}
`;

const NationalFlag = ({ country, width, height }: NationalFlagProps) => {
	return (
		<StyledNationalFlag width={width} height={height}>
			<img
				src={flagImages[`${country.toUpperCase()}_64.png`]}
				alt={`${country}`}
				onContextMenu={(event) => event.preventDefault()}
			/>
		</StyledNationalFlag>
	);
};

export default NationalFlag;
