import {
	NewsGetAPIRequestType,
	NewsGetPayloadType,
} from "../../../../../types/news.type";
import { formatFXSymbolToCurrencies } from "../../../../fx";

/**
 * Format get news API request
 * @param {NewsGetPayloadType} payload - The get news payload.
 * @return {NewsGetAPIRequestType} The get news API request payload.
 */
const formatGetNewsPayload = (
	payload: NewsGetPayloadType
): NewsGetAPIRequestType => {
	let requestPayload: NewsGetAPIRequestType = {};
	if (payload.fx)
		requestPayload.ccy = formatFXSymbolToCurrencies(payload.fx).join(",");
	if (payload.lastNewsId) requestPayload.nid = payload.lastNewsId;
	if (payload.lastNewsDatetime) requestPayload.rt = payload.lastNewsDatetime;
	if (payload.keyword) requestPayload.keyword = payload.keyword;
	if (payload.limit) requestPayload.limit = payload.limit;
	if (payload.direction) requestPayload.getlatest = payload.direction;

	return requestPayload;
};

export default formatGetNewsPayload;
