/**
 * Converts a given date to Malaysian local time.
 *
 * @param {Date} date - The date to be converted.
 * @return {Date} - The converted date in Malaysian local time.
 */
export function toMalaysianTime(date: Date): Date {
    const offset = -480; // Malaysia is UTC+8, so the offset is -480 minutes
    const localTime = new Date(date);
    localTime.setMinutes(date.getMinutes() - date.getTimezoneOffset() + offset);
    return localTime;
}
