import * as React from 'react';
import styled from "styled-components";
import {OrderStatusEnum} from "../../order/enums/OrderStatusEnum";
import {RateAlertTabEnum} from "../enums/RateAlertTabEnum";
import {ReactComponent as EmptyRateAlertGraphic} from './../../../assets/images/empty-rate-alert.svg'
import useNavigateThrottle from "../../../hooks/useNavigateThrottle";
import {useAppSelector} from "../../../utils/store.utils";
import {selectFxRateAlertList} from "../../../slice/fx";
import {useIntl} from "react-intl";

type Props = {
    selectedType: RateAlertTabEnum | OrderStatusEnum
    handleCreateNewRateAlert: () => void
};
export const NoRateAlertAvailable = (props: Props) => {
    const navigate = useNavigateThrottle();
    const rateAlertListRedux = useAppSelector(selectFxRateAlertList)
    const intl = useIntl();

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
        }}>
            <EmptyDataContainer>
                <EmptyDataInnerContainer>
                    <EmptyMsgTitle>
                        {props.selectedType === RateAlertTabEnum.ACTIVE &&
                            <span>
                                No Rate Alert Available
                            </span>
                        }
                        {props.selectedType === RateAlertTabEnum.HISTORY &&
                            <span>
                                No Past Rate Alerts
                            </span>
                        }
                        {Object.values(OrderStatusEnum).includes(props.selectedType as OrderStatusEnum) &&
                            <span>No Orders to Display</span>
                        }
                    </EmptyMsgTitle>
                    <EmptyMsgSentence>
                        {props.selectedType === RateAlertTabEnum.ACTIVE &&
                            <EmptyMsgSentenceText>
                                Stay ahead of the market with our Rate Alert.
                            </EmptyMsgSentenceText>
                        }
                        {props.selectedType === RateAlertTabEnum.HISTORY &&
                            <EmptyMsgSentenceText>
                                Looking to stay updated on your ideal rates?
                            </EmptyMsgSentenceText>
                        }
                        <EmptyMsgSentenceText>
                            {Object.values(RateAlertTabEnum).includes(props.selectedType as RateAlertTabEnum)
                                ? "Create a new alert and never miss a beat!"
                                : getOrderStatusMessage(props.selectedType as OrderStatusEnum)
                            }
                        </EmptyMsgSentenceText>
                    </EmptyMsgSentence>
                </EmptyDataInnerContainer>
                {
                    !Object.values(OrderStatusEnum).includes(props.selectedType as OrderStatusEnum) &&
                    <CreateNewAlertButton>
                        <ButtonMsg
                            onClick={props.handleCreateNewRateAlert}
                        >Create New Alert</ButtonMsg>
                    </CreateNewAlertButton>
                }

            </EmptyDataContainer>
            <div style={{
                width: '100%',
            }}>
                <EmptyRateAlertGraphic style={{
                    width: '100%',
                    marginBottom: -20,
                    height: "auto",
                }} preserveAspectRatio="xMidYMax meet"/>
            </div>
        </div>
    );
};

function getOrderStatusMessage(selectType: OrderStatusEnum): string {
    switch (selectType) {
        case OrderStatusEnum.ALL:
            return "You have no orders to display at the moment.";
        case OrderStatusEnum.PENDING:
            return "You have no pending orders to display at the moment.";
        case OrderStatusEnum.COMPLETED:
            return "You have no completed orders to display at the moment.";
        case OrderStatusEnum.EXPIRED:
            return "Orders that have expired will be displayed here.";
        case OrderStatusEnum.UNSUCCESSFUL:
            return "Orders that were unsuccessful will be displayed here.";
        case OrderStatusEnum.CANCELLED:
            return "Orders that you've cancelled will be displayed here.";
        default:
            return "No order...";
    }
}

const EmptyDataContainer = styled.div`
    height: 132px;
    width: 327px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    margin-top: 20px;
`;

const EmptyDataInnerContainer = styled.div`
    height: 68px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;
`;

const EmptyMsgTitle = styled.div`
    font-weight: 600;
    font-size: 18px;
`
const EmptyMsgSentence = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 400;
    font-size: 14px;
`

const EmptyMsgSentenceText = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; /* Centers text horizontally */
`

const CreateNewAlertButton = styled.div`
    width: 172px;
    height: 40px;
    border-radius: 25px;
    padding: 9.5px 24px 9.5px 24px;
    background-color: #ffc600;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

`

const ButtonMsg = styled.div`
    font-size: 14px;
    font-weight: 600;
`

const SVGContainer = styled.div`
    width: 100%;
    height: 420px;
    position: relative;
    background-color: red;

    g {
        position: absolute;
        bottom: 0;
    }
`;
