export function capitalizeFirstLetter(inputString: string): string {
    // Split the input string into words
    const words = inputString.split(' ');

    // Capitalize the first letter of each word and make the rest lowercase
    const capitalizedWords = words.map(word =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );

    // Join the words back into a single string
    const result = capitalizedWords.join(' ');

    return result;
}

