import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { NewsStateType, NewsType } from "../types/news.type";

const initialState: NewsStateType = {
	fx: {
		latest: [],
		single: [],
		all: [],
	},
};

export const newsSlice = createSlice({
	name: "news",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		updateFXLatestNews: (state, action: PayloadAction<NewsType[]>) => {
			state.fx.latest = action.payload;
		},
		updateFXNewsListSingle: (state, action: PayloadAction<NewsType[]>) => {
			state.fx.single = action.payload;
		},
		prependFXNewsListSingle: (state, action: PayloadAction<NewsType[]>) => {
			state.fx.single = action.payload.concat(state.fx.single);
		},
		appendFXNewsListSingle: (state, action: PayloadAction<NewsType[]>) => {
			state.fx.single = state.fx.single.concat(action.payload);
		},
		updateFXNewsListAll: (state, action: PayloadAction<NewsType[]>) => {
			state.fx.all = action.payload;
		},
	},
});

export const {
	updateFXLatestNews,
	updateFXNewsListSingle,
	prependFXNewsListSingle,
	appendFXNewsListSingle,
	updateFXNewsListAll,
} = newsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectFXNews = (state: RootState) => state.news.fx;
export const selectFXLatestNews = (state: RootState) => state.news.fx.latest;
export const selectFXNewsListSingle = (state: RootState) =>
	state.news.fx.single;
export const selectFXNewsListAll = (state: RootState) => state.news.fx.all;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default newsSlice.reducer;
