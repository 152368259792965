import moment from "moment";
import {
  FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE,
  FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE,
} from "../../../../../constants/fx.constant";
import { getIntlObject } from "../../../../../utils/intl";

export const formatS2UMOFailOrder = (referenceId: string, orderId: string) => {
  const intl = getIntlObject();
  return {
    transactionStatus: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_TYPE.FAIL,
    statusDescription: intl.formatMessage({
      id: "app.page.transactionReceipt.statusDescription.fail.text",
    }),
    statusCode: FX_ORDER_TRANSACTION_ACKNOWLEDGEMENT_STATUS_CODE.FAIL,
    failureMessage: JSON.stringify({
      referenceId: referenceId,
      orderId: orderId,
    }),
  };
};

export default formatS2UMOFailOrder;
