import styled from "styled-components";
import NationalFlag, { StyledNationalFlag } from "./NationalFlag";
import {
	DEFAULT_FX_NATIONAL_FLAG_OVERLAP,
	MIN_NATIONAL_FLAG_WIDTH,
} from "../../constants/common.constant";

interface StyledFXNationalFlagProps {
	width?: number;
	height?: number;
	overlap?: number;
}

interface FXNationalFlagProps extends StyledFXNationalFlagProps {
	source: string;
	target: string;
}

export const StyledFXNationalFlag = styled.div<StyledFXNationalFlagProps>`
	display: flex;

	> ${StyledNationalFlag}:nth-child(1) {
		z-index: 2;
	}

	> ${StyledNationalFlag}:nth-child(2) {
		margin-left: ${({ overlap }) =>
			overlap ? `-${overlap}px` : `-${DEFAULT_FX_NATIONAL_FLAG_OVERLAP}px`};
		z-index: 1;
	}
`;

const FXNationalFlag = ({
	source,
	target,
	width,
	height,
	overlap,
}: FXNationalFlagProps) => {
	return (
		<StyledFXNationalFlag>
			<NationalFlag country={source} width={width} height={height} />
			<NationalFlag country={target} width={width} height={height} />
		</StyledFXNationalFlag>
	);
};

export default FXNationalFlag;
