import { CurrencyType, FXType } from "../../../types/fx.type";

/**
 * Find exchangeable currencies in FX list
 * @param {Array<FXType>} fxList - FX list for search.
 * @param {Array<CurrencyType>} currencies - Currency list for validate the currency that you provided.
 * @param {string} currency - The currency you want to search by.
 * @return {Array<CurrencyType>} Array of exchangeable currency object.
 */
const findExchangeableCurrenciesInFXList = (
	fxList: FXType[],
	currencies: CurrencyType[],
	currency: string
): CurrencyType[] => {
	const filtered = fxList
		.filter((v) => v.currencies.indexOf(currency) > -1)
		.map((w) => w.currencies.find((x) => x !== currency));
	return currencies.filter((v) => filtered.indexOf(v.currency) > -1);
};

export default findExchangeableCurrenciesInFXList;
