export function formatNumberWithThousandSeparator(input: string | number ): string {
    if (!input) {
        return "something is wrong";
    }
    // Check if the input is a valid number
    // @ts-ignore
    if (!/^[-+]?\d*\.?\d+(?:[Ee][-+]?\d+)?$/.test(input)) {
        throw new Error("Invalid input: Not a number");
    }

    // Convert the input string to a number and format it with two decimal places
    const num = Number(input);
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}
