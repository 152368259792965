import styled from "styled-components";
import ItemStartContent from "./ItemStartContent";

interface ItemStartContainerProps {
	textWrap?: boolean;
	disableHidden?:boolean
}

const ItemStartContainer = styled.div<ItemStartContainerProps>`
	display: flex;
	font-weight: var(--item-start-font-weight);
	overflow: ${props => props.disableHidden ? 'visible' : 'hidden'};
	white-space: nowrap;
	text-overflow: ellipsis;

	> .mbb-icon + ${ItemStartContent}, > ${ItemStartContent} + .mbb-icon {
		margin-left: 4px;
	}
`;

export default ItemStartContainer;
