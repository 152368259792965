import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DpUqConfigType, FxConfigType} from "../types/fxConfig.type";
import {RootState} from "../store/store";
import {countDecimals} from "../components/order/FutureOrderInput/FutureInputField/services/countDecimals";

const initialState: FxConfigType = {
    dpUqConfig: []
}

const fxConfigSlice = createSlice({
    name: "fxConfig",
    initialState,
    reducers: {
        updateFxConfigDpUqConfig: (
            state,
            action: PayloadAction<DpUqConfigType[]>
        ) => {
            state.dpUqConfig = action.payload;
        },
        updateFxConfigDpUqConfigWithRate: (
            state,
            action: PayloadAction<rateObject>
        ) => {
            const {symbol, ask, dp, uq} = action.payload;
            state.dpUqConfig = state.dpUqConfig.map(item => {
                if (item.symbol === symbol) {

                    // console.log(`Old dp: ${item.dp}, Old uq: ${item.uq}`);
                    const dpForDefault: number = ask ? countDecimals(ask): 0 ;
                    const uqForDefault: number = 0
                    const isParamDpDefined = dp !== undefined && dp !== null
                    const isParamUqDefined = uq !== undefined && uq !== null
                    const isAuthDPDefined = item.dp !== undefined && item.dp !== null
                    const isAuthUqDefined = item.uq !== undefined && item.uq !== null
                    const newDp = isParamDpDefined ? dp : (isAuthDPDefined) ? item.dp : dpForDefault
                    const newUq = isParamUqDefined ? uq : (isAuthUqDefined) ? item.uq : uqForDefault
                    // console.log(`New dp: ${newDp}, New uq: ${newUq}`);
                    return {...item, dp: newDp, uq: newUq};
                }
                return item;
            });
        },
    }
});
export const {
    updateFxConfigDpUqConfig,
    updateFxConfigDpUqConfigWithRate
} = fxConfigSlice.actions;
export const selectFxConfig = (state: RootState) => state.fxConfig
export const selectFxConfigDpUqConfig = (state: RootState) => state.fxConfig.dpUqConfig
export default fxConfigSlice.reducer;

type rateObject = {
    ask: number
    dp: number;
    uq: number;
    symbol: string;
};
