import {
	CurrencyAPIResponseType,
	CurrencyType,
} from "../../../../../types/fx.type";

/**
 * Format currency API response
 * @param {Array<CurrencyAPIResponseType>} response - The API response currency object
 * @return {Array<CurrencyType>} The formatted array of currency.
 */
const formatCurrencies = (
	response: CurrencyAPIResponseType[]
): CurrencyType[] =>
	response.map((v) => ({
		currency: v.ccy,
		name: v.ccy_name,
		keywords: v.keyword.split(", "),
		config: {
			marketOrder: {
				fee: { conversion: v.mo_conversion_fee },
				amount: { min: v.mo_min_amt, max: v.mo_max_amt },
			},
			futureOrder: {
				fee: { conversion: v.fo_conversion_fee },
				amount: { min: v.fo_min_amt, max: v.fo_max_amt },
			},
		},
	}));

export default formatCurrencies;
