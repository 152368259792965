import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store/store";
import {NumPadInputType} from "../types/common.type";
import {
    CurrencyType,
    FXFormPayloadActionType,
    FXOrderFeeType,
    FXOrderFormBankAccountType,
    FXOrderFormFeeType,
    FXOrderFormType,
    FXStateType,
    FXTradeType,
    FXType,
} from "../types/fx.type";
import {
    DEFAULT_FX_CALCULATOR_FORM_FIELD,
    DEFAULT_FX_ORDER_FORM_FIELD, DEFAULT_FX_RATE_ALERT_FIELD,
    FX_TRADE_CURRENCY_TYPE,
} from "../constants/fx.constant";
import {UserBankAccountType} from "../types/user.type";
import {DEFAULT_ORDER_STATUS_INFO, OrderStatusInfoReal} from "../pages/order/interface/OrderStatusInfo";
import {RateAlertInfoReal} from "../pages/rateAlert/interface/RateAlertInfo";
import {OrderStatusEnum} from "../pages/order/enums/OrderStatusEnum";
import {RateAlertTabEnum} from "../pages/rateAlert/enums/RateAlertTabEnum";

const initialState: FXStateType = {
    currencies: [],
    forexes: [],
    forms: {
        calculator: JSON.parse(JSON.stringify(DEFAULT_FX_CALCULATOR_FORM_FIELD)),
        order: JSON.parse(JSON.stringify(DEFAULT_FX_ORDER_FORM_FIELD)),
        rateAlert: JSON.parse(JSON.stringify(DEFAULT_FX_RATE_ALERT_FIELD)),
    },
    orderStatusDetailPage: JSON.parse(JSON.stringify(DEFAULT_ORDER_STATUS_INFO)),
    orderStatusList: [],
    rateAlertList:[],
    currentOrPrevOrderId:"",
    hideCreateRateAlertItems:false,
    unitQuote:0,
    dp:0,
    lastOrderStatusTab: OrderStatusEnum.ALL,
    tabBarPosition:0,
    allowGetRate:true
};

export const fxSlice = createSlice({
    name: "fx",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        updateRateAlertFrom: (state, action) => {
            state.forms.rateAlert.source = action.payload
        },
        updateRateAlertTo: (state, action) => {
            state.forms.rateAlert.target = action.payload
        },
        resetRateAlertTo: (state) => {
            state.forms.rateAlert.target.currency = ""
            state.forms.rateAlert.target.name = ""
        },
        swapRateAlertFromAndTo: (state) => {
            const { source, target } = state.forms.rateAlert;

            state.forms.rateAlert.source = target;
            state.forms.rateAlert.target = source;
        },

        updateRateAlertExpiryDate: (state, action) => {
            state.forms.rateAlert.expiryDate = action.payload;
        },
        updateRateAlertFX: (state, action: PayloadAction<FXType | undefined>) => {
            state.forms.rateAlert.fx = action.payload;
        },
        updateRateAlertFutureTargetRate: (state, action: PayloadAction<string | number>) => {
            state.forms.rateAlert.futureTargetRate = action.payload;
        },
        updateRateAlertFutureTargetRateString: (state, action: PayloadAction<string | number>) => {
            state.forms.rateAlert.futureTargetRateString = action.payload;
        },
        updateRateAlertList: (state, action: PayloadAction<RateAlertInfoReal[]>)=>{
            state.rateAlertList = action.payload
        },
        updateHideCreateRateAlertItems: (state, action: PayloadAction<boolean>)=>{
            state.hideCreateRateAlertItems = action.payload
        },
        updateCurrencies: (state, action: PayloadAction<CurrencyType[]>) => {
            state.currencies = action.payload;
        },
        updateForexes: (state, action: PayloadAction<FXType[]>) => {
            state.forexes = action.payload;
        },
        updateFXTradeForm: (
            state,
            action: PayloadAction<
                FXFormPayloadActionType<FXTradeType | FXOrderFormType>
            >
        ) => {
            switch (action.payload.form) {
                case "calculator":
                    state.forms.calculator = action.payload.data as FXTradeType;
                    break;
                case "order":
                    state.forms.order = action.payload.data as FXOrderFormType;
                    break;
            }
        },
        updateFXTradeFormAmount: (
            state,
            action: PayloadAction<
                FXFormPayloadActionType<{
                    type: FX_TRADE_CURRENCY_TYPE;
                    amount: NumPadInputType;
                }>
            >
        ) => {
            state.forms[action.payload.form][action.payload.data.type].amount =
                action.payload.data.amount;
        },
        updateFXTradeFormFX: (
            state,
            action: PayloadAction<FXFormPayloadActionType<FXType | undefined>>
        ) => {
            state.forms[action.payload.form].fx = action.payload.data;
        },
        updateFXTradeFormSelectingCurrencyType: (
            state,
            action: PayloadAction<FXFormPayloadActionType<FX_TRADE_CURRENCY_TYPE>>
        ) => {
            state.forms[action.payload.form].selectingCurrencyType =
                action.payload.data;
        },
        updateFXOrderForm: (state, action: PayloadAction<FXOrderFormType>) => {
            state.forms.order = action.payload;
        },
        updateFXOrderFormExpiryDate: (state, action: PayloadAction<string>) => {
            state.forms.order.expiryDate = action.payload;
        },
        updateFXOrderFormFutureTargetRate: (state, action: PayloadAction<string | number>) => {
            state.forms.order.futureTargetRate = action.payload;
        },
        updateFXOrderFormFutureTargetRateString: (state, action: PayloadAction<string>) => {
            state.forms.order.futureTargetRateString = action.payload;
        },

        resetFXOrderForm: (state) => {
            state.forms.order = JSON.parse(
                JSON.stringify(DEFAULT_FX_ORDER_FORM_FIELD)
            );
        },
        updateFXOrderFormAmount: (
            state,
            action: PayloadAction<{
                type: FX_TRADE_CURRENCY_TYPE;
                amount: NumPadInputType;
            }>
        ) => {
            state.forms.order[action.payload.type].amount = action.payload.amount;
        },
        updateFXOrderFormFee: (
            state,
            action: PayloadAction<FXOrderFormFeeType>
        ) => {
            state.forms.order.fee = action.payload;
        },
        updateFXOrderFormFX: (state, action: PayloadAction<FXType | undefined>) => {
            state.forms.order.fx = action.payload;
        },
        updateFXOrderFormSelectingCurrencyType: (
            state,
            action: PayloadAction<FX_TRADE_CURRENCY_TYPE>
        ) => {
            state.forms.order.selectingCurrencyType = action.payload;
        },
        updateFXOrderFormBankAccount: (
            state,
            action: PayloadAction<FXOrderFormBankAccountType>
        ) => {
            state.forms.order.bankAccount = action.payload;
        },
        updateFXOrderFormSourceBankAccount: (
            state,
            action: PayloadAction<UserBankAccountType>
        ) => {
            state.forms.order.bankAccount.source = action.payload;
        },
        updateFXOrderFormTargetBankAccount: (
            state,
            action: PayloadAction<UserBankAccountType>
        ) => {
            state.forms.order.bankAccount.target = action.payload;
        },
        updateFXOrderFormBankAccountByType: (
            state,
            action: PayloadAction<{
                type: keyof FXOrderFormBankAccountType;
                account: UserBankAccountType;
            }>
        ) => {
            state.forms.order.bankAccount[action.payload.type] =
                action.payload.account;
        },
        updateOrderStatusInfoReal: (
            state,
            action: PayloadAction<OrderStatusInfoReal>
        ): void => {
            state.orderStatusDetailPage = action.payload
        },
        updateOrderStatusList: (
            state,
            action: PayloadAction<OrderStatusInfoReal[]>
        ): void => {
            state.orderStatusList = action.payload
        },
        updateCurrentOrPrevOrderId: (
            state,
            action: PayloadAction<string>
        ): void => {
            state.currentOrPrevOrderId = action.payload
        },
        updateUnitQuote:(
            state,
            action: PayloadAction<number>
        ):void=>{
            state.unitQuote = action.payload
        },
        updateDP:(
            state,
            action: PayloadAction<number>
        ):void=>{
            state.dp = action.payload
        },
        updateLastOrderStatusTab:(
            state,
            action: PayloadAction<OrderStatusEnum>
        ):void=>{
            state.lastOrderStatusTab = action.payload
        },
        updateTabBarPosition:(
            state,
            action: PayloadAction<number>
        ):void=>{
            state.tabBarPosition = action.payload
        },
        disableGetRate:(
            state,
        ):void=>{
            state.allowGetRate = false
        }
    },
});

export const {
    updateCurrencies,
    updateForexes,
    updateFXTradeForm,
    updateFXTradeFormAmount,
    updateFXTradeFormFX,
    updateFXTradeFormSelectingCurrencyType,
    updateFXOrderForm,
    resetFXOrderForm,
    updateFXOrderFormExpiryDate,
    updateFXOrderFormFutureTargetRate,
    updateFXOrderFormAmount,
    updateFXOrderFormFee,
    updateFXOrderFormFX,
    updateFXOrderFormSelectingCurrencyType,
    updateFXOrderFormBankAccount,
    updateFXOrderFormBankAccountByType,
    updateFXOrderFormSourceBankAccount,
    updateFXOrderFormTargetBankAccount,
    updateRateAlertFrom,
    updateRateAlertTo,
    resetRateAlertTo,
    swapRateAlertFromAndTo,
    updateRateAlertExpiryDate,
    updateRateAlertFX,
    updateRateAlertFutureTargetRate,
    updateRateAlertList,
    updateOrderStatusInfoReal,
    updateOrderStatusList,
    updateCurrentOrPrevOrderId,
    updateHideCreateRateAlertItems,
    updateUnitQuote,
    updateDP,
    updateFXOrderFormFutureTargetRateString,
    updateRateAlertFutureTargetRateString,
    updateLastOrderStatusTab,
    updateTabBarPosition,
    disableGetRate
} = fxSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectCurrencies = (state: RootState) => state.fx.currencies;
export const selectFXList = (state: RootState) => state.fx.forexes;
export const selectFXCalculatorForm = (state: RootState) =>
    state.fx.forms.calculator;
export const selectFXOrderForm = (state: RootState) => state.fx.forms.order;
export const selectFutureTargetRateString = (state: RootState) => state.fx.forms.order.futureTargetRateString;
export const selectRateAlertFutureTargetRateString = (state: RootState) => state.fx.forms.rateAlert.futureTargetRateString;
export const selectFXRateAlert = (state: RootState) => state.fx.forms.rateAlert

export const selectFxOrderStatusDetailPage = (state: RootState) => state.fx.orderStatusDetailPage

export const selectFxOrderStatusList = (state:RootState) => state.fx.orderStatusList

export const selectFxRateAlertList = (state:RootState)=> state.fx.rateAlertList

export const selectCurrentOrPrevOrderId = (state:RootState)=> state.fx.currentOrPrevOrderId

export const selectHideCreateRateAlertItems = (state:RootState)=> state.fx.hideCreateRateAlertItems

export const selectFXUnitQuote = (state: RootState)=> state.fx.unitQuote

export const selectLastOrderStatusTab = (state:RootState)=> state.fx.lastOrderStatusTab

export const selectTabBarPosition = (state:RootState)=> state.fx.tabBarPosition


export const selectDP = (state: RootState)=> state.fx.dp



export const selectAllowGetRate = (state: RootState) => state.fx.allowGetRate

export default fxSlice.reducer;
