import styled from "styled-components";
import { findAll as splitForHighlightByWords } from "highlight-words-core";
import moment from "moment";

import Card from "../styled/Card";
import HighlightText from "../styled/HighlightText";

import { NewsType } from "../../types/news.type";
import {
	DEFAULT_NEWS_DATE_FORMAT,
	DEFAULT_NEWS_TIME_FORMAT,
} from "../../constants/news.constant";

interface NewsCardProps extends React.ComponentPropsWithRef<"div"> {
	news: NewsType;
	highlightBy?: string;
	onClick?: () => void;
}

export const StyledNewsCard = styled(Card)`
	margin-top: 24px;
	margin-bottom: 24px;
`;

const FXNewsDatetime = styled.div`
	color: var(--text-color-secondary);
	font-size: 12px;
	font-weight: var(--font-weight-light);
	line-height: 16px;
`;

const FXNewsHeadline = styled.div`
	font-weight: var(--font-weight-light);
	line-height: 24px;

	& + ${FXNewsDatetime} {
		margin-top: 10px;
	}
`;

const NewsCard = ({ news, highlightBy, onClick }: NewsCardProps) => {
	const highlight = (
		sourceText: string,
		searchWords: string[]
	): React.ReactNode => {
		const chunks = splitForHighlightByWords({
			autoEscape: true,
			caseSensitive: false,
			searchWords,
			textToHighlight: sourceText,
		});
		return (
			<>
				{chunks.map((chunk, i) => {
					const { end, highlight, start } = chunk;
					const text = sourceText.substring(start, end);
					return highlight ? (
						<HighlightText key={`news-highlight-text-${i}`}>
							{text}
						</HighlightText>
					) : (
						text
					);
				})}
			</>
		);
	};

	return (
		<StyledNewsCard onClick={onClick}>
			<FXNewsHeadline>
				{news.headline && highlightBy
					? highlight(news.headline, [highlightBy])
					: news.headline}
			</FXNewsHeadline>
			<FXNewsDatetime>
				{moment
					.utc(news.datetime, "YYYYMMDDHHmmss")
					.local()
					.format(`${DEFAULT_NEWS_DATE_FORMAT} • ${DEFAULT_NEWS_TIME_FORMAT}`)}
			</FXNewsDatetime>
		</StyledNewsCard>
	);
};

export default NewsCard;
