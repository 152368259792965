import moment from "moment";

// export function daysUntilDateFromNowMalaysiaTime(date: Date): number {
//     // Convert the input Date object to a Moment.js object in Malaysia's timezone
//     const targetDate = moment(date).tz('Asia/Kuala_Lumpur').startOf('day');
//
//     // Get the current date and time in Malaysia's timezone, also start of the day for accurate day difference calculation
//     const now = moment().tz('Asia/Kuala_Lumpur').startOf('day');
//
//     // Calculate the difference in days
//     const differenceInDays = targetDate.diff(now, 'days');
//
//     return differenceInDays;
// }
//

export function daysUntilDateFromNowMalaysiaTime(dateString: string): number {
    // Ensure the input is of expected length
    if(!dateString) return 0
    if (dateString.length !== 14) {
        return 0
    }

    // Extract date parts from the input string
    const year = parseInt(dateString.substring(0, 4), 10);
    const month = parseInt(dateString.substring(4, 6), 10) - 1; // month is 0-indexed in JavaScript
    const day = parseInt(dateString.substring(6, 8), 10);
    const hour = parseInt(dateString.substring(8, 10), 10);
    const minute = parseInt(dateString.substring(10, 12), 10);
    const second = parseInt(dateString.substring(12, 14), 10);

    // Convert the input string to a date object in Malaysia's timezone
    const targetDate = moment.tz({ year, month, day, hour, minute, second }, 'Asia/Kuala_Lumpur').startOf('day');

    // Get the current date and time in Malaysia's timezone, also start of the day for accurate day difference calculation
    const now = moment().tz('Asia/Kuala_Lumpur').startOf('day');

    // Calculate the difference in days
    const differenceInDays = targetDate.diff(now, 'days');

    return differenceInDays;
}
