import GraphemeSplitter from "grapheme-splitter";

/**
 * A utility class for splitting strings into graphemes.
 * @constructor
 */
const splitter = new GraphemeSplitter();

/**
 * Serializes an object into a query string.
 *
 * @param {any} obj - The object to be serialized.
 * @param {string} [prefix] - Optional prefix for the query parameters.
 * @returns {string} - The serialized query string.
 */
export const serialize = (obj: any, prefix?: string): string => {
	let str = [];
	for (let p in obj) {
		if (obj.hasOwnProperty(p)) {
			let k = prefix ? `${prefix}.${p}` : p,
				v = obj[p];
			str.push(
				v !== null && typeof v === "object"
					? serialize(v, k)
					: `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
			);
		}
	}
	return str.join("&");
};

/**
 * Checks whether a number is empty.
 *
 * @param {number} [value] - The number to check.
 * @returns {boolean} True if the number is empty, false otherwise.
 */
export const isNumberEmpty = (value?: number): boolean => {
	return !value && value !== 0;
};

/**
 * Retrieves a value from the browser's localStorage.
 *
 * @param {string} key - The key of the value to retrieve.
 * @param {boolean} [isJson=true] - Indicates whether the retrieved value should be parsed as JSON. Defaults to true.
 * @returns {T | null} - The retrieved value or null if not found or there was an error.
 */
export const getLocalStorage = <T>(key: string, isJson = true): T | null => {
	const strVal = localStorage.getItem(key);

	if (strVal) {
		try {
			return isJson ? JSON.parse(strVal) : strVal;
		} catch (error) {
			console.log(error);
			return null;
		}
	}

	return null;
};

/**
 * Sets a value in the Local Storage using the specified key.
 * If the value is not a string, it will be serialized using JSON.stringify().
 *
 * @param {string} key - The key to use for the Local Storage entry.
 * @param {*} value - The value to store in the Local Storage entry.
 * @throws {Error} If an error occurs while setting the value in the Local Storage.
 */
export const setLocalStorage = (key: string, value: any) => {
	try {
		if (typeof value !== "string") value = JSON.stringify(value);
		localStorage.setItem(key, value);
	} catch (error) {
		console.log(error);
	}
};

/**
 * Deletes the specified item from the local storage.
 *
 * @param {string} key - The key of the item to be deleted.
 * @returns {void}
 */
export const deleteLocalStorage = (key: string) => {
	localStorage.removeItem(key);
};

/**
 * Retrieves a value from the session storage based on a given key.
 *
 * @param {string} key - The key to search for in the session storage.
 * @param {boolean} [isJson=true] - Indicates whether the value is expected to be JSON formatted.
 * @returns {T | null} - The value corresponding to the given key, or null if the key does not exist or if there was an error parsing the value.
 */
export const getSessionStorage = <T>(key: string, isJson = true): T | null => {
	const strVal = sessionStorage.getItem(key);

	if (strVal) {
		try {
			return isJson ? JSON.parse(strVal) : strVal;
		} catch (error) {
			console.log(error);
			return null;
		}
	}

	return null;
};

/**
 * Set a value in the session storage under a specified key.
 *
 * @param {string} key - The key under which the value should be stored.
 * @param {*} value - The value to be stored.
 * @returns {void}
 *
 * @throws {Error} If an error occurs while setting the value in the session storage.
 *
 * @example
 * setSessionStorage("username", "John");
 */
export const setSessionStorage = (key: string, value: any) => {
	try {
		if (typeof value !== "string") value = JSON.stringify(value);
		sessionStorage.setItem(key, value);
	} catch (error) {
		console.log(error);
	}
};

/**
 * Deletes the specified key from session storage.
 *
 * @param {string} key - The key of the item to be deleted from session storage.
 * @returns {void}
 */
export const deleteSessionStorage = (key: string) => {
	sessionStorage.removeItem(key);
};

/**
 * Retrieves the value of a query parameter from the current URL by its key.
 * @param {string} key - The key of the query parameter.
 * @returns {string | null} - The value of the specified query parameter, or null if not found.
 */
export const getURLQueryValueByKey = (key: string): string | null => {
	const url: URL = new URL(window.location.href);
	const q: URLSearchParams = new URLSearchParams(url.search);
	return q.get(key);
};

/**
 * Compares two arrays to check if every element in the target array is included in the source array.
 *
 * @param {any[]} source - The source array to compare against.
 * @param {any[]} target - The target array containing elements to compare.
 * @returns {boolean} - True if every element in the target array is included in the source array, false otherwise.
 */
export const compareArray = (source: any[], target: any[]): boolean => {
	return target.every((v) => source.includes(v));
};

/**
 * Returns the number of decimal places in a given number.
 * @param {number} num - The number to calculate the decimal places for.
 * @returns {number} - The number of decimal places in the given number.
 */
export const getDecimals = (num: number): number => {
	const stringified = num.toString().split(".");
	return stringified.length === 2 ? stringified[1].length : 0;
};

/**
 * Calculates the real length of the given text by counting Unicode graphemes.
 *
 * @param {string} text - The text to be measured.
 * @returns {number} The real length of the text in number of graphemes.
 */
export const getRealTextLength = (text: string): number => {
	return splitter.countGraphemes(text);
};

/**
 * Builds a bad match table for the given string.
 *
 * @param {string} str - The string to build the bad match table for.
 * @returns {Object} - The bad match table as an object with characters as keys and their respective bad match values as values.
 */
const buildBadMatchTable = (str: string) => {
	const tableObj: { [x: string]: number } = {};
	const strLength = str.length;
	for (let i = 0; i < strLength - 1; i++) {
		tableObj[str[i]] = strLength - 1 - i;
	}
	if (tableObj[str[strLength - 1]] === undefined) {
		tableObj[str[strLength - 1]] = strLength;
	}
	return tableObj;
};

/**
 * Performs the Boyer-Moore text search algorithm to find the first occurrence of a pattern in a given string.
 *
 * @param {string} str - The string to search in.
 * @param {string} pattern - The pattern to search for.
 * @returns {number} - The index of the first occurrence of the pattern in the string, or -1 if not found.
 */
export const boyerMooreTextSearch = (str: string, pattern: string) => {
	const badMatchTable = buildBadMatchTable(pattern);
	let offset = 0;
	const patternLastIndex = pattern.length - 1;
	const maxOffset = str.length - pattern.length;
	// if the offset is bigger than maxOffset, cannot be found
	while (offset <= maxOffset) {
		let scanIndex = 0;
		while (pattern[scanIndex] === str[scanIndex + offset]) {
			if (scanIndex === patternLastIndex) {
				// found at this index
				return offset;
			}
			scanIndex++;
		}
		const badMatchString = str[offset + patternLastIndex];
		if (badMatchTable[badMatchString]) {
			// increase the offset if it exists
			offset += badMatchTable[badMatchString];
		} else {
			offset++;
		}
	}
	return -1;
};

/**
 * Parses a JSON Web Token (JWT) and returns its payload.
 *
 * @param {string} token - The JWT to be parsed.
 * @returns {Object|null} - The payload of the JWT, or null if the token is invalid.
 * @throws {Error} - If an error occurs during parsing.
 *
 * @example
 * const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";
 * const payload = parseJwt(token);
 *
 */
export const parseJwt = (token: string) => {
	if (!token) return null;

	if (token.split(".").length !== 3) return null;

	try {
		return JSON.parse(window.atob(token.split(".")[1]));
	} catch (error) {
		console.log(error);
		return null;
	}
};

/**
 * Copies the given text to the clipboard.
 *
 * @param {string} text - The text to be copied to the clipboard.
 * @param {HTMLElement=} el - Optional element where the temporary textarea will be appended. If not provided, it will be appended to the document body.
 * @returns {void}
 */
export const copyToClipboard = (text: string, el?: HTMLElement) => {
	if (navigator.clipboard && window.isSecureContext) {
		navigator.clipboard.writeText(text);
	} else {
		const textarea = document.createElement("textarea");
		textarea.innerText = text;
		if (el) {
			el.appendChild(textarea);
		} else {
			document.body.appendChild(textarea);
		}
		textarea.focus();
		textarea.select();
		document.execCommand("copy");
		if (el) {
			el.removeChild(textarea);
		} else {
			textarea.remove();
		}
	}
};

/**
 * Filters and converts a string value to a float number.
 *
 * @param {string} value - The string value to be filtered and converted.
 * @returns {number} - The float number representation of the value, or NaN if the value is not valid.
 */
export const filterFloat = (value: string): number => {
	if (/^(-|\+)?([0-9]+(\.[0-9]+)?|Infinity)$/.test(value)) return Number(value);
	return NaN;
};

// export const formatNumber = (num: number | string, decimal = 0): string => {
// 	let numberText =
// 		typeof num === "string"
// 			? Number.parseFloat(num).toFixed(decimal < 0 ? 0 : decimal)
// 			: num.toFixed(decimal < 0 ? 0 : decimal);

// 	return numberText.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
// };

/**
 * Formats a number with specified decimal and section delimiters.
 *
 * @param {number|string} num - The number to format.
 * @param {number} [decimal=0] - The number of decimal places to include.
 * @param {number} [section=3] - The number of digits in each section.
 * @param {string} [sectionDelimiter=','] - The delimiter to use between sections.
 * @param {string} [decimalDelimiter='.'] - The delimiter to use for the decimal point.
 * @returns {string} The formatted number.
 */
export const formatNumber = (
	num: number | string,
	decimal = 0,
	section = 3,
	sectionDelimiter = ",",
	decimalDelimiter = "."
) => {
	const regex =
		"\\d(?=(\\d{" + section + "})+" + (decimal > 0 ? "\\D" : "$") + ")";
	if(typeof num === "string"){
		num = Number.parseFloat(num)
	}
	if(num>0 && num % 1 !== 0){
		if(num.toString().split(".")[1].length > decimal){
			num = Number.parseFloat(num+"1")
		}
	}
	let numberText =
		typeof num === "string"
			? Number.parseFloat(num).toFixed(Math.max(0, ~~decimal))
			: num.toFixed(Math.max(0, ~~decimal));

	return (
		decimalDelimiter ? numberText.replace(".", decimalDelimiter) : numberText
	).replace(new RegExp(regex, "g"), "$&" + (sectionDelimiter || ","));
};

/**
 * Format number input.
 *
 * @param {string} value - The input value to format.
 * @param {number} length - The maximum length of the formatted value.
 * @param {boolean} excludeZero - Whether to exclude zero from the formatted value.
 * @param {boolean} isNegative - Whether negative values are allowed.
 * @returns {string} - The formatted value.
 */
export const formatNumberInput = (
	value: string,
	length: number,
	excludeZero: boolean,
	isNegative: boolean
): string => {
	if (value.length > length) {
		value = value.slice(0, length);
		return value;
	}

	if (isNaN(filterFloat(value))) {
		if (value === "") {
			return "";
		}
		if (value === "-" && isNegative) {
			return "";
		}
		if (
			value.substring(value.length - 1) === "." &&
			value.split(".").length - 1 < 2
		) {
			//last char is '.' and '.' occurance less than 2
			return "";
		}

		if (value.substring(value.length - 2, 1) === ".") {
			//last 2 char is '.' and Nan
			return value.substring(0, value.length - 1);
		}

		//remove last char and test again, may cause problem in Japanese input ???
		const newInput = value.substring(0, value.length - 1);
		if (isNaN(filterFloat(newInput))) {
			return "";
		} else {
			return newInput;
		}
	} else {
		let numberValue = parseFloat(value);

		if (numberValue < 0 && !isNegative) {
			return "";
		}

		if (excludeZero && numberValue === 0) {
			return "";
		}
	}

	return value;
};

/**
 * Imports all images from a given Webpack require context
 * @param {__WebpackModuleApi.RequireContext} r - The Webpack require context to import images from
 * @returns {Object} - An object containing all imported images, where the keys are the image filenames and the values are the imported images
 */
export const importAllImages = (r: __WebpackModuleApi.RequireContext) => {
	let images: { [x: string]: any } = {};
	r.keys().map((item) => {
		images[item.replace("./", "")] = r(item);
	});
	return images;
};
/**
 * Converts a payload object to a query string.
 *
 * @param {Record<string, any>} payload - The payload object to convert.
 * @returns {string} - The query string representation of the payload object.
 */
export const convertPayloadToQueryString = (payload: Record<string, any>) => {
	const keyValuePairs = [];
	for (const key in payload) {
		if (payload.hasOwnProperty(key)) {
			keyValuePairs.push(
				encodeURIComponent(key) + "=" + encodeURIComponent(payload[key])
			);
		}
	}
	return keyValuePairs.join("&");
};
