import { FX_TRADE_CURRENCY_TYPE } from "../../../constants/fx.constant";

/**
 * Swap trade currency type
 * @param {FX_TRADE_CURRENCY_TYPE} type - Trade currency type.
 * @return {FX_TRADE_CURRENCY_TYPE} Swapped trade currency type.
 */
const swapCurrencyType = (
	type: FX_TRADE_CURRENCY_TYPE
): FX_TRADE_CURRENCY_TYPE =>
	type === FX_TRADE_CURRENCY_TYPE.SOURCE
		? FX_TRADE_CURRENCY_TYPE.TARGET
		: FX_TRADE_CURRENCY_TYPE.SOURCE;

export default swapCurrencyType;
