import {
	BankAccountAPIResponseType,
	UserBankAccountType,
} from "../../../../../types/user.type";

/**
 * Format bank account API response
 * @param {Array<BankAccountAPIResponseType>} response - Bank account API response.
 * @return {Array<UserBankAccountType>} The array of formatted bank account object.
 */
const formatBankAccount = (
	response: BankAccountAPIResponseType[]
): UserBankAccountType[] =>
	response.map((v) => ({
		key: v.key,
		id: v.AccountId,
		type: v.AccountType,
		subtype: v.AccountSubType,
		name: v.Name,
		label: v.Label,
		currency: v.Currency,
		productType: v.ProductType,
		balance: v.Value,
		balanceText: v.Text,
		status: v.Status,
	}));

export default formatBankAccount;
