export const LATEST_FX_NEWS_AMOUNT = 5;

export const DEFAULT_NEWS_PER_PAGE = 10;

export const DEFAULT_NEWS_UPDATE_INTERVAL = 15000;

export const DEFAULT_NEWS_DATE_FORMAT = "DD MMMM YYYY";
export const DEFAULT_NEWS_TIME_FORMAT = "HH:mm a";

export const NEWS_SEARCH_MODE_TRIGGER_TEXT_LENGTH = 3;

export enum NEWS_VIRTUALIZED_LIST_ITEM_TYPE {
	ITEM,
	LABEL,
}

export const GROUPED_NEWS_SECTION_HEADER_TEXT_MAP = {
	today: "app.page.news.section.today.title",
	thirtyDays: "app.page.news.section.thirtyDays.title",
};

export const MOCK_FX_NEWS = [
	{
		id: 1,
		headline: "Euro Dips Even as Germany Says All Options on Table Over FX",
		datetime: "20230624000000",
	},
	{
		id: 2,
		headline: "Today News",
		datetime: "20230624000000",
	},
	{
		id: 3,
		headline: "Euro Dips Even as Germany Says All Options on Table Over FX",
		datetime: "20230624000000",
	},
	{
		id: 4,
		headline: "Euro Dips Even as Germany Says All Options on Table Over FX",
		datetime: "20230624000000",
	},
	{
		id: 5,
		headline: "Euro Dips Even as Germany Says All Options on Table Over FX",
		datetime: "20230624000000",
	},
	{
		id: 6,
		headline: "Euro Dips Even as Germany Says All Options on Table Over FX",
		datetime: "20230624000000",
	},
	{
		id: 7,
		headline: "Euro Dips Even as Germany Says All Options on Table Over FX",
		datetime: "20230624000000",
	},
	{
		id: 8,
		headline: "Euro Dips Even as Germany Says All Options on Table Over FX",
		datetime: "20230624000000",
	},
	{
		id: 9,
		headline: "Euro Dips Even as Germany Says All Options on Table Over FX",
		datetime: "20230624000000",
	},
	{
		id: 10,
		headline: "Euro Dips Even as Germany Says All Options on Table Over FX",
		datetime: "20230624000000",
	},
];
