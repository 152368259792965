
import { useState } from "react";
import {useIntl} from "react-intl";
import styled from "styled-components";
import Card from "../styled/Card";
import List from "../styled/List";
import Item, {StyledItem} from "../styled/items/Item";
import ItemStartContainer from "../styled/items/ItemStartContainer";
import ItemStartContent from "../styled/items/ItemStartContent";
import ItemEndContainer from "../styled/items/ItemEndContainer";
import AlertDialog from "../common/dialog/AlertDialog";
import {FXOrderFormFeeType, FXType} from "../../types/fx.type";
import {
    DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT,
    FX_TRADE_CURRENCY_TYPE,
} from "../../constants/fx.constant";
import {formatNumber} from "../../utils/common.utils";
import {getRateByDealt} from "../../formatters/fx";
import {getConversionFee} from "../../formatters/fx/order";
import {ReactComponent as InformationIcon} from "../../assets/icons/information.svg";
import {roundNumber} from "./FutureOrderInput/FutureInputField/services/roundNumber";
import {getDpUqBySymbol} from "../fx/service/getDpUqBySymbol";
import {isNumber} from "./FutureOrderInput/FutureInputField/services/isNumber";

interface CalculationCardProps {
    source: {
        currency: string;
        amount: number;
    };
    target: {
        currency?: string;
        amount: number;
    };
    selectingCurrencyType: FX_TRADE_CURRENCY_TYPE;
    fxObject: FXType;
    fee: FXOrderFormFeeType;
}

const StyledCalculationCard = styled(Card)`
    margin-bottom: 24px;
`;

const CalculationCardList = styled(List)`
    ${StyledItem} {
        font-size: 12px;

        &:not(:first-child) {
            margin-top: 8px;
        }

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
`;
/**
 * Renders the exchange rate between two currencies.
 *
 * @param {string} currency1 - The first currency code.
 * @param {string} currency2 - The second currency code.
 * @param {string | number | null} rate - The exchange rate between the currencies.
 * @param dp -The decimal place
 * @param unitQuote
 * @param isWatchList
 * @param isFutureInput
 * @returns {string} - The rendered exchange rate.
 */
export const renderRate = (currency1: string,
                           currency2: string,
                           rate: string | number | null,
                           dp: number,
                           unitQuote: number,
                           isWatchList?: boolean,
                           isFutureInput?: boolean,
) => {
    if(!(unitQuote && dp)){
        // console.error("uq or unitQuote is lacking!!!")
    }
    if(!isNumber(dp) || !isNumber(unitQuote)){ //this means do not format
        if(isWatchList){
            return rate as string
        }else if(isFutureInput){
            return `${currency1} 1 = ${currency2} `;
        }
        else{
            return `${currency1} 1 = ${currency2} ${rate as string}`;
        }
    }
	if(unitQuote===0){ //this is to save CPU power
		if(isWatchList){
			return Number(rate as string).toFixed(dp)
		}else if(isFutureInput){
            return `${currency1} 1 = ${currency2}`;
        } else{
			return `${currency1} 1 = ${currency2} ${ Number(rate as string).toFixed(dp)}`;
		}
	}
    const powerTo = 10 ** unitQuote
    const decimalPlacesToDisplay: number = dp - unitQuote
    if (isWatchList) {
        return `${roundNumber((Number(rate)) * powerTo, 10, 'off').toFixed(decimalPlacesToDisplay)}`;
    } else if(isFutureInput){
        return `${currency1} ${roundNumber(powerTo, 10, 'off')} = ${currency2} `;
    } else {
        return `${currency1} ${roundNumber(powerTo, 10, 'off')} = ${currency2} ${roundNumber((Number(rate)) * powerTo, 10, 'off').toFixed(decimalPlacesToDisplay)}`;
    }
}

const CalculationCard = ({
                             source,
                             target,
                             selectingCurrencyType,
                             fxObject,
                             fee,
                         }: CalculationCardProps) => {
    const intl = useIntl();
    const [isRateAlertDialogOpen, setIsRateAlertDialogOpen] =
        useState<boolean>(false);

    const handleRateAlertDialogClose = () => {
        setIsRateAlertDialogOpen(false);
    };

    const handleRateAlertDialogConfirm = () => {
        setIsRateAlertDialogOpen(false);
    };

    return (
        <StyledCalculationCard>
            <CalculationCardList>
                <Item>
                    <ItemStartContainer>
                        <ItemStartContent>
                            {intl.formatMessage({
                                id: "app.page.orderForm.card.calculation.item.rate.label",
                            })}
                        </ItemStartContent>
                        <InformationIcon
                            className="mbb-icon"
                            onClick={() => setIsRateAlertDialogOpen(true)}
                        />
                    </ItemStartContainer>
                    {
                        // fxOrderForm.future &&
                        <ItemEndContainer>
                            {
                                renderRate(
                                    fxObject.currencies[0],
                                    fxObject.currencies[1],
                                    getRateByDealt(source.currency, fxObject),
                                    getDpUqBySymbol(fxObject.symbol).dp,
                                    getDpUqBySymbol(fxObject.symbol).uq,
                                )
                            }
                        </ItemEndContainer>
                    }

                </Item>
                <Item>
                    <ItemStartContainer>
                        {intl.formatMessage({
                            id: "app.page.orderForm.card.calculation.item.conversionFee.label",
                        })}
                    </ItemStartContainer>
                    <ItemEndContainer>
                        {fee.currency}{" "}
                        {
                            formatNumber(
                                getConversionFee(),
                                DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
                            ) /*fee.conversion*/
                        }
                    </ItemEndContainer>
                </Item>
                <Item>
                    <ItemStartContainer>
                        {intl.formatMessage({
                            id: "app.page.orderForm.card.calculation.item.total.label",
                        })}
                    </ItemStartContainer>
                    <ItemEndContainer>
                        {source.currency}{" "}
                        {formatNumber(
                            source.amount + Number(getConversionFee()), // fee.conversion
                            DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT //todo check if this is correct... I think this should be using the decimal returned by API
                        )}
                    </ItemEndContainer>
                </Item>
            </CalculationCardList>
            <AlertDialog
                titleComp={intl.formatMessage({
                    id: "app.page.orderForm.dialog.rate.title",
                })}
                contentComp={intl.formatMessage({
                    id: "app.page.orderForm.dialog.rate.content",
                })}
                confirmButtonText={intl.formatMessage({
                    id: "app.page.orderForm.dialog.rate.button.ok",
                })}
                open={isRateAlertDialogOpen}
                onClose={handleRateAlertDialogClose}
                onConfirm={handleRateAlertDialogConfirm}
            />
        </StyledCalculationCard>
    );
};

export default CalculationCard;
