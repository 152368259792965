/**
 * Formats an account number by dividing it into chunks of four digits and adding spaces between the chunks.
 *
 * @param {string | number | bigint} accountNumber - The account number to be formatted. It can be a string, number, or bigint.
 * @return {string} - The formatted account number.
 */
export function formatAccountNumber(accountNumber: string | number | bigint): string {
    // Convert accountNumber to a string, using BigInt for large numbers to avoid scientific notation
    const accountStr = typeof accountNumber === 'number' && !Number.isSafeInteger(accountNumber)
        ? BigInt(accountNumber).toString()
        : accountNumber.toString();

    let formatted = '';
    for (let i = 0; i < accountStr.length; i += 4) {
        if (i > 0) {
            formatted += ' '; // Add space before next chunk
        }
        formatted += accountStr.slice(i, i + 4);
    }
    return formatted;
}

//#case 13284483

// Example usage
// const accountNumber1 = 1234567; // number type within safe integer range
// const accountNumber2 = '123456789'; // string type
// const accountNumber3 = '1234567890123';
// const accountNumberOther = 12345612321321321321312321321321; // large number, using BigInt
//
// console.log(formatAccountNumber(accountNumber1)); // 1234 567
// console.log(formatAccountNumber(accountNumber2)); // 1234 5678 9
// console.log(formatAccountNumber(accountNumber3)); // 1234 5678 9012 3
// console.log(formatAccountNumber(accountNumberOther)); // 1234 5612 3213 2132 1321 3123 2132 1321
