import {
	CircularProgress,
	circularProgressClasses,
	CircularProgressProps,
} from "@mui/material";
import styled from "styled-components";

interface LoadingSpinnerProps extends CircularProgressProps {
	display?: "block" | "inline";
	$centered?: boolean;
}

const LoadingSpinner = styled(CircularProgress)<LoadingSpinnerProps>`
	&.${circularProgressClasses.root} {
		${({ display }) => display === "block" && `display: block;`}
		${(props) => props?.$centered && `margin-left: auto; margin-right: auto;`}
        margin-top: 8px;
		margin-bottom: 8px;
		color: var(--theme-color-primary);
	}
`;

export default LoadingSpinner;
