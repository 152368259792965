import React, {createContext, forwardRef, useEffect, useState} from 'react';
import {useIntl} from "react-intl";
import {OrderStatusEnum} from "../enums/OrderStatusEnum";
import {OrderStatusInfoReal} from "../interface/OrderStatusInfo";

import styled from "styled-components";
import {Dialog, dialogContentClasses, dialogTitleClasses} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {PageAndTypeSelector} from "../../container/PageAndTypeSelector";
import {filterAndSortOrderStatusData} from "../services/filterAndSortOrderStatusData";
import {AxiosResponse} from "axios";
import fxAPI, {ListOrderAPIStateEnum} from "../../../api/fx.api";
import {GetOrderStatusListResponseType} from "../../../types/fx.type";
import {useAppDispatch, useAppSelector} from "../../../utils/store.utils";
import {
    selectAppBackFromPath,
    selectIsAfterPressingCancelOrder,
    updateIsAfterPressingCancelOrder
} from "../../../slice/app";
import {selectFxOrderStatusList, updateOrderStatusList} from "../../../slice/fx";
import {GET_ORDER_STATUS_LIST_INVALID} from "../../../constants/errorMsg.constant";
import {ErrorObjForToastBar} from "../../../types/errorObjType.type";
import {processApiError} from "../../../utils/errorHandling.utils";
import {emitErrorToApp} from "../../../utils/integration.utils";
import {ListRateAlertStateEnum} from "../../rateAlert/enums/RateAlertTabEnum";
import {store} from "../../../store/store";

type RefreshOrderStatusContextType = (state: ListOrderAPIStateEnum) => Promise<void>;
export const RefreshOrderStatusContext = createContext<RefreshOrderStatusContextType>(() => Promise.resolve());

export function OrderStatus() {
    const intl = useIntl();
    const [orderStatusData, setOrderStatusData] = useState<OrderStatusInfoReal[] | undefined>(undefined)
    const backFromPath: string = useAppSelector(selectAppBackFromPath)
    const dispatch = useAppDispatch()
    const orderStatusListRedux = useAppSelector(selectFxOrderStatusList)
    const isAfterPressingCancelOrder = useAppSelector(selectIsAfterPressingCancelOrder)

    //Call API at the start of loading the page
    useEffect(() => {
        if (backFromPath.includes("/order/details")) {
            if (isAfterPressingCancelOrder) {
                dispatch(updateIsAfterPressingCancelOrder(false))
                getOrderStatus(ListOrderAPIStateEnum.Cancelled) //todo this need to changed
                return
            }
            setOrderStatusData(orderStatusListRedux)
            return
        } else {
            getOrderStatus(ListOrderAPIStateEnum.All)
        }
    }, [])

    const getOrderStatus = async (state: ListOrderAPIStateEnum) => {
        const maxRetries = store.getState().app.fo_retry + 1
        let attempt = 0;
        while (attempt < maxRetries) {
            try {
                resetOrderStatus()
                const response: AxiosResponse<GetOrderStatusListResponseType> = await fxAPI.getOrderStatusList(state)
                if (
                    !response.data ||
                    response.data.responseCode !== "0" ||
                    !response.data.data
                ) throw {
                    errorMsg: GET_ORDER_STATUS_LIST_INVALID,
                    responseHttpStatus: response.status,
                    responseData: response.data
                } as ErrorObjForToastBar
                const orderStatusList = response.data.data
                const orderStatusListFiltered = orderListPreventCrashFilter(orderStatusList)
                setOrderStatusData(orderStatusListFiltered)
                dispatch(updateOrderStatusList(orderStatusListFiltered))
                // setOrderStatusData(orderListPreventCrashFilter(testObject))
                // dispatch(updateOrderStatusList(orderListPreventCrashFilter(testObject)))
                return
            } catch (error) {
                attempt++;
                if (attempt >= maxRetries) {
                    processApiError(error, emitErrorToApp, undefined, true) //this is fatal
                }
            }
        }
    }

    const resetOrderStatus = () => {
        setOrderStatusData(undefined)
    }

    return (
        //what is the user of this refreshOrderStatus??? I forgot, so I just comment out now
        <RefreshOrderStatusContext.Provider value={getOrderStatus}>
            <PageAndTypeSelector
                title={intl.formatMessage({id: "app.page.orderStatus.header.title"})}
                data={orderStatusData}
                defaultSelectedType={OrderStatusEnum.ALL}
                types={Object.values(OrderStatusEnum)}
                triggerFetch={getOrderStatus as (state: ListOrderAPIStateEnum | ListRateAlertStateEnum) => void}
                filterFunc={filterAndSortOrderStatusData}
            />
            // </RefreshOrderStatusContext.Provider>
    );
}

const OrderDetailDialog = styled(Dialog)`
    .${dialogTitleClasses.root} {
        display: flex;
        justify-content: space-between;
        min-height: calc(44px + constant(safe-area-inset-top));
        min-height: calc(44px + env(safe-area-inset-top));
        padding-top: calc(18px + constant(safe-area-inset-top));
        padding-top: calc(18px + env(safe-area-inset-top));
        padding-right: 24px;
        padding-bottom: 18px;
        padding-left: 24px;
        font-family: inherit;
        font-size: 16px;
        letter-spacing: normal;

        &.close-only {
            justify-content: flex-end;
        }
    }

    .${dialogContentClasses.root} {
        overflow-y: hidden;
    }
`;

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const orderListPreventCrashFilter = (orders:OrderStatusInfoReal[])=>{
    return orders.filter(order => order.symbol !== null && order.symbol !== undefined && order.symbol.trim() !== "");
}

// const testObject:any = [
//     {
//         "symbol": "CADSGD",
//         "orderType": 2,
//         "country": "MY",
//         "cancelDate": null,
//         "orderId": "5.1.13212",
//         "purpose": "RFX",
//         "dealtCurr": "CAD",
//         "forwardMarkup": null,
//         "buy": true,
//         "orderStatus": "CANCELLED",
//         "dp": 4,
//         "takeProfit": 1,
//         "orderState": 5,
//         "toAccName": "Global Access Account-i (JPY)",
//         "tenor": "SPOT",
//         "quote": null,
//         "rate": null,
//         "contraAmount": "1.00",
//         "spotRate": null,
//         "limit": "1",
//         "spotDps": null,
//         "toAccNo": "900000000025",
//         "expireDate": "20240930155959",
//         "uq": 0,
//         "amount": "1.00",
//         "digitalId": "STAFF",
//         "tradeDate": null,
//         "valueDate": "20240919",
//         "dealType": "ISLAMIC",
//         "inputDate": null,
//         "forwardPort": null,
//         "bnmPurPose": "TF004-TRANSFER TRANSFER",
//         "cancelUser": "stream3LOApp01@LiveFX",
//         "fromAccNo": "900000000025",
//         "spoMarkup": null,
//         "orderTag": "A202409090428480L0",
//         "cust_tier": "RetailFX_Affulent",
//         "account": "RetailFX_ACC",
//         "fromAccName": "Global Access Account-i (MYR)"
//     },
//     {
//         "symbol": "CADSGD",
//         "orderType": 2,
//         "country": "MY",
//         "cancelDate": null,
//         "orderId": "5.1.13210",
//         "purpose": "RFX",
//         "dealtCurr": "CAD",
//         "forwardMarkup": null,
//         "buy": true,
//         "orderStatus": "COMPLETED",
//         "dp": null,
//         "takeProfit": 1,
//         "orderState": 2,
//         "toAccName": "Global Access Account-i (JPY)",
//         "tenor": "SPOT",
//         "quote": null,
//         "rate": null,
//         "contraAmount": "1.00",
//         "spotRate": null,
//         "limit": "1",
//         "spotDps": null,
//         "toAccNo": "900000000025",
//         "expireDate": null,
//         "uq": 0,
//         "amount": "1.00",
//         "digitalId": "STAFF",
//         "tradeDate": null,
//         "valueDate": "20240919",
//         "dealType": "ISLAMIC",
//         "inputDate": null,
//         "forwardPort": null,
//         "bnmPurPose": "TF004-TRANSFER TRANSFER",
//         "cancelUser": null,
//         "fromAccNo": "900000000025",
//         "spoMarkup": null,
//         "orderTag": "A202409090343250L0",
//         "cust_tier": "RetailFX_Affulent",
//         "account": "RetailFX_ACC",
//         "fromAccName": "Global Access Account-i (MYR)"
//     },
//     {
//         "symbol": "USDMYR",
//         "orderType": 2,
//         "country": "MY",
//         "cancelDate": null,
//         "orderId": "5.1.13056",
//         "purpose": "RFX",
//         "dealtCurr": "MYR",
//         "forwardMarkup": null,
//         "buy": false,
//         "orderStatus": "PENDING",
//         "dp": 4,
//         "takeProfit": 1,
//         "orderState": 2,
//         "toAccName": "Global Access Account-i (USD)",
//         "tenor": "SPOT",
//         "quote": null,
//         "rate": null,
//         "contraAmount": "250.00",
//         "spotRate": null,
//         "limit": "4",
//         "spotDps": null,
//         "toAccNo": "900000000025",
//         "expireDate": null,
//         "uq": 0,
//         "amount": "999.99",
//         "digitalId": "STAFF",
//         "tradeDate": null,
//         "valueDate": "20240919",
//         "dealType": "ISLAMIC",
//         "inputDate": "20240903100133",
//         "forwardPort": null,
//         "bnmPurPose": "TF004-TRANSFER TRANSFER",
//         "cancelUser": null,
//         "fromAccNo": "900000000025",
//         "spoMarkup": null,
//         "orderTag": "A202409031001330L0",
//         "cust_tier": "RetailFX_Affulent",
//         "account": "RetailFX_ACC",
//         "fromAccName": "Global Access Account-i (MYR)"
//     }
// ]

export default OrderStatus;
