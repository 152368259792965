import {isSameDate} from "./services/isSameDate";
import "./DateRange.css"
import {isWithinExpiryRange} from "./services/isWithinExpiryRange";

/**
 * Renders a date range component.
 *
 * @param {Object} props - The props for the component.
 * @param {Date[]} props.dayRange - An array of dates to be displayed.
 * @param {Date | null} props.selectedDate - The currently selected date.
 * @param {function} props.handleSelectedDate - A callback function for handling the selection of a date.
 * @param {Date} props.currentDate - The current date.
 *
 * @return {JSX.Element} - The rendered date range component.
 */
export function DateRange(props: {
    dayRange: Date[],
    selectedDate: Date | null,
    handleSelectedDate: (dateObj: Date) => void,
    currentDate: Date
    isRateAlert?:boolean
}) {
    return (
        <div className="date-container">
            <div className="weekday-name">M</div>
            <div className="weekday-name">T</div>
            <div className="weekday-name">W</div>
            <div className="weekday-name">T</div>
            <div className="weekday-name">F</div>
            <div className="weekday-name">S</div>
            <div className="weekday-name">S</div>
            {props.dayRange.map((item, index) => {
                const isSelectedDate = props.selectedDate && isSameDate(item, props.selectedDate);
                return (
                    <div
                        className={`date-num`}
                        key={index}
                        onClick={() => {
                            if (isWithinExpiryRange(item, props.currentDate, props.isRateAlert)) {
                                props.handleSelectedDate(item);
                            }
                        }}
                        id={`date-${item.getDate()}`} //this is not good at there would be duplicate date, but for the purpose I testing, I don't care about it first
                    >
                        <div
                            className={`${isSelectedDate ? 'selectedDate' : ''} 
                            ${isWithinExpiryRange(item, props.currentDate, props.isRateAlert) ? "" : "outOfRange"}`}
                        >
                            <span>{item.getDate()}</span>
                        </div>
                    </div>
                )
            })
            }
        </div>
    )
}
