import { PathMatch } from "react-router-dom";
import { APP_NAVIGATE_DIRECTION } from "../constants/app.constant";

export interface AppHistoryType {
	path: string;
}

export interface AppType {
	title: string;
	navigateDirection: APP_NAVIGATE_DIRECTION;
	history: AppHistoryType[];
	isRootScrollable: boolean;
	debugMode: boolean;
	backFromPath: AppHistoryType;
	isWentBackAndToggledFalse: isWentBackAndToggledFalseEnum;
	latestErrorMsg: string;
	triggerCloseApp: string;
	isAfterPressingCancelOrder: boolean;
	// New parameters added
	ra_max_active_count: number; // Maximum Number of Active Rate Alerts
	ra_max_expiry_date: number; // Maximum Number of Days of Validity of Rate Alerts
	ra_retry: number;
	ra_default_plus_n_date: number; // Default plus n date for rate alert
	ra_calendar_max_previous_month: number; // Maximum number of previous month calendar for rate alert
	ra_calendar_max_future_month: number; // Maximum number of future month calendar for rate alert
	fo_max_active_count: number; // Maximum Number of Active Future Orders (not yet implemented)
	fo_max_expiry_date: number; // Maximum Number of Days of Validity of Future Orders
	fo_retry:number;
	fo_default_plus_n_date: number; // Default plus n date for future order
	fo_calendar_max_previous_month: number; // Maximum number of previous month calendar for future order
	fo_calendar_max_future_month: number; // Maximum number of future month calendar for future order
	wl_update_interval: number; // Watchlist update interval (3 seconds now)
	ui_post_msg_wait_time: number; // post_message waiting MBB's app response time (120 seconds)
	news_update_interval: number; // news update interval (15 seconds now)
	fo_profit_limitation: number; // FO's 10% profit limitation on future rate input box
}

export enum isWentBackAndToggledFalseEnum {
	FIRST_OPEN_PAGE = "firstOpenPage",
	WENT_BACK_AND_TOGGLE_FALSE = "wentBackAndToggleFalse",
	MAY_GO_BACK_AGAIN_BUT_HAVE_NOT_TOGGLE = "mayGoBackAgainButHaveNotToggle"
}

export interface AppHeaderEndButtonDisplayPathType {
	menu: Array<PathMatch<string> | null>;
	close: Array<PathMatch<string> | null>;
}
