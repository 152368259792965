import { AxiosResponse } from "axios";
import {
	GetWatchlistAPIResponseType,
	UpdateWatchlistAPIRequestType,
	UpdateWatchlistAPIResponseType,
} from "../types/watchlist.type";
import { api } from "../utils/api.utils";

/**
 * Represents the type definition for the WatchlistAPIType object.
 *
 * @typedef {Object} WatchlistAPIType
 * @property {function} get - A function that retrieves the watchlist data.
 * @property {function} update - A function that updates the watchlist data.
 */
type WatchlistAPIType = {
	get: () => Promise<AxiosResponse<GetWatchlistAPIResponseType>>;
	update: (
		payload: UpdateWatchlistAPIRequestType
	) => Promise<AxiosResponse<UpdateWatchlistAPIResponseType>>;
};

/**
 * Represents an API for managing watchlist.
 *
 * @typedef {Object} WatchlistAPIType
 * @property {Function} get - Retrieves the watchlist data.
 * @property {Function} update - Updates the watchlist data with the given payload.
 */
const watchlistAPI: WatchlistAPIType = {
	get: () => {

		return api.get("/watchlist?rate=1");
	},
	update: (payload) => {
		return api.post("/watchlist", payload);
	},
};

export default watchlistAPI;
