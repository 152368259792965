import * as React from 'react';
import {PageAndTypeSelector} from "../container/PageAndTypeSelector";
import {useIntl} from "react-intl";
import {createContext, useEffect, useState} from "react";
import {ListRateAlertStateEnum, RateAlertTabEnum} from "./enums/RateAlertTabEnum";
import {filterAndSortReteAlertData} from "./services/filterAndSortRateAlertData";
import {RateAlertInfoReal} from "./interface/RateAlertInfo";
import {AxiosResponse} from "axios";
import {GetRateAlertListResponseType} from "../../types/fx.type";
import fxAPI, {ListOrderAPIStateEnum} from "../../api/fx.api";
import {useAppDispatch, useAppSelector} from "../../utils/store.utils";
import {selectAppBackFromPath, selectAppHistory} from "../../slice/app";
import {selectFxRateAlertList, updateRateAlertList} from "../../slice/fx";
import {countPendingAlerts} from "./services/countActiveRateAlert";
import {GET_ORDER_STATUS_LIST_INVALID} from "../../constants/errorMsg.constant";
import {ErrorObjForToastBar} from "../../types/errorObjType.type";
import {processApiError} from "../../utils/errorHandling.utils";
import {emitErrorToApp} from "../../utils/integration.utils";
import {AFTER_CREATE_RATE_ALERT_WAIT_RELOAD_SECOND_TIME} from "../../constants/fx.constant";
import {store} from "../../store/store";

type Props = {};

type RefreshRateAlertContextType = (state: ListRateAlertStateEnum) => Promise<void>;

export const RefreshRateAlertContext = createContext<RefreshRateAlertContextType>(() => Promise.resolve());
export const RateAlert = (props: Props) => {
    const intl = useIntl();
    const [rateAlertData, setRateAlertData] = useState<RateAlertInfoReal[] | undefined>(undefined) //todo set the interface
    const backFromPath: string = useAppSelector(selectAppBackFromPath)
    const dispatch = useAppDispatch()
    const rateAlertListRedux = useAppSelector(selectFxRateAlertList)
    const history = useAppSelector(selectAppHistory)

    //Call API at the start of loading the page
    useEffect(() => {
        const previousPage = history[history.length - 2]?.path ?? ""; // Using optional chaining and nullish coalescing
        const isFromStep3 = previousPage.toLowerCase().includes("rateAlert/create/step3".toLowerCase());
        if (backFromPath.includes("/rateAlert/create/step1")) {
            setRateAlertData(rateAlertListRedux)
            return
        } else {
            getRateAlertList(ListRateAlertStateEnum.active)
            if(isFromStep3){
                setTimeout(()=>{
                    getRateAlertList(ListRateAlertStateEnum.active, true)
                }, AFTER_CREATE_RATE_ALERT_WAIT_RELOAD_SECOND_TIME)
            }
        }
    }, [])

    const getRateAlertList = async (state:ListRateAlertStateEnum, noReset?:boolean) => {
        if(!noReset) {
            setRateAlertData(undefined)
        }

        const maxRetries = store.getState().app.ra_retry + 1
        let attempt = 0;

        while (attempt < maxRetries) {
            try {
                const response: AxiosResponse<GetRateAlertListResponseType> = await fxAPI.getRateAlertList(state)
                if (
                    !response.data ||
                    response.data.responseCode !== "0" ||
                    !response.data.data
                ) throw {
                    errorMsg: GET_ORDER_STATUS_LIST_INVALID,
                    responseHttpStatus: response.status,
                    responseData: response.data
                } as ErrorObjForToastBar
                const rateAlertList = response.data.data
                setRateAlertData(rateAlertList)
                dispatch(updateRateAlertList(rateAlertList))
                return
            } catch (error) {
                attempt++;
                if (attempt >= maxRetries) {
                    processApiError(error, emitErrorToApp, undefined, true) //this is fatal
                    return
                }
            }
        }
    }

    return (
        <RefreshRateAlertContext.Provider value={getRateAlertList}>
            <PageAndTypeSelector
                title={intl.formatMessage({id: "app.page.rateAlert.header.title"})}
                data={rateAlertData}
                defaultSelectedType={RateAlertTabEnum.ACTIVE}
                types={Object.values(RateAlertTabEnum)}
                triggerFetch={getRateAlertList as (state: ListOrderAPIStateEnum | ListRateAlertStateEnum) => void}
                filterFunc={filterAndSortReteAlertData}
            />
        </RefreshRateAlertContext.Provider>
    );
};
