import {useIntl} from "react-intl";
import styled from "styled-components";

import List from "../../styled/List";
import Item, {StyledItem} from "../../styled/items/Item";
import ItemEndContainer from "../../styled/items/ItemEndContainer";
import ItemStartContainer from "../../styled/items/ItemStartContainer";
import ItemStartContent from "../../styled/items/ItemStartContent";

import {
    FXOrderFormBankAccountType,
    FXOrderFormFeeType,
    FXType,
} from "../../../types/fx.type";
import {getConversionFee} from "../../../formatters/fx/order";
import {getRateByDealt} from "../../../formatters/fx";
import {formatNumber} from "../../../utils/common.utils";
import {DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT} from "../../../constants/fx.constant";
import Anchor from "../../styled/Anchor";
import {useAppSelector} from "../../../utils/store.utils";
import {selectFXOrderForm, selectFXUnitQuote} from "../../../slice/fx";
import {renderRate} from "../CalculationCard";
import {DetailedHTMLProps, HTMLAttributes} from "react";
import {getDpUqBySymbol} from "../../fx/service/getDpUqBySymbol";
import {TextWrapper} from "../../styled/TextWrapper";
import {SHOW_MYT_TIMEZONE_SYMBOL} from "../../../constants/app.constant";
import {showMYTText} from "../../../pages/order/services/showMYTText";

interface OrderDataListProps {
    source: {
        currency: string;
        amount: number;
    };
    target: {
        currency: string;
        amount: number;
    };
    accounts: FXOrderFormBankAccountType;
    fxObject: FXType;
    fee: FXOrderFormFeeType;
    onAccountClick: () => void;

}

/**
 * Renders the order data list component.
 *
 * @param {OrderDataListProps} props - The props object containing the necessary data for rendering the component.
 * @returns {JSX.Element} The order data list component.
 */
const OrderDataList = ({
                           source,
                           target,
                           accounts,
                           fxObject,
                           fee,
                           onAccountClick,
                       }: OrderDataListProps): JSX.Element => {
    const intl = useIntl();
    const fxOrderForm = useAppSelector(selectFXOrderForm);
    return (
        <List>
            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING}>
                <ItemStartContainer>
                    <ItemStartContent>
                        {intl.formatMessage({
                            id: "app.page.orderConfirmation.list.orderData.item.transferFrom",
                        })}
                    </ItemStartContent>
                </ItemStartContainer>
                <ItemEndContainer>
                    <Anchor onClick={onAccountClick}>{accounts.source?.label}</Anchor>
                </ItemEndContainer>
            </OrderDataListItem>
            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING}>
                <ItemStartContainer>
                    <ItemStartContent>
                        {intl.formatMessage({
                            id: "app.page.orderConfirmation.list.orderData.item.transferTo",
                        })}
                    </ItemStartContent>
                </ItemStartContainer>
                <ItemEndContainer>
                    <Anchor onClick={onAccountClick}>{accounts.target?.label}</Anchor>
                </ItemEndContainer>
            </OrderDataListItem>
            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING}>
                <ItemStartContainer>
                    <ItemStartContent>
                        {intl.formatMessage({
                            id: "app.page.orderConfirmation.list.orderData.item.receiveAmount",
                        })}
                    </ItemStartContent>
                </ItemStartContainer>
                <ItemEndContainer>
                    {target.currency}{" "}
                    {formatNumber(
                        target.amount,
                        DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
                    )}
                </ItemEndContainer>
            </OrderDataListItem>
            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING}>

                {
                    !fxOrderForm.future &&
                    <>
                        <ItemStartContainer>
                            <ItemStartContent>
                                {intl.formatMessage({
                                    id: "app.page.orderConfirmation.list.orderData.item.rate",
                                })}
                            </ItemStartContent>
                        </ItemStartContainer>
                        <ItemEndContainer>
                            {
                                getRateByDealt(source.currency, fxObject)
                                    ? renderRate(
                                        fxObject.currencies[0],
                                        fxObject.currencies[1],
                                        getRateByDealt(source.currency, fxObject),
                                        getDpUqBySymbol(fxObject.symbol).dp,
                                        getDpUqBySymbol(fxObject.symbol).uq,
                                    )
                                    : "-"
                            }
                        </ItemEndContainer>
                    </>
                }
                {
                    fxOrderForm.future &&
                    <>
                        <ItemStartContainer>
                            <ItemStartContent>
                                {intl.formatMessage({
                                    id: "app.page.orderConfirmation.list.orderData.item.targetRate",
                                })}
                            </ItemStartContent>
                        </ItemStartContainer>
                        <ItemEndContainer>
                            <ItemEndContainer>
                                {renderRate(
                                    fxObject.currencies[0],
                                    fxObject.currencies[1],
                                    fxOrderForm.futureTargetRate || getRateByDealt(source.currency, fxObject),
                                    getDpUqBySymbol(fxObject.symbol).dp,
                                    getDpUqBySymbol(fxObject.symbol).uq,
                                )}
                            </ItemEndContainer>

                        </ItemEndContainer>
                    </>
                }
            </OrderDataListItem>
            {
                fxOrderForm.future &&
                <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING}>
                    <ItemStartContainer>
                        <ItemStartContent>
                            {intl.formatMessage({
                                id: "app.page.orderForm.form.order.label.expiration",
                            })}
                        </ItemStartContent>
                    </ItemStartContainer>
                    <ItemEndContainer>
                        <TextWrapper pushToRight={true}>
                            <div>{formatDate(fxOrderForm.expiryDate as string)},</div>
                            <div>&nbsp;11:59:59 PM</div>
                            {SHOW_MYT_TIMEZONE_SYMBOL? `, ${showMYTText()}`: ""}
                        </TextWrapper>
                    </ItemEndContainer>
                </OrderDataListItem>
            }

            <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING}>
                <ItemStartContainer>
                    <ItemStartContent>
                        {intl.formatMessage({
                            id: "app.page.orderConfirmation.list.orderData.item.transactionType",
                        })}
                    </ItemStartContent>
                </ItemStartContainer>
                <ItemEndContainer>
                    {
                        fxOrderForm.future ? "Future Order" : "Currency Conversion"
                    }

                </ItemEndContainer>
            </OrderDataListItem>
            {
                fee &&
                <OrderDataListItem padding={ORDER_DATA_LIST_ITEM_PADDING}>
                    <ItemStartContainer>
                        <ItemStartContent>
                            {intl.formatMessage({
                                id: "app.page.orderConfirmation.list.orderData.item.conversionFee",
                            })}
                        </ItemStartContent>
                    </ItemStartContainer>
                    <ItemEndContainer>
                        {fee.currency}{" "}
                        {formatNumber(
                            getConversionFee(),
                            DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
                        )}
                    </ItemEndContainer>
                </OrderDataListItem>
            }
        </List>
    );
};

export default OrderDataList;

/**
 * Formats an ISO string into a localized date string.
 *
 * @param {string} isoString - The ISO string representation of the date.
 * @return {string} The formatted date string.
 */
export function formatDate(isoString: string): string {
    // Parse the ISO string into a Date object
    const date = new Date(isoString);

    // Format the date
    // Specify the locale ('en-GB' in this example) and options for formatting
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    };

    return new Intl.DateTimeFormat('en-GB', options).format(date);
}

export const ORDER_DATA_LIST_ITEM_PADDING = {
    left: "var(--common-layout-padding-x)",
    right: "var(--common-layout-padding-y)",
};

// export const OrderDataListItem = styled(StyledItem)`
//     ${ItemStartContainer} {
//         width: 130px;
//         min-width: 130px;
//         max-width: 130px;
//     }
//
//     ${ItemEndContainer} {
//         text-align: right;
//     }
//
//     padding-bottom: 20px;
// `;

interface OrderDataListItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    extraLargePadding?: boolean;
}

export const OrderDataListItem = styled(StyledItem)<OrderDataListItemProps>`
    ${ItemStartContainer} {
        width: 130px;
        min-width: 130px;
        max-width: 130px;
    }

    ${ItemEndContainer} {
        text-align: right;
    }

    padding-bottom: ${({extraLargePadding}) => extraLargePadding ? '8px' : '0'};
`;
