import axios, {Axios, AxiosError, AxiosInstance} from "axios";
import {
	getLocalStorage,
	getURLQueryValueByKey,
	setSessionStorage,
} from "./common.utils";
import {checkResponseCodeHasMDIP} from "./checkResponseCodeHasMDIP";
import {emitErrorToApp} from "./integration.utils";
import {MDIP_DISPLAY_MSG} from "../constants/errorMsg.constant";
import {manageApiErrorResponse} from "./errorHandling.utils";
import {ErrorObjForToastBar} from "../types/errorObjType.type";
import { store } from "src/store/store";
import { disableGetRate } from "src/slice/fx";

let token = getURLQueryValueByKey("token");



const api:AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		Authorization: `Bearer ${token}`,
		//token: token || "",
	},
});



const
	marketAxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_MARKET_API_URL,
});

const onRejected = (
	errors: AxiosError | string
): Promise<AxiosError | string> => {
	/*Sentry.setExtra("errors", errors);
	Sentry.captureException(errors);*/
	return Promise.reject(errors);
};


api.interceptors.response.use(
	// @ts-ignore
  (response) => {
    if (response.data?.status == -1) {
      return Promise.reject("API error status: -1"); 
    }
		if (checkResponseCodeHasMDIP(response.data?.responseCode)) {
			store.dispatch(disableGetRate())
      const convertedError: ErrorObjForToastBar = {
        errorMsg: MDIP_DISPLAY_MSG,
        responseHttpStatus: response!.status,
        responseData: response?.data ?? null
		};
				manageApiErrorResponse(convertedError, emitErrorToApp, MDIP_DISPLAY_MSG, true);
			
				// console.log("found mdip error...fatal error toastbar");
				return new Promise((resolve, reject) => {
          			// This promise will never resolve or reject
        		});										
			
       
    } else {
      // console.log("No MDIP error, proceed...");
      return Promise.resolve(response);  
    }
  }, 
	async (error) => {
		store.dispatch(disableGetRate())
	  // console.log("INTERCEPTED HTTP ERROR...");
    if (error.response && checkResponseCodeHasMDIP(error.response.data?.responseCode)) {
      
      const convertedError: ErrorObjForToastBar = {
        errorMsg: MDIP_DISPLAY_MSG,
        responseHttpStatus: error.response.status,
        responseData: error.response?.data ?? null
      };
      manageApiErrorResponse(convertedError, emitErrorToApp, MDIP_DISPLAY_MSG, true);
		// console.log("found mdip error in http error ...fatal error toastbar");
		return new Promise((resolve, reject) => {
          			// This promise will never resolve or reject
        		});		
    //   return Promise.reject("MDIP Error: No further action."); 
    } else {
      // console.log("Have http error but no MDIP error, proceed...");
      return Promise.reject(error);
    }
  }
);


// api.interceptors.response.use((response) => {
// 	if (response.data?.status == -1) {
// 		onRejected("API error status: -1");
// 	}
// 	return response;
// }, onRejected);


marketAxiosInstance.interceptors.response.use((response) => {
	if (response.data?.status == -1) {
		onRejected("API error status: -1");
	}
	return response;
}, onRejected);

export const updateAPIAxiosInstanceToken = (newToken: string) => {
	const completeAuthorizationHeader:string = `Bearer ${newToken}`
	// @ts-ignore
	api.defaults.headers["Authorization"] = completeAuthorizationHeader
	// @ts-ignore
	api.defaults.headers.common["Authorization"] = completeAuthorizationHeader;
};

export const updateMarketAxiosInstanceToken = (
	token: string,
	expiration: number
) => {
	setSessionStorage("marketToken", token);
	// setSessionStorage("marketTokenExpiration", expiration * 60 * 100);
	marketAxiosInstance.defaults.headers.common[
		"Authorization"
	] = `Bearer ${token}`;
};

export { api, marketAxiosInstance };
