import { forwardRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
	Dialog,
	DialogProps,
	DialogContent,
	DialogTitle,
	dialogTitleClasses,
	dialogContentClasses,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import styled from "styled-components";

import EmptyHeader from "../../styled/EmptyHeader";
import EmptyMessage from "../../styled/EmptyMessage";
import SearchBar from "../SearchBar";
import FXItem, { StyledFXItem } from "../FXItem";

import { FXType } from "../../../types/fx.type";
import { FX_SEARCH_IGNORE_SPECIAL_CHARACTER_REGEX } from "../../../constants/fx.constant";
import { boyerMooreTextSearch } from "../../../utils/common.utils";
import { useAppSelector } from "../../../utils/store.utils";
import { selectCurrencies, selectFXList } from "../../../slice/fx";

import { ReactComponent as CloseIcon } from "../../../assets/icons/close.svg";

interface FXSelectorDialogProps extends DialogProps {
	onConfirm: (fxObject: FXType) => void;
	onClose: () => void;
}

/**
 * Transition component.
 *
 * This component is a wrapper for Slide component and accepts props of type TransitionProps
 * and a required prop "children" of type React.ReactElement.
 *
 * @param {TransitionProps & { children: React.ReactElement }} props - The props for the Transition component.
 * @param {React.Ref<unknown>} ref - The ref for the Transition component.
 * @returns {React.ReactElement} The rendered Transition component.
 */
const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const FXSelectorDialogHeaderStartContainer = styled.div`
	min-width: 24px;
	line-height: 1;
`;

const FXSelectorDialogHeaderTitle = styled.div`
	flex: 1 0 auto;
	margin-left: 16px;
	margin-right: 16px;
	font-weight: var(--font-weight-semi-bold);
	text-align: center;
`;

const FXSelectorDialogHeaderEndContainer = styled.div`
	min-width: 24px;
	line-height: 1;
`;

const FXSelectorDialogHeader = styled(DialogTitle)`
	display: flex;
	align-items: center;
	min-height: calc(44px + constant(safe-area-inset-top));
	min-height: calc(44px + env(safe-area-inset-top));
	padding-top: calc(18px + constant(safe-area-inset-top));
	padding-top: calc(18px + env(safe-area-inset-top));
	padding-right: 24px;
	padding-bottom: 18px;
	padding-left: 24px;
	background-color: var(--background-color-primary);
	user-select: none;
`;

const StyledFXSelectorDialog = styled(Dialog)`
	.${dialogTitleClasses.root} {
		display: flex;
		justify-content: space-between;
		min-height: calc(44px + constant(safe-area-inset-top));
		min-height: calc(44px + env(safe-area-inset-top));
		padding-top: calc(18px + constant(safe-area-inset-top));
		padding-top: calc(18px + env(safe-area-inset-top));
		padding-right: 24px;
		padding-bottom: 18px;
		padding-left: 24px;
		font-family: inherit;
		font-size: 16px;
		letter-spacing: normal;

		&.close-only {
			justify-content: flex-end;
		}
	}

	.${dialogContentClasses.root} {
		overflow-y: hidden;
	}
`;

const FXSelectorDialogContent = styled(DialogContent)`
	&.${dialogContentClasses.root} {
		display: flex;
		flex-direction: column;
		padding: 0;
		background-color: var(--background-color-primary);
	}
`;

const FXListContainer = styled.div`
	padding-top: 24px;
	overflow-y: auto;

	${StyledFXItem}:last-child {
		border-bottom: 0;
	}
`;

const FXSelectorDialog = ({
	open,
	onConfirm,
	onClose,
}: FXSelectorDialogProps) => {
	const intl = useIntl();
	const currencies = useAppSelector(selectCurrencies);
	const fxList = useAppSelector(selectFXList);
	const [filteredFXList, setFilteredFXList] = useState<FXType[]>(
		JSON.parse(JSON.stringify(fxList))
	);
	const [searchText, setSearchText] = useState<string>("");

	// Reset all state on exited
	/**
	 * This function is used to handle the exited state.
	 * It clears the search text and resets the filtered FX list.
	 * It does not return any value.
	 *
	 * @function
	 * @name handleExited
	 */
	const handleExited = () => {
		handleSearchTextClear();
		setFilteredFXList(JSON.parse(JSON.stringify(fxList)));
	};

	/**
	 * Handles the search functionality based on the given text.
	 *
	 * @param {string} text - The search text.
	 */
	const handleSearch = (text: string) => {
		if (text.length < 1) {
			setFilteredFXList(fxList);
		} else {
			let filteredCurrency: string[] = [];
			const splittedSearchText = text.split(" ").filter((v) => v && v !== "");
			baseLoop: for (let i = 0; i < currencies.length; i++) {
				for (let j = 0; j < splittedSearchText.length; j++) {
					if (
						boyerMooreTextSearch(
							currencies[i].currency.toLowerCase(),
							splittedSearchText[j].toLowerCase()
						) > -1
					) {
						filteredCurrency.push(currencies[i].currency);
						continue baseLoop;
					}
				}

				currencyKeywordLoop: for (
					let j = 0;
					j < currencies[i].keywords.length;
					j++
				) {
					for (let k = 0; k < splittedSearchText.length; k++) {
						const index = boyerMooreTextSearch(
							currencies[i].keywords[j].toLowerCase(),
							splittedSearchText[k].toLowerCase()
						);
						if (index > -1) {
							filteredCurrency.push(currencies[i].currency);
							break currencyKeywordLoop;
						}
					}
				}
			}

			setFilteredFXList(
				fxList.filter((v) => {
					const fxKeywords = [
						`${v.currencies[0]}${v.currencies[1]}`,
						`${v.currencies[1]}${v.currencies[0]}`,
					];

					for (let i = 0; i < fxKeywords.length; i++) {
						const formatted = text
							.toLowerCase()
							.replaceAll(FX_SEARCH_IGNORE_SPECIAL_CHARACTER_REGEX, "");
						if (
							formatted !== "" &&
							boyerMooreTextSearch(fxKeywords[i].toLowerCase(), formatted) > -1
						) {
							return v;
						}
					}

					for (let i = 0; i < v.currencies.length; i++) {
						if (filteredCurrency.indexOf(v.currencies[i]) > -1) return v;
					}
				})
			);
		}
	};

	/**
	 * Handles the change event of the search bar.
	 *
	 * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object.
	 */
	const handleSearchBarChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSearchText(event.currentTarget.value);
		handleSearch(event.currentTarget.value);
	};

	/**
	 * Clears the search text and triggers a search.
	 * @function
	 * @name handleSearchTextClear
	 */
	const handleSearchTextClear = () => {
		setSearchText("");
		handleSearch("");
	};

	/**
	 * Handles the selection of a FX object.
	 *
	 * @param {FXType} fxObject - The selected FX object.
	 * @returns {void}
	 */
	const handleFXSelect = (fxObject: FXType) => {
		onConfirm(fxObject);
		onClose();
	};

	return (
		<StyledFXSelectorDialog
			open={open}
			fullScreen
			TransitionComponent={Transition}
			TransitionProps={{ onExited: handleExited }}
			onClose={onClose}
		>
			<FXSelectorDialogHeader>
				<FXSelectorDialogHeaderStartContainer></FXSelectorDialogHeaderStartContainer>
				<FXSelectorDialogHeaderTitle>
					{intl.formatMessage({
						id: "app.page.watchlistEdit.dialog.fxSelector.title",
					})}
				</FXSelectorDialogHeaderTitle>
				<FXSelectorDialogHeaderEndContainer>
					<CloseIcon className="mbb-icon" onClick={onClose} />
				</FXSelectorDialogHeaderEndContainer>
			</FXSelectorDialogHeader>
			<FXSelectorDialogContent>
				<SearchBar
					value={searchText}
					onClear={handleSearchTextClear}
					inputProps={{
						onChange: handleSearchBarChange,
					}}
				/>
				<FXListContainer>
					{filteredFXList.length > 0 ? (
						filteredFXList.map((v) => (
							<FXItem
								key={`fx-item-checkbox-${v.symbol}`}
								fxObject={v}
								onClick={handleFXSelect}
							/>
						))
					) : (
						<>
							<EmptyHeader>
								{intl.formatMessage({
									id: "app.page.watchlistEdit.dialog.fxSelector.message.empty.header",
								})}
							</EmptyHeader>
							<EmptyMessage>
								{intl.formatMessage({
									id: "app.page.watchlistEdit.dialog.fxSelector.message.empty.content",
								})}
							</EmptyMessage>
						</>
					)}
				</FXListContainer>
			</FXSelectorDialogContent>
		</StyledFXSelectorDialog>
	);
};

export default FXSelectorDialog;
