export const addOverlay = () => {
    console.log("make a layer to stop all click events")
    const appElement = document.querySelector('.app');
    if (appElement) {            
            const overlay = document.createElement('div');
            overlay.style.position = 'absolute';
            overlay.style.top = '0';
            overlay.style.left = '0';
            overlay.style.width = '100%';
            overlay.style.height = '100%';
            // overlay.style.backgroundColor = 'rgba(0, 255, 0, 0.1)'; // transparent blue
            overlay.style.pointerEvents = 'all';
            overlay.style.zIndex = '1300'; // this is set to be lower than the snackbar, which is 1400
            appElement.appendChild(overlay);
    }
}
