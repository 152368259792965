import styled from "styled-components";
import {
    INPUT_FIELD_HELPER_TEXT_TYPE,
    INPUT_FIELD_TEXT_COUNT_MODE,
} from "../../constants/common.constant";
import {getRealTextLength} from "../../utils/common.utils";

interface HelperTextProps {
    helperText?: React.ReactNode;
    helperTextProps?: {
        align?: "left" | "between" | "right";
        type?: INPUT_FIELD_HELPER_TEXT_TYPE;
    };
    textCount?: {
        mode?: INPUT_FIELD_TEXT_COUNT_MODE;
        trim?: boolean;
        limit?: number;
    };
    id?: string;
    disableHelperText?: boolean
}

interface InputFieldProps extends HelperTextProps {
    value: number | string;
    fullWidth?: boolean;
    inputProps?: Omit<React.ComponentPropsWithRef<"input">, "value">;
    specialColor?: string
}

export const InputFieldContainer = styled.div``;

const StyledInputField = styled.input<InputFieldProps>`
    margin-bottom: 8px;
    border: 0;
    border-bottom: 1px solid var(--input-border-color);
    border-radius: 0;
    padding: 0 0 8px 0;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: var(--font-weight-semi-bold);
    line-height: 26px;
    background: none;
    outline: none;
    appearance: none;

    ${({ fullWidth }) => fullWidth && `width: 100%;`}

    ${({ helperTextProps }) => {
        if (helperTextProps?.type) {
            switch (helperTextProps?.type) {
                case INPUT_FIELD_HELPER_TEXT_TYPE.SUCCESS:
                    return `border-color: var(--input-success-border-color);`;
                case INPUT_FIELD_HELPER_TEXT_TYPE.ERROR:
                    return `border-color: var(--input-error-border-color);`;
            }
        }
    }}

    ${({ specialColor }) => specialColor === 'grey' && `
        color: rgba(207, 207, 207, 1);
    `}
`;


const HelperText = styled.div<HelperTextProps>`
    display: flex;
    margin-bottom: 8px;
    color: var(--text-color-secondary);
    font-size: 12px;
    font-weight: var(--font-weight-light);

    ${({helperText, helperTextProps, textCount}) => {
        if (helperTextProps?.align) {
            if (helperText && textCount) {
                return `justify-content: space-between`;
            } else {
                switch (helperTextProps?.align) {
                    case "between":
                        return `justify-content: space-between`;
                    case "right":
                        return `justify-content: flex-end;`;
                }
            }
        }
    }}

    ${({helperTextProps}) => {
        if (helperTextProps?.type) {
            switch (helperTextProps?.type) {
                case INPUT_FIELD_HELPER_TEXT_TYPE.SUCCESS:
                    return `color: var(--text-color-green);`;
                case INPUT_FIELD_HELPER_TEXT_TYPE.ERROR:
                    return `color: var(--text-color-red);`;
            }
        }
    }}
`;

const InputField = ({
                        value,
                        fullWidth,
                        inputProps,
                        helperText,
                        helperTextProps,
                        textCount,
                        id, disableHelperText, specialColor
                    }: InputFieldProps) => {
    /**
     * Renders the text count based on the provided configuration.
     *
     * @returns {React.ReactNode | null} - The rendered text count or null if textCount is not provided.
     */
    const renderTextCount = (): React.ReactNode | null => {
        if (textCount) {
            const realTextLength =
                typeof value !== "undefined" && value !== null
                    ? textCount.trim
                        ? getRealTextLength(value.toString().trim())
                        : getRealTextLength(value.toString())
                    : 0;
            switch (textCount.mode) {
                case INPUT_FIELD_TEXT_COUNT_MODE.COUNT:
                    return <span>{realTextLength}</span>;
                case INPUT_FIELD_TEXT_COUNT_MODE.LIMIT:
                    return <span>{textCount.limit}</span>;
                case INPUT_FIELD_TEXT_COUNT_MODE.COUNT_AND_LIMIT:
                    return (
                        <span>
							{realTextLength}/{textCount.limit}
						</span>
                    );
                case INPUT_FIELD_TEXT_COUNT_MODE.REMAINING:
                    return (
                        <span>{textCount.limit && textCount.limit - realTextLength}</span>
                    );
                default:
                    return null;
            }
        }

        return null;
    };

    /**
     * Handles the change event of an input element.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The change event.
     */
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (textCount?.limit) {
            if (
                textCount.trim &&
                getRealTextLength(event.currentTarget.value.toString().trim()) >
                textCount.limit
            )
                return;
            else if (
                getRealTextLength(event.currentTarget.value.toString()) >
                textCount.limit
            )
                return;
        }
        if (inputProps?.onChange) inputProps?.onChange(event);
    };

    return (
        <InputFieldContainer>
            <StyledInputField
                value={value}
                {...inputProps}
                onChange={handleInputChange}
                fullWidth={fullWidth}
                helperTextProps={helperTextProps}
                id={id}
                autoComplete={"off"}
                specialColor={specialColor}
            />
            {
                !disableHelperText &&
                <HelperText
                    helperText={helperText}
                    helperTextProps={helperTextProps}
                    textCount={textCount}
                >
                    {helperText}
                    {renderTextCount()}
                </HelperText>
            }

        </InputFieldContainer>
    );
};

export default InputField;
