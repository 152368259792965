export enum RateAlertTabEnum{
    ACTIVE = "ACTIVE",
    HISTORY = "HISTORY",
}

export enum ListRateAlertStateEnum{
    active = "active",
    history = "all" // note that, to click history, the param is "all"
}

export enum RateAlertStatusEnum{
    PENDING = "PENDING",
    EXPIRED = "EXPIRED",
    CANCELLED = "CANCELLED",
    COMPLETED = "COMPLETED"
}
