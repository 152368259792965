import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import Card from "../../styled/Card";
import FXNationalFlag, {
	StyledFXNationalFlag,
} from "../../common/FXNationalFlag";

import { FXType } from "../../../types/fx.type";
import { COUNTRY_CURRENCY_MAP } from "../../../constants/fx.constant";

import { ReactComponent as RemoveIcon } from "../../../assets/icons/remove.svg";
import { ReactComponent as SortIcon } from "../../../assets/icons/sort.svg";

interface EditableWatchlistFXCardProps {
	id: string;
	fxObject: FXType;
	onRemove: () => void;
}

export const StyledEditableWatchlistFXCard = styled(Card)`
	position: relative;
	display: flex;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 16px;
	margin-left: 8px;

	${StyledFXNationalFlag} {
		margin-right: 4px;
	}

	.icon-sort {
		width: 24px;
		height: 24px;
		flex-shrink: 0;
		margin-left: 6px;
		padding: 4px;
	}

	.icon-remove {
		position: absolute;
		left: -12px;
		z-index: 3;
	}
`;

const FXText = styled.div`
	flex: 1 0 auto;
	margin-left: 8px;
	margin-right: 8px;
	font-size: 14px;
	font-weight: var(--font-weight-semi-bold);
	text-transform: capitalize;
`;

const EditableWatchlistFXCard = ({
	id,
	fxObject,
	onRemove,
}: EditableWatchlistFXCardProps) => {
	const intl = useIntl();

	return (
		<StyledEditableWatchlistFXCard>
			<RemoveIcon className="mbb-icon icon-remove" onClick={onRemove} />
			<FXNationalFlag
				source={
					COUNTRY_CURRENCY_MAP[
						fxObject.currencies[0].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
					]
				}
				target={
					COUNTRY_CURRENCY_MAP[
						fxObject.currencies[1].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
					]
				}
				width={36}
				height={36}
			/>
			<FXText>
				{intl.formatMessage(
					{ id: "app.common.fx" },
					{ source: fxObject.currencies[0], target: fxObject.currencies[1] }
				)}
			</FXText>
			<SortIcon className="mbb-icon icon-sort" onClick={() => {}} />
		</StyledEditableWatchlistFXCard>
	);
};

export default EditableWatchlistFXCard;
