import { UserBankAccountType } from "../../../../types/user.type";

/**
 * Find bank accounts by currency
 * @param {Array<UserBankAccountType>} accounts - Bank accounts for search.
 * @param {string} currency - The currency you want to search by.
 * @return {Array<UserBankAccountType>} Array of filtered bank accounts.
 */
const findBankAccountByCurrency = (
	accounts: UserBankAccountType[],
	currency: string
): UserBankAccountType[] => {
	return accounts.filter((v) => v.currency === currency);
};

export default findBankAccountByCurrency;
