import { forwardRef } from "react";
import { Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

/**
 * SlideUpTransition is a transition component that animates its children by sliding them up.
 *
 * USAGE:
 * - Wrap the content that you want to animate with SlideUpTransition component.
 *
 * @component
 *
 * @param {object} props - The properties of the SlideUpTransition component.
 * @param {React.ReactElement} props.children - The content to be animated.
 *
 * @returns {React.ReactElement} The SlideUpTransition component.
 */
const SlideUpTransition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default SlideUpTransition;
