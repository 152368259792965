/**
 * Parses a date string into a Date object.
 *
 * @param {string | undefined} dateString - The date string to parse.
 *     If undefined, the current date and time will be returned.
 * @return {Date} The parsed Date object. If no input is provided,
 *     the current date and time will be returned.
 */
export function parseDateString(dateString: string | undefined): Date {
    if (dateString === undefined) {
        // Return the current date and time if no input is provided
        return new Date();
    }

    // Create a Date object from the input string
    const date = new Date(dateString);

    // Convert the date to Malaysia time (GMT+8)
    const malaysiaTimeOffset = 8 * 60; // 8 hours in minutes
    const utcTimeOffset = date.getTimezoneOffset(); // in minutes
    date.setMinutes(date.getMinutes() + malaysiaTimeOffset + utcTimeOffset);

    return date;
}
