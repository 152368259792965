import styled from "styled-components";

const StyledSwitch = styled.div`
	line-height: 14px;
`;

const SwitchInput = styled.input`
	position: relative;
	display: inline-block;
	width: 39px;
	height: 22px;
	border-radius: 999px;
	background-color: var(--switch-track-background-color);
	cursor: pointer;
	appearance: none;

	&::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 1px;
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: var(--switch-thumb-background-color);
		box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.17);
		transform: translateY(-50%);
		transition: left 0.15s var(--common-animation-curve),
			right 0.15s var(--common-animation-curve);
	}

	&:checked {
		background-color: var(--switch-track-toggled-background-color);

		&::after {
			left: calc(100% - 21px);
		}
	}
`;

const Switch = ({ type, ...rest }: React.ComponentPropsWithRef<"input">) => {
	return (
		<StyledSwitch>
			<SwitchInput type="checkbox" {...rest} />
		</StyledSwitch>
	);
};

export default Switch;
