import { BASE_CURRENCY } from "../../../../constants/common.constant";

/**
 * Get fee currency (MYR cannot be a fee currency)
 * @param {string} source - Source (From) currency.
 * @param {string} target - Target (To) currency.
 * @return {string} The fee currency.
 */
const getFeeCurrency = (source: string, target: string): string =>
	source !== BASE_CURRENCY && target !== BASE_CURRENCY
		? source
		: source !== BASE_CURRENCY && target === BASE_CURRENCY
		? source
		: target;

export default getFeeCurrency;
