export const countDecimals = (value: string | number): number => {
    // Convert the value to a string if it is a number
    const valueStr = typeof value === 'number' ? value.toString() : value;

    if (valueStr.includes('.')) {
        return valueStr.split('.')[1].length;
    }

    return 0;
};

