import styled from "styled-components";

interface SubTextProps extends React.ComponentProps<any> {
	tag?: "div" | "span";
	children?: React.ReactNode;
}

const StyledSubText = styled.div`
	color: var(--text-color-secondary);
	font-size: 12px;
	font-weight: var(--font-weight-light);
`;

const SubText = ({ tag, children, ...rest }: SubTextProps) => {
	return (
		<StyledSubText as={tag} {...rest}>
			{children}
		</StyledSubText>
	);
};

export default SubText;
