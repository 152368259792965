import styled from "styled-components";
import { StyledButton } from "./Button";

interface ButtonContainerProps {
	hasPaddingX?: boolean;
	isWrappable?: boolean;
	wrapCount?: number;
}

const ButtonContainer = styled.div<ButtonContainerProps>`
	display: flex;
	${({ isWrappable }) =>
		isWrappable ? "flex-wrap: wrap;" : "flex-wrap: nowrap;"}
	${({ hasPaddingX }) =>
		hasPaddingX
			? "padding-left: var(--common-layout-padding-x); padding-right: var(--common-layout-padding-x);"
			: ""}

	> ${StyledButton} {
		${({ isWrappable, wrapCount }) =>
			isWrappable && wrapCount && wrapCount > 0 ? `` : "flex: 1 0 auto"}
	}
`;

export default ButtonContainer;
