import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import Card from "../styled/Card";
import FXNationalFlag, { StyledFXNationalFlag } from "../common/FXNationalFlag";

import { FXType } from "../../types/fx.type";
import { DEFAULT_FX_PRICE_CHANGE_COLOR_TIMEOUT } from "../../constants/watchlist.constant";
import { COUNTRY_CURRENCY_MAP } from "../../constants/fx.constant";
import { useAppSelector } from "../../utils/store.utils";
import { formatURL } from "../../formatters/common";
import { selectSelectedWatchlistIndex } from "../../slice/watchlist";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";

import { ReactComponent as ChartIcon } from "../../assets/icons/chart.svg";
import {refreshMarketToken} from "../../utils/auth.utilis";
import {selectUser} from "../../slice/user";
import {ACTIVATED_ACCOUNT_ROLE} from "../../constants/common.constant";
import {renderRate} from "../order/CalculationCard";
import {clearPasswordTimeoutInterval, requestPasswordAuthentication} from "../../utils/integration.utils";
import {IntegrationMessageDataType} from "../../types/integration.type";
import {MAE_APP_RESPONSE_EVENT_TYPE} from "../../constants/integration.constant";
import {updateAPIAxiosInstanceToken} from "../../utils/api.utils";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import errorTimeout from "../../slice/errorTimeout";
import {getDpUqBySymbol} from "../fx/service/getDpUqBySymbol";

interface WatchlistFXCardProps {
	fxObject: FXType;
	children?: React.ReactNode;
	onChartClick: (fx: string) => void;
}

interface FXPriceContainerProps {
	mode: "buy" | "sell";
}

export const StyledWatchlistFXCard = styled(Card)`
	display: flex;
	align-items: center;
	margin-top: 16px;
	margin-bottom: 16px;
	padding: 10px;

	${StyledFXNationalFlag} {
		margin-right: 4px;
	}

	.mbb-icon {
		width: 24px;
		height: 24px;
		flex-shrink: 0;
		margin-left: 2px;
		padding: 4px;
	}
`;

const FXText = styled.div`
	width: 57px;
	min-width: 57px;
	flex: 1 0 auto;
	margin-right: 6px;
	font-size: 12px;
	font-weight: 600;
	text-transform: capitalize;
`;

const FXPriceText = styled.div`
	font-size: 12px;
	font-weight: 600;

	&.pricing {
		transition: color 0.2s var(--common-animation-curve);
	}

	&.price-up,
	&.price-down {
		transition: none;
	}

	&.price-up {
		color: var(--text-color-green);
	}

	&.price-down {
		color: var(--text-color-red);
	}
`;

const FXPriceContainer = styled.div<FXPriceContainerProps>`
	width: 76px;
	min-width: 76px;
	max-width: 76px;
	margin-left: 2px;
	margin-right: 2px;
	border: 1px solid var(--border-color);
	border-radius: 7px;
	padding: 8px;
	text-align: center;
	line-height: 12px;

	> ${FXPriceText} {
		margin-bottom: 2px;
	}
`;

const FXPriceMessage = styled.span`
	font-size: 10px;
	font-weight: 400;
	color: var(--text-color-secondary);
`;

const WatchlistFXCard = ({
                             fxObject,
                             children,
                             onChartClick,
                         }: WatchlistFXCardProps) => {
    const intl = useIntl();
    const navigate = useNavigateThrottle();
    const selectedWatchlistIndex = useAppSelector(selectSelectedWatchlistIndex);
    const [askClassName, setAskClassName] = useState<string>("pricing");
    const [bidClassName, setBidClassName] = useState<string>("pricing");
    const prevAskRef = useRef<number | null>(fxObject.ask);
    const prevBidRef = useRef<number | null>(fxObject.bid);
    const isBuyOrSellProp = useRef<"buy" | "sell">("buy")
    const prevSelectedWatchlistIndex = useRef<number>(selectedWatchlistIndex);
    const user = useAppSelector(selectUser);
    const errorTimeout = useAppSelector((state) => state.errorTimeout);

	useEffect(() => {
		if (
			prevSelectedWatchlistIndex.current === selectedWatchlistIndex &&
			prevAskRef.current !== null &&
			fxObject.ask !== null
		) {
			if (prevAskRef.current > fxObject.ask) {
				setAskClassName("pricing price-down");
				setTimeout(
					() => setAskClassName("pricing"),
					DEFAULT_FX_PRICE_CHANGE_COLOR_TIMEOUT
				);
			} else if (prevAskRef.current < fxObject.ask) {
				setAskClassName("pricing price-up");
				setTimeout(
					() => setAskClassName("pricing"),
					DEFAULT_FX_PRICE_CHANGE_COLOR_TIMEOUT
				);
			}
		} else if (prevSelectedWatchlistIndex.current !== selectedWatchlistIndex) {
			setAskClassName("");
		}
		if (
			prevSelectedWatchlistIndex.current === selectedWatchlistIndex &&
			prevBidRef.current !== null &&
			fxObject.bid !== null
		) {
			if (prevBidRef.current > fxObject.bid) {
				setBidClassName("pricing price-down");
				setTimeout(
					() => setBidClassName("pricing"),
					DEFAULT_FX_PRICE_CHANGE_COLOR_TIMEOUT
				);
			} else if (prevBidRef.current < fxObject.bid) {
				setBidClassName("pricing price-up");
				setTimeout(
					() => setBidClassName("pricing"),
					DEFAULT_FX_PRICE_CHANGE_COLOR_TIMEOUT
				);
			}
		} else if (prevSelectedWatchlistIndex.current !== selectedWatchlistIndex) {
			setBidClassName("");
		}

		prevSelectedWatchlistIndex.current = selectedWatchlistIndex;
		if (fxObject.ask !== null) prevAskRef.current = fxObject.ask;
		if (fxObject.ask !== null) prevBidRef.current = fxObject.bid;
	}, [selectedWatchlistIndex, fxObject.ask, fxObject.bid]);

    const handleAuthenticate = (message: MessageEvent<string>) => {
        try {
            const formattedData: IntegrationMessageDataType = JSON.parse(
                message.data
            );
            switch (formattedData.type) {
                case MAE_APP_RESPONSE_EVENT_TYPE.PASSWORD:
                    if (errorTimeout.passwordResponseRemainTime > 0){
                        const token = formattedData.data //this is L3 token, according to the DRS
                        if (token) {
                            updateAPIAxiosInstanceToken(token)
                        }
                        navigate(
                            formatURL("/order/form/step-1", {
                                from: fxObject.currencies[isBuyOrSellProp.current === "buy" ? 1 : 0],
                                to: fxObject.currencies[isBuyOrSellProp.current === "buy" ? 0 : 1],
                            })
                        );
                        clearPasswordTimeoutInterval()
                        closeSnackbar()
                        window.removeEventListener("message", handleAuthenticate); // iOS
                        document.removeEventListener("message", onMessageHandler); // Android
                    }
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    };
    const onMessageHandler: EventListener = (event: any) => {
        if (
            event.type &&
            event.type === "message" &&
            event.data &&
            typeof event.data === "string"
        ) {
            handleAuthenticate(event);
        }
    };



    /**
     * Handle trade click event.
     * This function is triggered when the user clicks on the trade button for a specific mode (buy or sell).
     * It will perform password authentication and navigate to the FX order form.
     *
     * @param {string} mode - The trade mode, either "buy" or "sell".
     * @returns {Function} - A function that performs the handling logic.
     */
    const handleTradeClick = (mode: "buy" | "sell") => () => {
        if (ACTIVATED_ACCOUNT_ROLE.includes(user.type)) {
            window.addEventListener("message", handleAuthenticate); // iOS
            document.addEventListener("message", onMessageHandler); // Android
            requestPasswordAuthentication();
        }
        isBuyOrSellProp.current = mode
    };

	return (
		<StyledWatchlistFXCard>
			<FXNationalFlag
				source={
					COUNTRY_CURRENCY_MAP[
						fxObject.currencies[0].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
					]
				}
				target={
					COUNTRY_CURRENCY_MAP[
						fxObject.currencies[1].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
					]
				}
			/>
			<FXText>
				{intl.formatMessage(
					{ id: "app.common.fx" },
					{ source: fxObject.currencies[0], target: fxObject.currencies[1] }
				)}
			</FXText>
			<FXPriceContainer mode="sell" onClick={fxObject.bid ? handleTradeClick("sell") : () => { }}>
				<FXPriceText className={askClassName}>
					{fxObject.bid ? renderRate('ccy1', 'ccy2', fxObject.bid, getDpUqBySymbol(fxObject.symbol).dp, getDpUqBySymbol(fxObject.symbol).uq, true) : "-"}
				</FXPriceText>
				<FXPriceMessage>
					{intl.formatMessage(
						{
							id: "app.page.watchlist.card.fx.message.sell",
						},
						{ currency: fxObject.currencies[0] }
					)}
				</FXPriceMessage>
			</FXPriceContainer>
			<FXPriceContainer mode="buy" onClick={fxObject.ask ? handleTradeClick("buy") : () => { }}>
				<FXPriceText className={bidClassName}>
					{fxObject.ask ? renderRate('ccy1', 'ccy2', fxObject.ask, getDpUqBySymbol(fxObject.symbol).dp, getDpUqBySymbol(fxObject.symbol).uq, true) : "-"}
				</FXPriceText>
				<FXPriceMessage>
					{intl.formatMessage(
						{
							id: "app.page.watchlist.card.fx.message.buy",
						},
						{ currency: fxObject.currencies[0] }
					)}
				</FXPriceMessage>
			</FXPriceContainer>
			<ChartIcon
				className="mbb-icon"
				onClick={() => onChartClick(fxObject.symbol)}
			/>
		</StyledWatchlistFXCard>
	);
};

export default WatchlistFXCard;
