import {forwardRef, useEffect, useState} from "react";
import {useIntl} from "react-intl";
import {
    Dialog,
    DialogProps,
    DialogContent,
    DialogTitle,
    dialogTitleClasses,
    dialogContentClasses,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import {TransitionProps} from "@mui/material/transitions";
import styled from "styled-components";

import EmptyHeader from "../../styled/EmptyHeader";
import EmptyMessage from "../../styled/EmptyMessage";
import SearchBar from "../SearchBar";
import CurrencyItem, {StyledCurrencyItem} from "../CurrencyItem";

import {CurrencyType} from "../../../types/fx.type";
import {FX_SEARCH_IGNORE_SPECIAL_CHARACTER_REGEX} from "../../../constants/fx.constant";
import {boyerMooreTextSearch} from "../../../utils/common.utils";
import {useAppSelector} from "../../../utils/store.utils";
import {selectCurrencies, selectFXList} from "../../../slice/fx";

import {ReactComponent as ArrowLeftIcon} from "../../../assets/icons/arrow-left.svg";
import useNavigateThrottle from "../../../hooks/useNavigateThrottle";

interface CurrencySelectorDialogProps extends DialogProps {
    availableCurrencies?: CurrencyType[];
    onConfirm: (currencyObject: CurrencyType) => void;
    onClose: () => void;
    direction?: "from" | "to"
    leftIconSpecFunc?: "back"
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CurrencySelectorDialogHeaderStartContainer = styled.div`
    min-width: 24px;
    line-height: 1;
`;

const CurrencySelectorDialogHeaderTitle = styled.div`
    flex: 1 0 auto;
    margin-left: 16px;
    margin-right: 16px;
    font-weight: var(--font-weight-semi-bold);
    text-align: center;
`;

const CurrencySelectorDialogHeaderEndContainer = styled.div`
    min-width: 24px;
    line-height: 1;
`;

const CurrencySelectorDialogHeader = styled(DialogTitle)`
    display: flex;
    align-items: center;
    min-height: calc(44px + constant(safe-area-inset-top));
    min-height: calc(44px + env(safe-area-inset-top));
    padding-top: calc(18px + constant(safe-area-inset-top));
    padding-top: calc(18px + env(safe-area-inset-top));
    padding-right: 24px;
    padding-bottom: 18px;
    padding-left: 24px;
    background-color: var(--background-color-primary);
    user-select: none;
`;

const StyledCurrencySelectorDialog = styled(Dialog)`
    .${dialogTitleClasses.root} {
        display: flex;
        justify-content: space-between;
        min-height: calc(44px + constant(safe-area-inset-top));
        min-height: calc(44px + env(safe-area-inset-top));
        padding-top: calc(18px + constant(safe-area-inset-top));
        padding-top: calc(18px + env(safe-area-inset-top));
        padding-right: 24px;
        padding-bottom: 18px;
        padding-left: 24px;
        font-family: inherit;
        font-size: 16px;
        letter-spacing: normal;

        &.close-only {
            justify-content: flex-end;
        }
    }

    .${dialogContentClasses.root} {
        overflow-y: hidden;
    }
`;

const CurrencySelectorDialogContent = styled(DialogContent)`
    &.${dialogContentClasses.root} {
        display: flex;
        flex-direction: column;
        padding: 0;
        background-color: var(--background-color-primary);
    }
`;

const CurrencyListContainer = styled.div`
    padding-top: 24px;
    overflow-y: auto;

    ${StyledCurrencyItem}:last-child {
        border-bottom: 0;
    }
`;

const CurrencySelectorDialog = ({
                                    availableCurrencies,
                                    open,
                                    onConfirm,
                                    onClose,
                                    direction,
                                    leftIconSpecFunc,
                                }: CurrencySelectorDialogProps) => {
    const intl = useIntl();
    const navigate = useNavigateThrottle();
    const currencies = useAppSelector(selectCurrencies);
    const [filteredCurrencyList, setFilteredCurrencyList] = useState<
        CurrencyType[]
    >(JSON.parse(JSON.stringify(availableCurrencies || currencies)));
    const [searchText, setSearchText] = useState<string>("");

    useEffect(() => {
        if (availableCurrencies) setFilteredCurrencyList(availableCurrencies);
    }, [availableCurrencies]);

    // Reset all state on exited
    /**
     * Clears the search text and updates the filtered currency list.
     *
     * @function
     * @name handleExited
     */
    const handleExited = () => {
        handleSearchTextClear();
        setFilteredCurrencyList(
            JSON.parse(JSON.stringify(availableCurrencies || currencies))
        );
    };

    /**
     * Handle the search functionality for filtering currency list.
     * @param {string} text - The search text to filter the currency list.
     */
    const handleSearch = (text: string) => {
        const realCurrencies = availableCurrencies || currencies;
        if (text.length < 1) {
            setFilteredCurrencyList(realCurrencies);
        } else {
            let filteredCurrency: string[] = [];
            const splittedSearchText = text.split(" ").filter((v) => v && v !== "");
            baseLoop: for (let i = 0; i < realCurrencies.length; i++) {
                for (let j = 0; j < splittedSearchText.length; j++) {
                    if (
                        boyerMooreTextSearch(
                            realCurrencies[i].currency.toLowerCase(),
                            splittedSearchText[j].toLowerCase()
                        ) > -1
                    ) {
                        filteredCurrency.push(realCurrencies[i].currency);
                        continue baseLoop;
                    }
                }

                currencyKeywordLoop: for (
                    let j = 0;
                    j < realCurrencies[i].keywords.length;
                    j++
                ) {
                    for (let k = 0; k < splittedSearchText.length; k++) {
                        const index = boyerMooreTextSearch(
                            realCurrencies[i].keywords[j].toLowerCase(),
                            splittedSearchText[k].toLowerCase()
                        );
                        if (index > -1) {
                            filteredCurrency.push(realCurrencies[i].currency);
                            break currencyKeywordLoop;
                        }
                    }
                }
            }

            setFilteredCurrencyList(
                realCurrencies.filter((v) => filteredCurrency.indexOf(v.currency) > -1)
            );
        }
    };

    /**
     * Handles the change event of the search bar input.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The change event object.
     * @returns {void}
     */
    const handleSearchBarChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSearchText(event.currentTarget.value);
        handleSearch(event.currentTarget.value);
    };

    /**
     * Clears the search text and triggers the search operation.
     *
     * @function handleSearchTextClear
     * @returns {void}
     */
    const handleSearchTextClear = () => {
        setSearchText("");
        handleSearch("");
    };

    /**
     * Handles the selection of a currency.
     * @param {CurrencyType} currencyObject - The selected currency object.
     * @returns {void}
     */
    const handleCurrencySelect = (currencyObject: CurrencyType) => {
        // console.log("currencyObject", currencyObject)
        onConfirm(currencyObject);
        onClose();
    };

    return (
        <StyledCurrencySelectorDialog
            open={open}
            fullScreen
            TransitionComponent={Transition}
            TransitionProps={{onExited: handleExited}}
            onClose={onClose}
        >
            <CurrencySelectorDialogHeader>
                <CurrencySelectorDialogHeaderStartContainer>
                    {
                        leftIconSpecFunc?
                        <ArrowLeftIcon className="mbb-icon"
                                       onClick={()=>{
                                           onClose()
                                           navigate(-1)
                                       }}
                        />
							:
							<ArrowLeftIcon className="mbb-icon"
										   onClick={onClose}
							/>
                    }
                </CurrencySelectorDialogHeaderStartContainer>
                <CurrencySelectorDialogHeaderTitle>

                    {direction === "from" && intl.formatMessage({
                        id: "app.page.createRateAlert.fromTitle",
                    })}
                    {direction === "to" && intl.formatMessage({
                        id: "app.page.createRateAlert.toTitle",
                    })}
                    {!direction && intl.formatMessage({
                        id: "app.dialog.currencySelector.title",
                    })}
                </CurrencySelectorDialogHeaderTitle>
                <CurrencySelectorDialogHeaderEndContainer></CurrencySelectorDialogHeaderEndContainer>
            </CurrencySelectorDialogHeader>
            <CurrencySelectorDialogContent>
                <SearchBar
                    value={searchText}
                    onClear={handleSearchTextClear}
                    inputProps={{
                        onChange: handleSearchBarChange,
                    }}
                />
                <CurrencyListContainer>
                    {filteredCurrencyList.length > 0 ? (
                        filteredCurrencyList.map((v) => (
                            <CurrencyItem
                                key={`fx-item-checkbox-${v.currency}`}
                                currencyObject={v}
                                onClick={handleCurrencySelect}
                            />
                        ))
                    ) : (
                        <>
                            <EmptyHeader>
                                {intl.formatMessage({
                                    id: "app.page.watchlistEdit.dialog.fxSelector.message.empty.header",
                                })}
                            </EmptyHeader>
                            <EmptyMessage>
                                {intl.formatMessage({
                                    id: "app.page.watchlistEdit.dialog.fxSelector.message.empty.content",
                                })}
                            </EmptyMessage>
                        </>
                    )}
                </CurrencyListContainer>
            </CurrencySelectorDialogContent>
        </StyledCurrencySelectorDialog>
    );
};

export default CurrencySelectorDialog;
