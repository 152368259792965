import * as React from 'react';
import {RateAlertInfoReal} from "../interface/RateAlertInfo";
import {FirstRowContainer} from "../../../commonStyle/FirstRowContainer";
import {RateAlertStatusEnum} from "../enums/RateAlertTabEnum";
import {ReactComponent as BinSVG} from './../../../assets/icons/trashBin.svg'
import {DateMsgContainer} from "../../../commonStyle/DateMsgContainer";
import {BoldFXRate} from "../../../commonStyle/BoldFXRate";
import {COUNTRY_CURRENCY_MAP} from "../../../constants/fx.constant";
import FXNationalFlag from "../../../components/common/FXNationalFlag";
import AlertDialog from "../../../components/common/dialog/AlertDialog";
import {useContext, useState} from "react";
import {useIntl} from "react-intl";
import {enqueueSnackbar} from "notistack";
import {getCurrencyPart} from "../../order/orderStatus/OrderCardContent";
import {formatDateGMTToMYT} from "../../order/services/formatDateToStringDDMonthYYYY";
import fxAPI from "../../../api/fx.api";
import {RefreshRateAlertContext} from "../RateAlert";
import {renderRate} from "../../../components/order/CalculationCard";
import {CANCEL_RATE_ALERT_FAIL, GET_ORDER_STATUS_LIST_INVALID} from "../../../constants/errorMsg.constant";
import {ErrorObjForToastBar} from "../../../types/errorObjType.type";
import {processApiError} from "../../../utils/errorHandling.utils";
import {TextWrapper} from "../../../components/styled/TextWrapper";

type Props = {
    rateAlert: RateAlertInfoReal
};
export const RateAlertCardContent = (props: Props) => {
    const [isRateAlertDialogOpen, setIsRateAlertDialogOpen] =
        useState<boolean>(false);
    const intl = useIntl()
    const refreshList = useContext(RefreshRateAlertContext);
    const ccyPairString = `${getCurrencyPart(props.rateAlert.symbol, true)}/${getCurrencyPart(props.rateAlert.symbol, false)}`
    const openCancelRateAlertModal = (): void => {
        setIsRateAlertDialogOpen(true)
    }

    async function handleCancelRateAlert() {
        setIsRateAlertDialogOpen(false)
        try {
            const response = await fxAPI.cancelFutureOrder(props.rateAlert.alertId)
            if (
                !response.data ||
                response.data.responseCode !== "0" ||
                !response.data.data
            ) throw {
                errorMsg: CANCEL_RATE_ALERT_FAIL,
                responseHttpStatus: response.status,
                responseData: response.data
            } as ErrorObjForToastBar
            setTimeout(refreshList, 2000)
            enqueueSnackbar(
                `${intl.formatMessage({
                    id: "app.page.rateAlert.delete.toastbar.partOne",
                })} ${ccyPairString} ${intl.formatMessage({
                    id: "app.page.rateAlert.delete.toastbar.partTwo",
                })}`, {
                    variant: "general",
                    mode: 'info',
                })
        } catch (error) {
            processApiError(error, undefined) //this is non-FATAL
        }
    }

    return (
        <>
            <FirstRowContainer>
                <div style={{display: "flex", alignItems: "center"}}>
                    <FXNationalFlag
                        source={
                            COUNTRY_CURRENCY_MAP[getCurrencyPart(props.rateAlert.symbol, true) as keyof typeof COUNTRY_CURRENCY_MAP]
                        }
                        target={
                            COUNTRY_CURRENCY_MAP[getCurrencyPart(props.rateAlert.symbol, false) as keyof typeof COUNTRY_CURRENCY_MAP]
                        }
                    />
                    <div style={{fontSize: "16px", fontWeight: 600, paddingLeft: 10}}>
                        {ccyPairString}
                    </div>
                </div>
                {
                    props.rateAlert.alertStatus === RateAlertStatusEnum.PENDING &&
                    <div onClick={openCancelRateAlertModal}>
                        <BinSVG/>
                    </div>
                }
            </FirstRowContainer>
            <BoldFXRate>
                {renderRate(
                    getCurrencyPart(props.rateAlert.symbol, true),
                    getCurrencyPart(props.rateAlert.symbol, false),
                    (props.rateAlert.rate ? props.rateAlert.rate : props.rateAlert.limit),
                    props.rateAlert.dp,
                    props.rateAlert.uq,
                )}
            </BoldFXRate>
            <DateMsgContainer>


                <div style={{color: "#7C7C7D"}}>
                    <TextWrapper>
                        {props.rateAlert.alertStatus === RateAlertStatusEnum.EXPIRED && (
                            <>
                                <div>Expired on&nbsp;</div>
                                <div>{`${formatDateGMTToMYT(props.rateAlert.expireDate, false).date}`}</div>
                            </>
                        )}
                        {props.rateAlert.alertStatus === RateAlertStatusEnum.COMPLETED && (
                            <>
                                <div>Triggered on&nbsp;</div>
                                <div>{`${formatDateGMTToMYT(props.rateAlert.tradeDate as string, false).date}`}</div>
                            </>
                        )}
                        {props.rateAlert.alertStatus === RateAlertStatusEnum.PENDING && (
                            <>
                                <div>Expires on&nbsp;</div>
                                <div>{`${formatDateGMTToMYT(props.rateAlert.expireDate, false).date}`}</div>
                            </>
                        )}
                    </TextWrapper>
                </div>
            </DateMsgContainer>
            <AlertDialog
                titleComp={intl.formatMessage({
                    id: "app.page.rateAlert.deleteModal.title",
                })}

                contentComp={intl.formatMessage({
                    id: "app.page.rateAlert.deleteModal.content",
                })}
                confirmButtonText={intl.formatMessage({
                    id: "app.page.rateAlert.deleteModal.confirm",
                })}
                open={isRateAlertDialogOpen}
                onClose={() => setIsRateAlertDialogOpen(false)}
                onConfirm={() => handleCancelRateAlert()}
            />
        </>
    );
};
