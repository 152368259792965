import styled from "styled-components";

interface SectionHeaderStartContainerProps {
	textWrap?: boolean;
}

const SectionHeaderStartContainer = styled.div<SectionHeaderStartContainerProps>`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export default SectionHeaderStartContainer;
