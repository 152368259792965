import {
	WatchlistAPIResponseType,
	WatchlistType,
} from "../../../../../types/watchlist.type";
import { formatFXObjectList } from "../../../../fx/api/response";

/**
 * Format watchlist API response
 * @param {Array<WatchlistAPIResponseType>} response - Watchlist API response.
 * @return {Array<WatchlistType>} The array of formatted watchlist object.
 */
const formatWatchlist = (
	response: WatchlistAPIResponseType[]
): WatchlistType[] => {
	let formatted: WatchlistType[] = [];

	response.sort((a, b) => a.order - b.order);

	for (let i = 0; i < response.length; i++) {
		formatted[i] = {
			id: response[i].watchlist_id,
			name: response[i].name,
			fxList: formatFXObjectList(response[i].fxlist),
			order: response[i].order,
			isDefault: response[i].isDefault || false,
		};
	}

	return formatted;
};

export default formatWatchlist;
