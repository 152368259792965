import styled from "styled-components";
import { InputFieldContainer } from "../common/InputField";

const FormFieldRow = styled.div`
	display: flex;

	${InputFieldContainer} {
		&:not(:first-child) {
			margin-left: 5px;
		}
		&:not(:last-child) {
			margin-right: 5px;
		}
	}
`;

export default FormFieldRow;
