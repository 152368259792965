import { NEWS_VIRTUALIZED_LIST_ITEM_TYPE } from "../../../constants/news.constant";
import { NewsType } from "../../../types/news.type";

/**
 * Convert news object to list item format (EXPECTED TO VIRTUALIZED ITEM FORMAT)
 * @param {NewsType} item - News object.
 * @param {NEWS_VIRTUALIZED_LIST_ITEM_TYPE} type - Item / Label.
 * @return List item format.
 */
const formatNewsVirtualizedItem = (
	item: NewsType,
	type: NEWS_VIRTUALIZED_LIST_ITEM_TYPE
) => ({
	type,
	item,
});

export default formatNewsVirtualizedItem;
