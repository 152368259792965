import { useState } from "react";
import {
	NavigateFunction,
	NavigateOptions,
	To,
	useNavigate,
} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../utils/store.utils";
import {
	popHistory,
	pushHistory,
	replaceHistory, selectApp, setBackFromPath,
	setNavigateDirection,
} from "../slice/app";
import {
	APP_NAVIGATE_DIRECTION,
	APP_NAVIGATE_TRANSITION_DURATION,
} from "../constants/app.constant";
import app from "../App";

const useNavigateThrottle = (): NavigateFunction => {
	const nav = useNavigate();
	const dispatch = useAppDispatch();
	const [isThrottling, setIsThrottling] = useState<boolean>(false);
	const app = useAppSelector(selectApp)


	const navigate: NavigateFunction = (
		to: To | number,
		options?: NavigateOptions
	) => {
		// console.log("to", to)
		if (!isThrottling) {
			setIsThrottling(true);
			if (typeof to === "number") {
				if (to > 0) {
					dispatch(pushHistory({ path: "" })); // Unexpected
					dispatch(setNavigateDirection(APP_NAVIGATE_DIRECTION.NEXT));
				} else if (to < 0) {
					// console.log(app.history)
					// console.log("back from app.history[-1]", app.history[app.history.length - 1])
					dispatch(setBackFromPath(app.history[app.history.length - 1]))
					dispatch(popHistory(to));
					dispatch(setNavigateDirection(APP_NAVIGATE_DIRECTION.PREVIOUS));
				} else {
					dispatch(replaceHistory({ path: to.toString() }));
					dispatch(setNavigateDirection(APP_NAVIGATE_DIRECTION.REPLACE));
				}
				nav(to);
			} else {
				if (options?.replace) {
					dispatch(replaceHistory({ path: to.toString() }));
					dispatch(setNavigateDirection(APP_NAVIGATE_DIRECTION.REPLACE));
				} else {
					dispatch(pushHistory({ path: to.toString() }));
					dispatch(setNavigateDirection(APP_NAVIGATE_DIRECTION.NEXT));
				}
				// console.log("come from ", app.history[app.history.length - 1])
				nav(to, options);
			}
			setTimeout(() => {
				setIsThrottling(false);
			}, APP_NAVIGATE_TRANSITION_DURATION);
		}
	};

	return navigate;
};

export default useNavigateThrottle;
