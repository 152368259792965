import {mapErrorCodeToErrorMsg,} from "./errorMsg.utilis";

/**
 * Generates an error message based on the error status code and specific toast error message.
 * If a specific toast error message is provided, it will be returned.
 * Otherwise, the error status code will be mapped to an error message using the mapErrorCodeToErrorMsg function.
 *
 * @param {string | undefined | null} errorStatusCode - The error status code.
 * @param {string} [specificToastErrorMsg] - The specific toast error message.
 * @return {string} - The generated error message.
 */
export function generateErrorMsg(
    errorStatusCode: string  |undefined| null,
    specificToastErrorMsg?: string): string {
    if (specificToastErrorMsg) {
        return specificToastErrorMsg
    }else{
        return mapErrorCodeToErrorMsg(errorStatusCode)
    }
}

// const mapErrorCodeToErrorMsg = (errorStatusCode: string, genericErrorMsg: string): string => {
//     const errorStatusCodeLower = errorStatusCode.toLowerCase();
//     if (!["mdip", "arts", "bts"].some(code => errorStatusCodeLower.includes(code))) {
//         return genericErrorMsg;
//     }
//     switch (errorStatusCodeLower) {
//         case "mdip001":
//             return specificErrorMsgEnum.MDIP_001
//         case "bts001":
//             return specificErrorMsgEnum.BTS_001
//         default:
//             return showDefaultMsg(errorStatusCodeLower)
//     }
// }
