import { DEFAULT_NUMPAD_INPUT_DECIMAL_POINT } from "../../../constants/common.constant";

/**
 * Format NumPad input with decimal point
 * @param {string} input - NumPad input value.
 * @param {number=} decimal - Specify decimal point.
 * @return {string} The formatted human readable text.
 */
const formatNumPadInput = (
	input: string,
	decimal = DEFAULT_NUMPAD_INPUT_DECIMAL_POINT
): string => {
	decimal = Math.max(0, ~~decimal);
	if (input.length <= decimal) {
		input = "0." + input.padStart(decimal, "0");
	} else {
		input = [
			input.slice(0, input.length - decimal),
			".",
			input.slice(decimal * -1),
		].join("");
	}
	return input;
};

export default formatNumPadInput;
