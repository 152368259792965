import React from "react";
import { useIntl } from "react-intl";
import Card from "../styled/Card";
import styled from "styled-components";

import background from "../../assets/images/account-card-background.svg";

const StyledAccountCard = styled(Card)`
	height: 124px;
	background-image: url(${background});
	background-repeat: no-repeat;
	background-position: right;
	line-height: 18px;
	overflow: hidden;
`;

const AccountCardContent = styled.div``;

const AccountCardTitle = styled.div`
	max-width: calc(100% - 160px);
	margin-bottom: 4px;
	font-size: 14px;
	font-weight: var(--font-weight-semi-bold);
`;

const AccountCardMessage = styled.div`
	max-width: calc(100% - 128px);
	font-size: 12px;
`;

const AccountCard = ({ ...rest }: React.ComponentPropsWithRef<"div">) => {
	const intl = useIntl();

	return (
		<StyledAccountCard {...rest}>
			<AccountCardContent>
				<AccountCardTitle>
					{intl.formatMessage({
						id: "app.page.watchlist.card.account.title",
					})}
				</AccountCardTitle>
				<AccountCardMessage>
					<div>
						{intl.formatMessage({
							id: "app.page.watchlist.card.account.content.line.1",
						})}
					</div>
					<div>
						{intl.formatMessage({
							id: "app.page.watchlist.card.account.content.line.2",
						})}
					</div>
					<div>
						{intl.formatMessage({
							id: "app.page.watchlist.card.account.content.line.3",
						})}
					</div>
				</AccountCardMessage>
			</AccountCardContent>
		</StyledAccountCard>
	);
};

export default AccountCard;
