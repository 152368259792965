import styled from "styled-components";

import { ReactComponent as ChrevonDownIcon } from "../../assets/icons/chervon-down.svg";

interface BorderedSelectProps extends React.ComponentProps<"div"> {
	textWrap?: boolean;
	children: React.ReactNode;
}

const BorderedSelectText = styled.div`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const StyledBorderedSelect = styled.div<BorderedSelectProps>`
	display: flex;
	justify-content: space-between;
	border: 1px solid var(--secondary-border-color);
	border-radius: 9999px;
	padding: 16px 24px;
	font-size: 14px;
	font-weight: var(--font-weight-semi-bold);
	background-color: var(--bordered-select-background-color);

	${({ textWrap }) => !textWrap && `align-items: center;`}

	${BorderedSelectText} {
		${({ textWrap }) =>
			textWrap &&
			`
			overflow: visible;
			text-overflow: clip;
			word-break: break-word;
		`}
	}

	.mbb-icon {
		flex-shrink: 0;
		margin-left: 8px;

		${({ textWrap }) => textWrap && `margin-top: 4px;`}
	}
`;

const BorderedSelect = ({
	textWrap,
	children,
	className,
	onClick,
}: BorderedSelectProps) => {
	return (
		<StyledBorderedSelect
			className={className}
			textWrap={textWrap}
			onClick={onClick}
		>
			<BorderedSelectText>{children}</BorderedSelectText>
			<ChrevonDownIcon className="mbb-icon" />
		</StyledBorderedSelect>
	);
};

export default BorderedSelect;
