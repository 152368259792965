import { FXType } from "../../../types/fx.type";
import { FX_TRADE_CURRENCY_TYPE } from "../../../constants/fx.constant";
import getRateByDealt from "../getRateByDealt";

/**
 * Currency amount exchange calculation
 * @param {number} value - The amount.
 * @param {string} dealt - The currency symbol of amount.
 * @param {FX_TRADE_CURRENCY_TYPE} type - from or to currency.
 * @param {FXType} [fxObject] - FX data object.
 * @param {number} [rate] - Specific rate, use this rate instead of the rate in FX data object if provided.
 * @return {number} Exchanged amount.
 */
const exchange = (
	value: number,
	dealt: string,
	type: FX_TRADE_CURRENCY_TYPE,
	fxObject?: FXType,
	rate?: number
): number => {
	const realRate = rate ?? getRateByDealt(dealt, fxObject);
	if (!fxObject || realRate === 0 || realRate === null) return 0;

	let newValue: number;
	if (
		(type === FX_TRADE_CURRENCY_TYPE.TARGET &&
			fxObject.currencies[0] !== dealt) ||
		(type === FX_TRADE_CURRENCY_TYPE.SOURCE && fxObject.currencies[0] === dealt)
	) {
		// check dealt also
		newValue = value * Math.max(0, realRate);
	} else {
		newValue = value * (1 / Math.max(0, realRate));
	}

	return newValue;
};

export default exchange;
