import styled from "styled-components";

interface DividerProps {
	margin?: {
		top?: number | string;
		bottom?: number | string;
		left?: number | string;
		right?: number | string;
	};
	padding?: {
		top?: number | string;
		bottom?: number | string;
		left?: number | string;
		right?: number | string;
	};
}

const Divider = styled.div<DividerProps>`
	border-bottom: 1px solid var(--border-color);
	${({ margin }) => {
		let marginCSSText = "";
		if (margin && margin.top)
			marginCSSText += `margin-top: ${
				typeof margin.top === "string" ? margin.top : `${margin.top}px`
			};`;
		if (margin && margin.bottom)
			marginCSSText += `margin-bottom: ${
				typeof margin.bottom === "string" ? margin.bottom : `${margin.bottom}px`
			};`;
		if (margin && margin.left)
			marginCSSText += `margin-left: ${
				typeof margin.left === "string" ? margin.left : `${margin.left}px`
			};`;
		if (margin && margin.right)
			marginCSSText += `margin-right: ${
				typeof margin.right === "string" ? margin.right : `${margin.right}px`
			};`;

		return marginCSSText;
	}}
	${({ padding }) => {
		let paddingCSSText = "";
		if (padding && padding.top)
			paddingCSSText += `padding-top: ${
				typeof padding.top === "string" ? padding.top : `${padding.top}px`
			};`;
		if (padding && padding.bottom)
			paddingCSSText += `padding-bottom: ${
				typeof padding.bottom === "string"
					? padding.bottom
					: `${padding.bottom}px`
			};`;
		if (padding && padding.left)
			paddingCSSText += `padding-left: ${
				typeof padding.left === "string" ? padding.left : `${padding.left}px`
			};`;
		if (padding && padding.right)
			paddingCSSText += `padding-right: ${
				typeof padding.right === "string" ? padding.right : `${padding.right}px`
			};`;

		return paddingCSSText;
	}}
`;

export default Divider;
