import { calculateUpperLowerThreshold } from "./calculateUpperLowerThreshold";
import {
    updateFXOrderFormFutureTargetRate,
    updateFXOrderFormFutureTargetRateString,
    updateRateAlertFutureTargetRate,
    updateRateAlertFutureTargetRateString
} from "../../../../../slice/fx";
import { enqueueSnackbar } from "notistack";
import { FXOrderFormType, FXRateAlertType } from "../../../../../types/fx.type";
import { store } from "../../../../../store/store";
import { getIntlObject } from "../../../../../utils/intl";
import { hasFutureProperty } from "./hasFutureProperty";
import { uqAndPreserveDecimals } from "./uqAndPreserveDecimals";
import { roundToPrecision } from "./roundToPrecision";

export const onEnterAndUnfocusToCheckBound = (
    fxOrderForm: FXOrderFormType | FXRateAlertType,
    defaultRate: number,
    handleFutureInputChange: Function
) => {
    const unitQuote: number = fxOrderForm.fx?.unitQuote as number;
    const maxDecimalPlaces: number = (fxOrderForm.fx?.decimalPoint as number) - unitQuote;
    const { upperThreshold, lowerThreshold } = calculateUpperLowerThreshold(fxOrderForm, defaultRate, maxDecimalPlaces);
    const isFuture: boolean = hasFutureProperty(fxOrderForm);
    const dispatchFutureTargetRate = (data: string, inBound: boolean) => {
        const action = isFuture
            ? updateFXOrderFormFutureTargetRate
            : updateRateAlertFutureTargetRate;
        const actionString = isFuture
            ? updateFXOrderFormFutureTargetRateString
            : updateRateAlertFutureTargetRateString;

        store.dispatch(action(data));

        if (!inBound) {
            const roundedValue = roundToPrecision(Number(uqAndPreserveDecimals(Number(data), unitQuote)), maxDecimalPlaces);
            store.dispatch(actionString(String(roundedValue)));
        }
    };

    const handleOutOfBound = (boundary: number, messageId: string) => {
        // console.log(`exceed ${boundary === lowerThreshold ? "lower" : "upper"} bound`);
        dispatchFutureTargetRate(String(boundary), false);
        if (isFuture) {
            handleFutureInputChange(fxOrderForm, boundary);
        }
        enqueueSnackbar(
            getIntlObject().formatMessage({
                id: messageId,
            }),
            { variant: "general", mode: "info" }
        );
    };

    const futureTargetRate = Number(fxOrderForm.futureTargetRate);

    if (futureTargetRate < lowerThreshold) {
        handleOutOfBound(lowerThreshold, "app.page.orderForm.form.order.label.futureOrder.exceedLowerBound");
    } else if (futureTargetRate > upperThreshold) {
        handleOutOfBound(upperThreshold, "app.page.orderForm.form.order.label.futureOrder.exceedUpperBound");
    } else {
        console.log("In the bound");
        dispatchFutureTargetRate(fxOrderForm.futureTargetRate as string, true);
        if (isFuture) {
            handleFutureInputChange(fxOrderForm, lowerThreshold);
        }
    }
};
