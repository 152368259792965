import { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";

import Section from "../../styled/sections/Section";
import SectionContent from "../../styled/sections/SectionContent";

import SectionHeader from "../../styled/sections/SectionHeader";
import { UserBankAccountType } from "../../../types/user.type";
import BankAccountSelectableCard from "./BankAccountSelectableCard";
import {formatAccountNumber} from "../../../formatters/account/formatAccountNumber";

interface BankAccountSelectorSectionProps {
	title: string;
	accounts: UserBankAccountType[];
	selectedAccountKey?: string;
	onSelect: (account: UserBankAccountType) => void;
	onSliderMove?: () => void;
}

const StyledBankAccountSelectorSection = styled(Section)``;

const StyledSwiper = styled(Swiper)`
	height: 100%;
	overflow: hidden;
	z-index: auto;

	.swiper-slide {
		width: 100%;

		&:first-child {
			padding-left: 24px;
		}

		&:last-child:not(:nth-child(1)) {
			padding-right: 24px;
		}

		&.swiper-slide-active {
		}

		&:not(.swiper-slide-active) {
		}
	}
`;

const SwiperSlideContent = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const BankAccountSelectorSection = ({
	title,
	accounts,
	selectedAccountKey,
	onSelect,
	onSliderMove,
}: BankAccountSelectorSectionProps) => {
	const intl = useIntl();
	const [selectedIndex, setSelectedIndex] = useState<number>(
		accounts.findIndex((v) => v.key === selectedAccountKey) < 0
			? 0
			: accounts.findIndex((v) => v.key === selectedAccountKey)
	);

	// const onSlideChange = (index: number) => {
	// 	setSelectedIndex(index);
	// 	onSelect(accounts[index]);
	// };

	const onClickSelect = (index: number) => {
		setSelectedIndex(index);
		onSelect(accounts[index]);
	};

	return (
		<StyledBankAccountSelectorSection hasMarginBottom>
			<SectionHeader>{title}</SectionHeader>
			<SectionContent>
				<StyledSwiper
					height={89}
					slidesPerView={1.5}
					spaceBetween={12}
					initialSlide={selectedIndex}
					centeredSlides={true} //true b4
					centeredSlidesBounds={true} //true b4
					slideToClickedSlide={true}
					onSliderMove={onSliderMove}
					// onSlideChangeTransitionEnd={(swiper: any) => {
					// 	onSlideChange(swiper.realIndex);
					// }}
					onClick={(swiper: any) => {
						onClickSelect(swiper.realIndex);
					}}

				>
					{accounts.map((v, i) => (
						<SwiperSlide key={`bank-account-selectable-card-slide-${i}`}>
							<SwiperSlideContent>
								<BankAccountSelectableCard
									name={v.label}
									accountNumber={v.id}
									currency={v.currency}
									balanceText={v.balanceText}
									selected={selectedIndex === i}
								/>
							</SwiperSlideContent>
						</SwiperSlide>
					))}
				</StyledSwiper>
			</SectionContent>
		</StyledBankAccountSelectorSection>
	);
};

export default BankAccountSelectorSection;
