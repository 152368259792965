import { UserBankAccountType } from "../../../../types/user.type";

/**
 * Find bank accounts by account types
 * @param {Array<UserBankAccountType>} accounts - Bank accounts for search.
 * @param {Array<string>} types - The currency you want to search by.
 * @return {Array<UserBankAccountType>} Array of filtered bank accounts.
 */
const findBankAccountByTypes = (
	accounts: UserBankAccountType[],
	types: string[]
): Array<UserBankAccountType> => {
	return accounts.filter((v) => types.indexOf(v.type) > -1);
};

export default findBankAccountByTypes;
