import { AxiosResponse } from "axios";
import {
	CancelFutureOrderAPIRequestType,
	ConfirmFXOrderAPIRequestType,
	ConfirmFXOrderAPIResponseType,
	CreateFXOrderAPIRequestType,
	CreateFXOrderAPIResponseType,
	GetRateAPIResponseType,
	S2UInitAPIResponseType,
	S2UTransactionCheckAPIResponseType,
	S2UTransactionCheckAPIRequestType,
	S2UInitAPIRequestType
} from "../types/fx.type";
import moment from "moment";
import {ORDER_STATUS_RATE_ALERT_SHOW_LAST_N_DAYS} from "../constants/fx.constant";
import { CreateRateAlertAPIRequestType} from "../pages/rateAlert/interface/RateAlertInfo";
import {ListRateAlertStateEnum} from "../pages/rateAlert/enums/RateAlertTabEnum";
import {api} from "../utils/api.utils";

/**
 * Represents an interface for interacting with an FX API.
 *
 * @typedef {Object} FXAPIType
 * @property {function} getRate - A function that retrieves the exchange rate for a given set of currencies.
 * @property {function} createOrder - A function that creates a new FX order with the specified payload.
 * @property {function} confirmOrder - A function that confirms an existing FX order with the specified payload.
 * @property {function} cancelOrder - A function that cancels an existing FX order with the specified orderId.
 */
type FXAPIType = {
	getRate: (fxs: string[]) => Promise<AxiosResponse<GetRateAPIResponseType>>;
	createOrder: (
		payload: CreateFXOrderAPIRequestType
	) => Promise<AxiosResponse<CreateFXOrderAPIResponseType>>;
	confirmOrder: (
		payload: ConfirmFXOrderAPIRequestType
	) => Promise<AxiosResponse<ConfirmFXOrderAPIResponseType>>;
	cancelOrder: (orderId: string, reason:string) => Promise<AxiosResponse>;
	getOrderStatusList:(state:ListOrderAPIStateEnum)=>Promise<AxiosResponse>;
	cancelFutureOrder:(payload:string)=>Promise<AxiosResponse>
	getRateAlertList:(state:ListRateAlertStateEnum)=>Promise<AxiosResponse>;
	createRateAlert:(CreateRateAlertAPIRequest: CreateRateAlertAPIRequestType)=>Promise<AxiosResponse>;
	s2uInitMO: (
		payload: S2UInitAPIRequestType
	) => Promise<AxiosResponse<S2UInitAPIResponseType>>;
	s2uTransactionCheck: (
		payload: S2UTransactionCheckAPIRequestType
	) => Promise<AxiosResponse<S2UTransactionCheckAPIResponseType>>;
	s2uInitFO: (
		payload: S2UInitAPIRequestType
	) =>  Promise<AxiosResponse<S2UInitAPIResponseType>>;
};

/**
 * Represents a set of functions to interact with the FX API.
 *
 * @typedef {Object} FXAPIType
 *
 * @property {function(string[]): Promise<any>} getRate - Gets the exchange rate for the specified currencies.
 * @property {function(Object): Promise<any>} createOrder - Creates a new order with the specified payload.
 * @property {function(Object): Promise<any>} confirmOrder - Confirms an order with the specified payload.
 * @property {function(string): Promise<any>} cancelOrder - Cancels an order with the specified ID.
 */
const fxAPI: FXAPIType = {
	getRate: (fxs) => {
       return api.get(`/rate${fxs.length > 0 ? `?fxs=${fxs.join(",")}` : ""}`, {timeout:3000});
	},

	// getRate: (fxs) => {
	// 	const headers = {
	// 		Authorization: 'MDIP500',
	// 	};
	// 	const url = `/rate${fxs.length > 0 ? `?fxs=${fxs.join(",")}` : ""}`;
	// 	return api.get(url, { headers });
	// },

	createOrder: (payload) => {
		return api.post("/createOrder", payload, {timeout:10000});
	},
	confirmOrder: (payload) => {
		return api.post("/acceptQuote", payload, {timeout:10000});
	},
	cancelOrder: (orderId:string, reason:string) => {
		return api.post("/rejectQuote", { orderId:orderId, reason:reason }, {timeout:10000});
	},
	getOrderStatusList:(state:ListOrderAPIStateEnum):Promise<AxiosResponse>=>{
		const body = {"since": getCurrentDateMinusCertainDaysMalaysia(), "state": state}
		return api.post("/listOrder", body, {timeout:10000})
	},

	//this can be also used for cancel rateAlert.... don't ask why
	cancelFutureOrder:(payload: string ):Promise<AxiosResponse>=>{
		const body: CancelFutureOrderAPIRequestType = {
			orderId:payload
		}
		return api.post("cancelOrder", body, {timeout:10000})
	},
	getRateAlertList:(state: ListRateAlertStateEnum):Promise<AxiosResponse>=>{
		const body = {"since": getCurrentDateMinusCertainDaysMalaysia(), "state": state}
		return api.post("listRateAlert", body, {timeout:10000})
	},
	createRateAlert:(CreateRateAlertAPIRequest: CreateRateAlertAPIRequestType):Promise<AxiosResponse>=>{
		const body = CreateRateAlertAPIRequest
		return api.post("createRateAlert", body, {timeout:10000})
	},
	s2uInitMO: (payload) => {
		return api.post("/transactionInitTac", payload);
	},
	s2uInitFO: (payload) => {
		return api.post("/transactionInitTac", payload);
	},
	s2uTransactionCheck: (payload) => {
		return api.post("/transactionCheck", payload);
	},
};

function getCurrentDateMinusCertainDaysMalaysia(): string {
	// Get the current date and time in Malaysia's timezone
	const currentDate = moment().tz('Asia/Kuala_Lumpur');

	// Subtract the specified number of days
	currentDate.subtract(ORDER_STATUS_RATE_ALERT_SHOW_LAST_N_DAYS, 'days');

	// Set the time to the start of the day
	currentDate.startOf('day');

	// Format the new date into the desired string format
	return currentDate.format('YYYYMMDDHHmmss');
}

export enum ListOrderAPIStateEnum {
	All = "all",
	Pending = "pending",
	Unsuccessful = "unsuccessful",
	Completed = "completed",
	Expired = "expired",
	Cancelled = "cancelled"
}

export default fxAPI;
