import { useIntl } from "react-intl";
import styled from "styled-components";

import { openTNCView } from "../../../utils/integration.utils";

import Section from "../../styled/sections/Section";
import SectionContent from "../../styled/sections/SectionContent";
import SectionHeader from "../../styled/sections/SectionHeader";
import Link from "../../styled/Link";

const StyledTNCSection = styled(Section)`
	font-size: 12px;
	font-weight: var(--font-weight-semi-light);
	line-height: 18px;
	letter-spacing: -0.2px;
`;

const TNCSection = () => {
	const intl = useIntl();

	return (
		<StyledTNCSection hasMarginBottom>
			<SectionHeader>
				{intl.formatMessage({ id: "app.page.orderAccount.section.tnc.title" })}
			</SectionHeader>
			<SectionContent hasPaddingX>
				{intl.formatMessage(
					{
						id: "app.page.orderAccount.section.tnc.content",
					},
					{
						bnm: (
							<Link
								onClick={() => {
									openTNCView(
										process.env.REACT_APP_BNM_URL,
										intl.formatMessage({
											id: "app.page.orderAccount.bnm.view.title",
										})
									);
								}}
							>
								{intl.formatMessage({
									id: "app.page.orderAccount.bnm",
								})}
							</Link>
						),
						declaration: (
							<Link
								onClick={() => {
									openTNCView(
										process.env.REACT_APP_DECLARATION_URL,
										intl.formatMessage({
											id: "app.page.orderAccount.declaration.view.title",
										})
									);
								}}
							>
								{intl.formatMessage({
									id: "app.page.orderAccount.declaration",
								})}
							</Link>
						),
						tnc: (
							<Link
								onClick={() => {
									openTNCView(
										process.env.REACT_APP_TNC_URL,
										intl.formatMessage({
											id: "app.page.orderAccount.tnc.view.title",
										})
									);
								}}
							>
								{intl.formatMessage({
									id: "app.page.orderAccount.tnc",
								})}
							</Link>
						),
					}
				)}
			</SectionContent>
		</StyledTNCSection>
	);
};

export default TNCSection;
