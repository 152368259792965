import {RateAlertInfoReal} from "../interface/RateAlertInfo";
import {RateAlertStatusEnum, RateAlertTabEnum} from "../enums/RateAlertTabEnum";
import {OrderStatusInfoReal} from "../../order/interface/OrderStatusInfo";
import {OrderStatusEnum} from "../../order/enums/OrderStatusEnum";

export function filterAndSortReteAlertData (
    rateAlertData: RateAlertInfoReal[] ,
    selectedStatusType: RateAlertTabEnum
): RateAlertInfoReal[] {
    if(selectedStatusType === RateAlertTabEnum.HISTORY){
        return rateAlertData
            .filter(rateAlert => (rateAlert.alertStatus === RateAlertStatusEnum.EXPIRED
                || rateAlert.alertStatus === RateAlertStatusEnum.COMPLETED)
            ).sort((a,b)=> Number(getLastUpdateTime(b))- Number(getLastUpdateTime(a)))
    }else if(selectedStatusType === RateAlertTabEnum.ACTIVE){
        return rateAlertData
            .filter(rateAlert => (rateAlert.alertStatus === RateAlertStatusEnum.PENDING))
            .sort((a,b)=> Number(b.inputDate)- Number(a.inputDate))
    }else{
        return []
    }
}
const getLastUpdateTime = (status: RateAlertInfoReal): string => {
        if (status.alertStatus=== RateAlertStatusEnum.COMPLETED) {
            return status.tradeDate as string
        } else if (status.alertStatus===RateAlertStatusEnum.EXPIRED ){
            return status.expireDate as string
        }else if(status.alertStatus === RateAlertStatusEnum.CANCELLED){
            return status.cancelDate as string
        }else{
            return ""
        }
}
