import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import styled from "styled-components";

import Layout from "../components/common/Layout";
import SectionContent from "../components/styled/sections/SectionContent";
import FieldLabel from "../components/styled/FieldLabel";
import CurrencySelectorDialog from "../components/common/dialog/CurrencySelectorDialog";
import BorderedSelect, {
	StyledBorderedSelect,
} from "../components/styled/BorderedSelect";
import { ReactComponent as SwapArrowIcon } from "../assets/icons/swap-arrow.svg";
import Button from "../components/styled/buttons/Button";
import FooterButtonContainer from "../components/styled/buttons/FooterButtonContainer";
import NationalFlag from "../components/common/NationalFlag";
import InputField, {
	InputFieldContainer,
} from "../components/common/InputField";

import {
	COUNTRY_CURRENCY_MAP,
	DEFAULT_FX_ORDER_FORM_FIELD,
	DFFAULT_CURRENCY_FIELD,
	FX_TRADE_CURRENCY_TYPE,
	MAX_FX_TRADE_AMOUNT,
} from "../constants/fx.constant";
import { findExchangeableCurrenciesInFXList } from "../formatters/fx";
import useFXCalculator from "../hooks/useFXCalculator";
import { useAppDispatch, useAppSelector } from "../utils/store.utils";
import {
	selectCurrencies,
	selectFXCalculatorForm,
	selectFXList, selectFXUnitQuote,
	updateFXTradeForm,
	updateFXTradeFormFX, updateUnitQuote,
} from "../slice/fx";
import { CurrencyType, FXTradeType, FXType } from "../types/fx.type";

import {
	formatNumPadInput,
	formatNumPadInputToField,
} from "../formatters/common";

import fxAPI from "../api/fx.api";
import { formatFXObjectList } from "../formatters/fx/api/response";
import {renderRate} from "../components/order/CalculationCard";
import {getDpUqBySymbol} from "../components/fx/service/getDpUqBySymbol";
import {updateFxConfigDpUqConfigWithRate} from "../slice/fxConfig";
import { roundNumber } from "../components/order/FutureOrderInput/FutureInputField/services/roundNumber";
import { cleanRET_APPandRET_DCCookie } from "../utils/cleanCookieRET_APPandRET_DCCookie";
import {
	RATE_DATA_ASK_BID_DP_SYMBOL_NULL,
	RATE_DATA_IS_EMPTY_OR_INVALID,
	ERROR_RESPONSE_CODE
} from "../constants/errorMsg.constant";
import { processApiError } from "../utils/errorHandling.utils";
import { emitErrorToApp } from "../utils/integration.utils";
import { ErrorObjForToastBar } from "../types/errorObjType.type";
import { isArray } from "../utils/verifyType.utils";
import axios from "axios";
import { errorHasNonZeroResponseCode } from "src/utils/errorHasNonZeroResponseCode";

const Container = styled(SectionContent)`
	margin-top: 18px;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow-y: auto;
	${StyledBorderedSelect} {
		padding: 8px 24px;
		border: 1px solid var(--border-color);
	}
`;
const Label = styled(FieldLabel)`
	margin-bottom: 8px;
`;
export const IconWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 8px;
	margin-bottom: -8px;
`;
const AmountLabel = styled.div`
	margin-top: 24px;
	margin-bottom: 16px;
	font-size: 14px;
`;
const AmountReceiveLabel = styled(AmountLabel)`
	margin-top: 8px;
`;
export const LabelNation = styled.div`
	font-weight: var(--font-weight-semi-bold);
	font-size: 14px;
`;
export const NationWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: 10px;
	padding: 3px;
`;

const NoteText = styled.div`
	color: var(--text-color-secondary);
	font-size: 12px;
	margin-bottom: 44px;
`;
const CurrencyLabel = styled.div`
	width: 83px;
	margin-bottom: 16px;
	border-bottom: 1px solid var(--input-border-color);
	font-size: 20px;
	font-weight: var(--font-weight-semi-bold);
`;
const RelativeWrapper = styled.div`
	position: relative;
	flex-grow: 1;
`;
const RateText = styled.div`
	font-weight: var(--font-weight-semi-bold);
	font-size: 12px;
	position: absolute;
	bottom: -4px;
`;
const CurrencyExchangeWrapper = styled.div`
	${InputFieldContainer} {
		flex-grow: 1;
	}
	display: flex;
	column-gap: 10px;
`;
export const SelectTextWrapper = styled.div`
	margin: 6px 0;
`;
const CurrencyCalculatorPage = () => {
	const intl = useIntl();
	const sourceCurrencyInputRef = useRef<HTMLInputElement>(null);
	const targetCurrencyInputRef = useRef<HTMLInputElement>(null);
	const { enqueueSnackbar } = useSnackbar();
	const currencies = useAppSelector(selectCurrencies);
	const fxList = useAppSelector(selectFXList);
	const fxCalculatorForm = useAppSelector(selectFXCalculatorForm);
	const dispatch = useAppDispatch();
	const {
		availableCurrencies,
		focusingCurrencySelector,
		focusingInput,
		setFocusingInput,
		isFooterButtonContainerShow,
		setIsFooterButtonContainerShow,
		isNumPadOpen,
		setIsNumPadOpen,
		isCurrencySelectorDialogOpen,
		updateFocusingInputAmount,
		handleCurrencySelectorOpen,
		handleCurrencySelectorClose,
		handleNumPadInputClick,
		handleExchange,
	} = useFXCalculator({
		form: "calculator",
		sourceInputElement: sourceCurrencyInputRef.current,
		targetInputElement: targetCurrencyInputRef.current,
	});
	/**
	 * Updates the FXTradeForm when a currency is edited.
	 *
	 * @param {CurrencyType} updatedCurrency - The updated currency object.
	 */
	const fxUnitQuote = useAppSelector(selectFXUnitQuote)
	const handleEditCurrency = (updatedCurrency: CurrencyType) => {
		let updatedFXCalculatorForm: FXTradeType = JSON.parse(
			JSON.stringify(fxCalculatorForm)
		);
		if (!updatedFXCalculatorForm[focusingCurrencySelector]) return;

		// Handle user selected a currency that is same as opposite currency
		if (focusingCurrencySelector === FX_TRADE_CURRENCY_TYPE.SOURCE) {
			if (
				updatedCurrency.currency === fxCalculatorForm.target.currency ||
				findExchangeableCurrenciesInFXList(
					fxList,
					currencies,
					updatedCurrency.currency
				).length < 1
			) {
				// Swap currency and amount
				/*updatedFXCalculatorForm.target = {
					currency: fxCalculatorForm.source.currency,                                                                            
					amount: fxCalculatorForm.source.amount,
				};
				updatedFXCalculatorForm.source.amount = fxCalculatorForm.target.amount;*/

				// Reset target currency
				updatedFXCalculatorForm.target = DEFAULT_FX_ORDER_FORM_FIELD.target;
			}
			if (updatedCurrency.currency !== fxCalculatorForm.source.currency) {
				updatedFXCalculatorForm.source.amount = DFFAULT_CURRENCY_FIELD.amount;
			} else {
				return;
			}
		} else if (
			focusingCurrencySelector === FX_TRADE_CURRENCY_TYPE.TARGET &&
			updatedCurrency.currency === fxCalculatorForm.source.currency &&
			fxCalculatorForm.target.currency
		) {
			// IT SHOULD NOT HAPPEN SINCE TARGET CURRENCY IS PAIR OF SOURCE CURRENCY
			// Swap currency and amount
			/*updatedFXCalculatorForm.source = {
				currency: fxCalculatorForm.target.currency,
				amount: fxCalculatorForm.target.amount,
			};
			updatedFXCalculatorForm.target.amount = fxCalculatorForm.source.amount;*/
		}
		updatedFXCalculatorForm[focusingCurrencySelector].currency =
			updatedCurrency.currency;
		updatedFXCalculatorForm[focusingCurrencySelector].name =
			updatedCurrency.name;
		delete updatedFXCalculatorForm.fx;
		// In case has selected CCY2 , if change the CCY1, CCY2 must be reset if the current CCY2 is not in CCY1 available list
		if (
			focusingCurrencySelector === FX_TRADE_CURRENCY_TYPE.SOURCE &&
			fxCalculatorForm.target.currency &&
			!findExchangeableCurrenciesInFXList(
				fxList,
				currencies,
				updatedCurrency.currency
			).find((item) => item.currency === fxCalculatorForm.target.currency)
		) {
			updatedFXCalculatorForm.target = DFFAULT_CURRENCY_FIELD;
		}
		handleExchange();
		dispatch(
			updateFXTradeForm({ form: "calculator", data: updatedFXCalculatorForm })
		);
	};
	/**
	 * Opens the currency selector and handles the click event for the specified currency type.
	 *
	 * @param {FX_TRADE_CURRENCY_TYPE} currencyType - The currency type (either SOURCE or TARGET).
	 */
	const handleCurrencySelectClick = (currencyType: FX_TRADE_CURRENCY_TYPE) => {
		handleCurrencySelectorOpen(
			currencyType,
			currencyType === FX_TRADE_CURRENCY_TYPE.SOURCE
				? currencies
				: findExchangeableCurrenciesInFXList(
						fxList,
						currencies,
						fxCalculatorForm.source.currency
				  )
		);
	};

	/**
	 * Handle NumPad Key Click
	 *
	 * @param {string} type - The type of action to perform. Must be either "add" or "remove".
	 * @param {number} [num] - The number to be added or removed. This parameter is optional.
	 * @returns {Function} - A function that updates the focusing input amount.
	 */
	const handleNumPadKeyClick = (type: "add" | "remove") => (num?: number) => {
		updateFocusingInputAmount(type, num);
	};
	/**
	 * Checks if the calculation can be performed.
	 *
	 * @returns {boolean} Returns true if the calculation can be performed, otherwise false.
	 */
	const ableToCalculate = (): boolean => {
		// Form validation
		const sourceCurrency = currencies.find(
			(v) => v.currency === fxCalculatorForm.source.currency
		);
		const targetCurrency = currencies.find(
			(v) => v.currency === fxCalculatorForm.target.currency
		);
		if (
			!sourceCurrency ||
			!targetCurrency ||
			fxCalculatorForm.source.amount.value === 0
		)
			return false;

		return true;
	};
	/**
	 * Handles the click event triggered when the calculate button is clicked.
	 * It fetches the exchange rate data using the fetchRate function,
	 * and if the data is available, it triggers the handleExchange function
	 * with the fetched exchange rate and sets the ask rate as the bid rate.
	 *
	 * @returns {Promise<void>} A promise that resolves when the click event is handled.
	 */
	const handleCalculateClick = async () => {
		const fxObject = await fetchRate();
		if (fxObject) handleExchange({ ...fxObject, ask: fxObject?.bid });
	};

	/**
	 * Fetches the exchange rate between two currencies.
	 *
	 * @returns {Promise<FXType | undefined>} The exchange rate as an FXType object, or undefined if the rate could not be fetched.
	 */
	const fetchRate = async (): Promise<FXType | undefined> => {
		if (!fxCalculatorForm.source.currency || !fxCalculatorForm.target.currency)
			return;

		const fx = fxList.find(
			(v) =>
				v.symbol ===
					`${fxCalculatorForm.source.currency}${fxCalculatorForm.target.currency}` ||
				v.symbol ===
					`${fxCalculatorForm.target.currency}${fxCalculatorForm.source.currency}`
		);
		if (!fx) return;

		try {
			const response = await fxAPI.getRate([fx.symbol]);


			if (response.data.responseCode !== "0") throw {
				errorMsg: ERROR_RESPONSE_CODE,
				responseHttpStatus: response.status,
				responseData: response.data
			} as ErrorObjForToastBar
			if (

				!response.data.data ||
				response.data.data.length === 0 ||
				!isArray(response.data.data)
			) throw {
				errorMsg: RATE_DATA_IS_EMPTY_OR_INVALID,
				responseHttpStatus: response.status,
				responseData: response.data
			} as ErrorObjForToastBar
			if (
				!response.data.data[0].ask
				|| !response.data.data[0].bid
				|| !response.data.data[0].symbol
				// || !response.data.data[0].dp
			) throw {
				errorMsg: RATE_DATA_ASK_BID_DP_SYMBOL_NULL,
				responseHttpStatus: response.status,
				responseData: response.data
			} as ErrorObjForToastBar




			const fxObject = formatFXObjectList(response.data.data)[0];
			dispatch(updateFXTradeFormFX({ form: "calculator", data: fxObject }));
			dispatch(updateUnitQuote(response.data.data[0].uq))
			dispatch(updateFxConfigDpUqConfigWithRate({symbol:response.data.data[0].symbol, ask:response.data.data[0].ask as number,  dp:response.data.data[0].dp, uq:  response.data.data[0].uq}))
			return fxObject;
		} catch (error) {
			console.error(error);
			if (errorHasNonZeroResponseCode(error)) {
				processApiError(error, undefined) //this is non-FATAL
			} else {
				enqueueSnackbar(
					intl.formatMessage({
						id: "app.page.currencyCalculator.getRate.error",
					}),
					{ variant: "general", mode: "info" }
				);
			}
		}

		return;
	};
	/**
	 * Handles the swapping of source and target currencies in the FX calculator form.
	 */
	const handleSwapCurrency = () => {
		if (!fxCalculatorForm.target.currency) return;
		let cloneFxCalculatorForm: FXTradeType = structuredClone(fxCalculatorForm);
		cloneFxCalculatorForm.source = {
			amount: {
				input: "",
				value: 0,
			},
			currency: fxCalculatorForm.target.currency,
			name: fxCalculatorForm.target.name,
		};
		cloneFxCalculatorForm.target = {
			amount: {
				input: "",
				value: 0,
			},
			currency: fxCalculatorForm.source.currency,
			name: fxCalculatorForm.source.name,
		};
		delete cloneFxCalculatorForm.fx;
		dispatch(
			updateFXTradeForm({ form: "calculator", data: cloneFxCalculatorForm })
		);
		setIsNumPadOpen(false);
	};
	/**
	 * Sets the fx property of the clone of fxCalculatorForm to null and dispatches
	 * an action to update the FXTradeForm with the modified clone.
	 *
	 * @function setEmptyTargetAmount
	 * @returns {undefined}
	 */
	const setEmptyTargetAmount = () => {
		let cloneFxCalculatorForm: FXTradeType = structuredClone(fxCalculatorForm);
		delete cloneFxCalculatorForm.fx;
		dispatch(
			updateFXTradeForm({ form: "calculator", data: cloneFxCalculatorForm })
		);
	};
	useEffect(() => {
		setFocusingInput(sourceCurrencyInputRef.current);
	}, []);
	useEffect(() => {
		setEmptyTargetAmount();
	}, [fxCalculatorForm.source.amount.value]);

	return (
		<Layout
			title={intl.formatMessage({
				id: "app.page.currencyCalculator.header.title",
			})}
			numPadProps={{
				open: isNumPadOpen,
				onNumberKeyClick: handleNumPadKeyClick("add"),
				onBackspaceKeyClick: handleNumPadKeyClick("remove"),
				onClose: () => {
					setIsNumPadOpen(false);
				},
				onExited: () => {
					setIsFooterButtonContainerShow(true);
				},
			}}
		>
			<Helmet>
				<title>
					{intl.formatMessage({
						id: "app.page.currencyCalculator.header.title",
					})}
				</title>
			</Helmet>
			<Container hasPaddingX>
				<Label>
					{intl.formatMessage({
						id: "app.page.currencyCalculator.from",
					})}
				</Label>
				<BorderedSelect
					onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();
						handleCurrencySelectClick(FX_TRADE_CURRENCY_TYPE.SOURCE);
						setIsNumPadOpen(false);
					}}
				>
					<NationWrapper>
						<NationalFlag
							country={
								COUNTRY_CURRENCY_MAP[
									fxCalculatorForm.source.currency.toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
								]
							}
							width={24}
							height={24}
						/>
						<LabelNation>
							{`${fxCalculatorForm.source.currency.toUpperCase()} - ${
								fxCalculatorForm.source.name
							}`}
						</LabelNation>
					</NationWrapper>
				</BorderedSelect>
				<IconWrapper>
					<SwapArrowIcon onClick={handleSwapCurrency} />
				</IconWrapper>
				<Label>
					{intl.formatMessage({
						id: "app.page.currencyCalculator.to",
					})}
				</Label>
				<BorderedSelect
					onClick={(event) => {
						event.preventDefault();
						event.stopPropagation();
						handleCurrencySelectClick(FX_TRADE_CURRENCY_TYPE.TARGET);
						setIsNumPadOpen(false);
					}}
				>
					{fxCalculatorForm.target.currency ? (
						<NationWrapper>
							<NationalFlag
								country={
									COUNTRY_CURRENCY_MAP[
										fxCalculatorForm.target.currency.toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
									]
								}
								width={24}
								height={24}
							/>
							<LabelNation>
								{`${fxCalculatorForm.target.currency.toUpperCase()} - ${
									fxCalculatorForm.target.name
								}`}
							</LabelNation>
						</NationWrapper>
					) : (
						<SelectTextWrapper>
							{intl.formatMessage({
								id: "app.page.currencyCalculator.selectCurrency",
							})}
						</SelectTextWrapper>
					)}
				</BorderedSelect>
				<AmountLabel>
					{intl.formatMessage({
						id: "app.page.currencyCalculator.enterAmount",
					})}
				</AmountLabel>
				<CurrencyExchangeWrapper>
					<CurrencyLabel>{fxCalculatorForm.source.currency}</CurrencyLabel>
					<InputField
						value={formatNumPadInputToField(
							fxCalculatorForm.source.amount.input,
							true
						)}
						fullWidth
						inputProps={{
							type: "text",
							ref: sourceCurrencyInputRef,
							readOnly: true,
							onClick: handleNumPadInputClick(sourceCurrencyInputRef.current),
						}}
						helperText={fxCalculatorForm.source.amount.helper?.text}
						helperTextProps={{
							type: fxCalculatorForm.source.amount.helper?.type,
						}}
					/>
				</CurrencyExchangeWrapper>
				{fxCalculatorForm.fx && (
					<>
						<AmountReceiveLabel>
							{intl.formatMessage({
								id: "app.page.currencyCalculator.amountReceive",
							})}
						</AmountReceiveLabel>
						<CurrencyExchangeWrapper>
							<CurrencyLabel>{fxCalculatorForm.target.currency}</CurrencyLabel>
							<RelativeWrapper>
								<InputField
									value={formatNumPadInputToField(
										fxCalculatorForm.target.amount.input,
										true
									)}
									fullWidth
									inputProps={{
										type: "text",
										ref: targetCurrencyInputRef,
										readOnly: true,
									}}
									helperText={fxCalculatorForm.target.amount.helper?.text}
									helperTextProps={{
										type: fxCalculatorForm.target.amount.helper?.type,
									}}
								/>


								<RateText>
									{renderRate(
										fxCalculatorForm.fx.currencies[0],
										fxCalculatorForm.fx.currencies[1],
										fxCalculatorForm.fx.bid as number,
										getDpUqBySymbol(fxCalculatorForm.fx?.symbol!).dp,
										getDpUqBySymbol(fxCalculatorForm.fx?.symbol!).uq
									)}
								</RateText>
							</RelativeWrapper>
						</CurrencyExchangeWrapper>
					</>
				)}
			</Container>
			{isFooterButtonContainerShow && (
				<FooterButtonContainer>
					<NoteText>
						{intl.formatMessage({
							id: "app.page.currencyCalculator.note",
						})}
					</NoteText>
					<Button
						theme="primary"
						size="lg"
						disabled={!ableToCalculate()}
						onClick={handleCalculateClick}
					>
						{intl.formatMessage({
							id: "app.page.currencyCalculator.calculate",
						})}
					</Button>
				</FooterButtonContainer>
			)}
			<CurrencySelectorDialog
				open={isCurrencySelectorDialogOpen}
				availableCurrencies={availableCurrencies}
				onClose={handleCurrencySelectorClose}
				onConfirm={handleEditCurrency}
			/>
		</Layout>
	);
};

export default CurrencyCalculatorPage;
