import styled from "styled-components";
import { useIntl } from "react-intl";

import Section from "../styled/sections/Section";
import SectionContent from "../styled/sections/SectionContent";
import FXNationalFlag, { StyledFXNationalFlag } from "../common/FXNationalFlag";

import { FXType } from "../../types/fx.type";
import { COUNTRY_CURRENCY_MAP } from "../../constants/fx.constant";

import { ReactComponent as AlertAddIcon } from "../../assets/icons/alert-add.svg";
import Anchor from "../styled/Anchor";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import {formatURL} from "../../formatters/common";

interface FXDashboardSectionProps {
	fxObject: FXType;
	onFXClick: () => void;
}

const FXDashboard = styled.div`
	display: flex;
	align-items: center;
`;

const FXText = styled.span`
	flex: 1;
	margin-left: 6px;
	margin-right: 6px;
	font-size: 20px;
	font-weight: var(--font-weight-bold);
	line-height: 31px;
`;

const StyledFXDashboardSection = styled(Section)`
	background-color: #fff;

	${SectionContent} {
		padding-top: 20px;
		padding-bottom: 20px;

		${StyledFXNationalFlag} {
			margin-right: 6px;
		}

		.mbb-icon {
			display: none;
			margin-left: 6px;
		}
	}
`;

const FXDashboardSection = ({
	fxObject,
	onFXClick,
}: FXDashboardSectionProps) => {
	const intl = useIntl();
	const navigate = useNavigateThrottle();
	return (
		<StyledFXDashboardSection hasMarginBottom hasBackgroundColor>
			<SectionContent hasPaddingX>
				<FXDashboard>
					<FXNationalFlag
						source={
							COUNTRY_CURRENCY_MAP[
								fxObject.currencies[0].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
							]
						}
						target={
							COUNTRY_CURRENCY_MAP[
								fxObject.currencies[1].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
							]
						}
						width={36}
						height={36}
					/>
					<FXText>
						<Anchor onClick={() => onFXClick()}>
							{intl.formatMessage(
								{ id: "app.common.fx" },
								{
									source: fxObject.currencies[0],
									target: fxObject.currencies[1],
								}
							)}
						</Anchor>
					</FXText>
					<AlertAddIcon className="" onClick={()=>{
						navigate(formatURL(`/rateAlert/view`))
					}} />
				</FXDashboard>
			</SectionContent>
		</StyledFXDashboardSection>
	);
};

export default FXDashboardSection;
