import { useIntl } from "react-intl";
import styled from "styled-components";

import Anchor from "../styled/Anchor";
import FXNationalFlag from "../common/FXNationalFlag";
import Section from "../styled/sections/Section";
import SectionContent from "../styled/sections/SectionContent";

import { FXType } from "../../types/fx.type";
import {
	COUNTRY_CURRENCY_MAP,
	DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT,
	FX_TRADE_CURRENCY_TYPE,
} from "../../constants/fx.constant";
import { formatNumber } from "../../utils/common.utils";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import LoadingContainer from "../styled/LoadingContainer";
import LoadingSpinner from "../styled/LoadingSpinner";

type FXAmountSectionProps = {
	source: {
		currency: string;
		amount: number;
	};
	target: {
		currency: string;
		amount: number;
	};
	selectingCurrencyType: FX_TRADE_CURRENCY_TYPE;
	fxObject: FXType;
} & (
	| {
			page: "account";
	  }
	| {
			page: "confirmation";
			isLoading: boolean;
	  }
);

const StyledFXAmountSection = styled(Section)`
	margin-top: 16px;

	${SectionContent} {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
`;

const FXText = styled.div`
	margin-top: 13px;
	margin-bottom: 16px;
	font-size: 14px;
	font-weight: var(--font-weight-semi-bold);
`;

const TradeDirectionText = styled.div`
	font-size: 14px;
	font-weight: var(--font-weight-light);
	line-height: 18px;
`;

const AmountText = styled.div`
	font-size: 24px;
	font-weight: var(--font-weight-bold);
	line-height: 31px;
`;

const AmountContainer = styled.div`
	text-align: center;
`;

const FXAmountSection = (props: FXAmountSectionProps) => {
	const navigate = useNavigateThrottle();
	const intl = useIntl();

	return (
		<StyledFXAmountSection hasMarginBottom>
			<SectionContent hasPaddingX>
				<FXNationalFlag
					source={
						COUNTRY_CURRENCY_MAP[
							props.fxObject.currencies[0].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
						]
					}
					target={
						COUNTRY_CURRENCY_MAP[
							props.fxObject.currencies[1].toUpperCase() as keyof typeof COUNTRY_CURRENCY_MAP
						]
					}
					width={48}
					height={48}
				/>
				<FXText>
					{intl.formatMessage(
						{ id: "app.common.fx" },
						{
							source: props.fxObject.currencies[0],
							target: props.fxObject.currencies[1],
						}
					)}
				</FXText>
				<AmountContainer>
					{props.page === "account" && (
						<TradeDirectionText>
							{intl.formatMessage({
								id:
									props.selectingCurrencyType === FX_TRADE_CURRENCY_TYPE.SOURCE
										? "app.page.orderAccount.label.sell"
										: "app.page.orderAccount.label.buy",
							})}
						</TradeDirectionText>
					)}
					<AmountText>
						{props.page === "confirmation" ? (
							<>
								{props.isLoading ? (
									<LoadingContainer>
										<LoadingSpinner />
									</LoadingContainer>
								) : (
									<Anchor
										onClick={() => {
											navigate(-2);
										}}
									>
										{props.source.currency}{" "}
										{formatNumber(
											props.source.amount,
											DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
										)}
									</Anchor>
								)}
							</>
						) : (
							`${props[props.selectingCurrencyType].currency} ${formatNumber(
								props[props.selectingCurrencyType].amount,
								DEFAULT_FX_ORDER_CURRENCY_AMOUNT_DECIMAL_POINT
							)}`
						)}
					</AmountText>
				</AmountContainer>
			</SectionContent>
		</StyledFXAmountSection>
	);
};

export default FXAmountSection;
