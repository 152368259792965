// ----authAPI start----
export const RESPONSE_ERROR_OR_USER_DATA_INVALID = "Response is failure, or data invalid"
export const fxlist_OR_ccylist_IS_FALSY_OR_EMPTY = "fxlist or ccylist is empty or falsy"
export const USER_DATA_TYPE_COUNTRY_BASECURRECY_FROMCURRENCY_MISSING = "User's type or country or baseCurrency or fromCurrency mssing"
// ----authAPI end----


// ----rateAPI start----
export const RATE_DATA_IS_EMPTY_OR_INVALID = "Rate data is empty or invalid"
export const RATE_DATA_ASK_BID_DP_SYMBOL_NULL = "Rate data ask, or bid, or dp, or symbol is null"
// ----rateAPI end----

// ---accountAPI start----
export const ACCOUNT_FAIL_OR_DATA_IS_INVALID = "Account data is empty or invalid"

// ----marketOrderAPI start----
export const MARKET_ORDER_CREATE_ORDER_FAIL = "Market order's create order api fail"
export const MARKET_ORDER_CREATE_ORDER_MISSING_DATA = "Market order's create order api missing data"

export const MARKET_ORDER_CONFIRM_ORDER_FAIL = "Market order's create order api fail"
// ----marketOrderAPI end----

// ----futureOrderAPI start----
export const FUTURE_ORDER_CREATE_ORDER_FAIL = "Future order's create order api fail"
//---- futureOrderAPI end----

export const GET_ORDER_STATUS_LIST_INVALID = "There is issue for getting order status list"
export const CANCEL_RATE_ALERT_FAIL = "Cancel rate alert fail"
export const CREATE_RATE_ALERT_RETURN_UNSUCCESSFUK = "Create rate alert fail"
export const CANCEL_FUTURE_ORDER_FAIL = "Cancel future order fail"

// ----rejectQuoteReason----

export const REJECT_QUOTE_REJECT_BY_UI = "Reject by UI: "
export const REJECT_QUOTE_USER_LEAVE_PAGE = `${REJECT_QUOTE_REJECT_BY_UI}User click back button and leave order page`

export const REJECT_QUOTE_USER_CLOSE_ORDER_FORM = `${REJECT_QUOTE_REJECT_BY_UI}User closed order form page`


export const ERROR_RESPONSE_CODE = "Error response code"
export const MDIP_DISPLAY_MSG = "The session has expired. Please close this message to return to the previous screen."
