import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { ACCOUNT_ROLE } from "../constants/common.constant";
import {
	UserType,
	UserBasicInformationType,
	UserBankAccountType,
	UserAccountInformationType,
} from "../types/user.type";

const initialState: UserType = {
	type: ACCOUNT_ROLE.NOMFCA,
	tokens: {
		market: {
			token: "",
		},
	},
	baseCurrency: "MYR",
	country: "MYS",
	availableCurrencies: [],
	accounts: [],
	tier: undefined,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	// The `reducers` field lets us define reducers and generate associated actions
	reducers: {
		updateUserBasicInformation: (
			state,
			action: PayloadAction<UserBasicInformationType>
		) => {
			return { ...state, ...action.payload };
		},
		updateUserAccountInformation: (
			state,
			action: PayloadAction<UserAccountInformationType>
		) => {
			return { ...state, ...action.payload };
		},
		updateUserTier:(state, action: PayloadAction<number>) => {
			state.tier = action.payload;
		},
	},
});

export const { updateUserBasicInformation,
	updateUserAccountInformation,
	updateUserTier,
} =
	userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectUser = (state: RootState) => state.user;
export const selectUserTier = (state: RootState) => state.user.tier;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default userSlice.reducer;
