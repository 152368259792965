import {showServiceUnavailableSnackbar} from "./snackbar.utils";
import {
    ACCOUNT_FAIL_OR_DATA_IS_INVALID,
    fxlist_OR_ccylist_IS_FALSY_OR_EMPTY, MARKET_ORDER_CREATE_ORDER_FAIL, MARKET_ORDER_CREATE_ORDER_MISSING_DATA,
    RATE_DATA_IS_EMPTY_OR_INVALID, RESPONSE_ERROR_OR_USER_DATA_INVALID,
    USER_DATA_TYPE_COUNTRY_BASECURRECY_FROMCURRENCY_MISSING
} from "../constants/errorMsg.constant";

import axios from "axios";
import {ErrorForToastBar, ErrorObjForToastBar} from "../types/errorObjType.type";
import {setIsWentBackAndToggledFalse} from "../slice/app";
import {isWentBackAndToggledFalseEnum} from "../types/app.type";
import {checkResponseCodeHasMDIP} from "./checkResponseCodeHasMDIP";
import {emitErrorToApp} from "./integration.utils";
import { addOverlay } from "./addOverlay";

// Reusable error handling function
/**
 * Function to manage API error responses.
 *
 * @param {ErrorObjForToastBar | any} error - The error object.
 * @param {() => void} [onCloseFunc] - Optional function to execute on close.
 * @param {string} [specificToastErrorMsg] - Optional specific toast error message.
 */
export const manageApiErrorResponse = (
    error: (ErrorObjForToastBar | any)
    , onCloseFunc?: () => void,
    specificToastErrorMsg?: string,
    isFatal?: boolean
) => {
    // alert(error.message)
    console.error(error)
    console.error("error.errorMsg", error?.errorMsg);
    console.error("error.responseData", error?.responseData)
    console.error("error.responseData.responseCode", error?.responseData?.responseCode)


    //Explanation: it is fine to pass null, as it is optional
    let responseCode = null;
    if (error.responseData && error.responseData.responseCode) {
        responseCode = error.responseData.responseCode;
    }
    if(isFatal){
        addOverlay()
    }

    showServiceUnavailableSnackbar(responseCode, onCloseFunc, specificToastErrorMsg);

}

/**
 * Processes an API error and manages the appropriate response.
 *
 * @param {unknown | ErrorObjForToastBar} error - The error object to be processed.
 * @param {() => void} [callbackFn] - Optional callback function to be called after processing the error.
 * @param {string} [specificToastErrorMsg] - Optional specific error message for displaying in a toast message.
 *
 * @return {void}
 */
export function processApiError(
    error: unknown | ErrorObjForToastBar,
    callbackFn?: () => void,
    specificToastErrorMsg?: string,
    isFatal?: boolean
) {

    //@ts-ignore

    if (axios.isAxiosError(error)) {
        console.log('this is axios error');
        const convertedError: ErrorObjForToastBar = {
            errorMsg: error.message,
            responseHttpStatus: error.response!.status,
            responseData: error.response?.data ?? null
        };
        //this is to prevent if MDIP carries 403 and make it fall to http error and miss MDIP
        // if(error.response){
        //     if(error.response.data){
        //         // @ts-ignore
        //         if(error.response.data.responseCode){ //check this is define or not
        //             // @ts-ignore
        //             if(checkResponseCodeHasMDIP(error.response.data.responseCode)){
        //                 manageApiErrorResponse(convertedError, emitErrorToApp, MDIP_DISPLAY_MSG);
        //             }
        //         }
        //     }
        // }
        manageApiErrorResponse(convertedError, callbackFn, specificToastErrorMsg, isFatal);
    } else if (isInstanceOfErrorObjForToastBar(error)) {
        manageApiErrorResponse(error, callbackFn, specificToastErrorMsg, isFatal);
    } else {
        manageApiErrorResponse({errorMsg: 'An unknown error occurred'}, callbackFn, undefined, isFatal);
    }
}

/**
 * Checks if the given object is an instance of ErrorObjForToastBar.
 *
 * @param {any} obj - The object to be checked.
 * @returns {boolean} - True if the object is an instance of ErrorObjForToastBar, false otherwise.
 */
function isInstanceOfErrorObjForToastBar(obj: any): obj is ErrorObjForToastBar {
    return typeof obj.errorMsg === 'string' &&
        (typeof obj.responseHttpStatus === 'number' || obj.responseHttpStatus === null) &&
        obj.hasOwnProperty('responseData'); // This checks for the existence of responseData, but not its type
}
