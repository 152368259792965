import {
	UpdateWatchlistAPIRequestType,
	WatchlistType,
} from "../../../../../types/watchlist.type";

/**
 * Format edit watchlist form data to API request payload.
 * @param {WatchlistType} watchlist - The edit watchlist form data.
 * @return {UpdateWatchlistAPIRequestType} The edit watchlist API request payload.
 */
const formatWatchlistUpdatePayload = (
	watchlist: WatchlistType
): UpdateWatchlistAPIRequestType => {
	return {
		watchlist_id: watchlist.id,
		name: watchlist.name,
		fxlist: watchlist.fxList,
		order: watchlist.order,
	};
};

export default formatWatchlistUpdatePayload;
