import styled from "styled-components";

const Link = styled.a`
	color: var(--link-text-color);
	font-weight: var(--link-font-weight);
	text-decoration: var(--link-text-decoration);
	cursor: pointer;
	user-select: none;
`;

export default Link;
