import * as React from 'react';
import {OrderStatusInfo, OrderStatusInfoReal} from "../order/interface/OrderStatusInfo";
import {MAX_NUM_RATE_ALERT, NUM_ITEMS_IN_ORDER_STATUS} from "../../constants/fx.constant";
import {PageSelector} from "../order/orderStatus/PageSelector";
import {OrderStatusEnum} from "../order/enums/OrderStatusEnum";
import {CardsContainer} from "./CardContainer";
import {NoRateAlertAvailable} from "../rateAlert/components/NoRateAlertAvailable";
import {RateAlertTabEnum} from "../rateAlert/enums/RateAlertTabEnum";
import {isValueInEnum} from "../rateAlert/services/isValueInEnum";
import Button from "../../components/styled/buttons/Button";
import {BoxButtonContainer} from "../../commonStyle/BoxButtonContainer";
import styled from "styled-components";
import {formatURL} from "../../formatters/common";
import useNavigateThrottle from "../../hooks/useNavigateThrottle";
import {useIntl} from "react-intl";
import {RateAlertInfo} from "../rateAlert/interface/RateAlertInfo";
import SectionContent from "../../components/styled/sections/SectionContent";
import Section from "../../components/styled/sections/Section";
import {useAppSelector} from "../../utils/store.utils";
import {selectFxRateAlertList} from "../../slice/fx";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import {countPendingAlerts} from "../rateAlert/services/countActiveRateAlert";
import {useEffect, useRef, useState} from "react";
import {cleanRET_APPandRET_DCCookie} from "../../utils/cleanCookieRET_APPandRET_DCCookie";
import {ACTIVATED_ACCOUNT_ROLE} from "../../constants/common.constant";
import {clearPasswordTimeoutInterval, requestPasswordAuthentication} from "../../utils/integration.utils";
import {selectUser} from "../../slice/user";
import {IntegrationMessageDataType} from "../../types/integration.type";
import {MAE_APP_RESPONSE_EVENT_TYPE} from "../../constants/integration.constant";
import {updateAPIAxiosInstanceToken} from "../../utils/api.utils";
import errorTimeout from "../../slice/errorTimeout";
import {selectRaMaxActiveCount} from "../../slice/app";

type Props = {
    data: RateAlertInfo[] | OrderStatusInfoReal[], //todo limit the type of data
    currentPage: number,
    onChangePage: (direction: number) => void
    selectedType: RateAlertTabEnum | OrderStatusEnum
};
export const BoxContainer = (props: Props) => {
    const intl = useIntl();
    const navigate = useNavigateThrottle();
    const rateAlertListRedux = useAppSelector(selectFxRateAlertList)

    const numOfItems: number = props.data.length
    const firstItemNum = (props.currentPage - 1) * NUM_ITEMS_IN_ORDER_STATUS + 1
    const lastItemNum: number = (() => {
        const isTotalItemLargerThanMaxItemOfPage = props.currentPage * NUM_ITEMS_IN_ORDER_STATUS < numOfItems;
        if (isTotalItemLargerThanMaxItemOfPage) {
            return props.currentPage * NUM_ITEMS_IN_ORDER_STATUS;
        } else {
            return numOfItems;
        }
    })();
    const dataFilteredByPaginator: OrderStatusInfoReal[] | RateAlertInfo[] = props.data.slice(firstItemNum - 1, lastItemNum)
    const isRateAlert: boolean = isValueInEnum(RateAlertTabEnum, props.selectedType)
    const isOrderStatus = isValueInEnum(OrderStatusEnum, props.selectedType)
    const isOrderStatusPendingType = props.selectedType === OrderStatusEnum.PENDING
    const displayPaginator: boolean = numOfItems > NUM_ITEMS_IN_ORDER_STATUS
    const displayButton: boolean = isRateAlert ? true : isOrderStatusPendingType
    const containerRef = useRef<HTMLDivElement>(null);  // Reference to the scrollable div
    const [isAtTop, setIsAtTop] = useState(true);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const user = useAppSelector(selectUser);
    const errorTimeout = useAppSelector((state) => state.errorTimeout);
    const raMaxActiveCount = useAppSelector(selectRaMaxActiveCount)

    useEffect(() => {
        cleanRET_APPandRET_DCCookie()
    }, []);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = 0;  // Reset scroll to top
        }
        checkScroll()
    }, [props.data]);

    const checkScroll = () => {
        if (containerRef.current) {
            // console.log('checkScroll activated')
            const {scrollTop, clientHeight, scrollHeight} = containerRef.current;
            // console.log("scrollTop", scrollTop)
            // console.log("clientHeight", clientHeight)
            // console.log("scrollHeight", scrollHeight)
            setIsAtTop(!(scrollTop > 10));
            setIsAtBottom(scrollTop + clientHeight > scrollHeight - 10);
        }
    };
    // console.log("isAtTop from boxContainer", isAtTop)
    // console.log("isAtBottom from boxContainer", isAtBottom)
    const handleScroll = (event: any) => {
        checkScroll()
    };

    const handleCreateNewRateAlert = (): void => {
        if (countPendingAlerts(rateAlertListRedux) >= raMaxActiveCount) {
            enqueueSnackbar(
                intl.formatMessage({
                    id: "app.page.rateAlert.delete.toastbar.reachedMaxNumAlert",
                }), {
                    variant: "general",
                    mode: 'info',
                }
            )
            return
        }
        navigate(formatURL("/rateAlert/create/step1"))
    }

    const handleOrderFutureOrderAgain = () => {
        if (ACTIVATED_ACCOUNT_ROLE.includes(user.type)) {
            window.addEventListener("message", handleAuthenticate); // iOS
            document.addEventListener("message", onMessageHandler); // Android
            requestPasswordAuthentication();
        }
    }

    const handleAuthenticate = (message: MessageEvent<string>) => {
        try {
            const formattedData: IntegrationMessageDataType = JSON.parse(
                message.data
            );
            switch (formattedData.type) {
                case MAE_APP_RESPONSE_EVENT_TYPE.PASSWORD:
                    if (errorTimeout.passwordResponseRemainTime > 0) {
                        const token = formattedData.data //this is L3 token, according to the DRS
                        if (token) {
                            updateAPIAxiosInstanceToken(token)
                        }
                        navigate(
                            formatURL("/order/form/step-1",)
                        );
                        clearPasswordTimeoutInterval()
                        closeSnackbar()
                        window.removeEventListener("message", handleAuthenticate); // iOS
                        document.removeEventListener("message", onMessageHandler); // Android
                    }
                    break;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const onMessageHandler: EventListener = (event: any) => {
        if (
            event.type &&
            event.type === "message" &&
            event.data &&
            typeof event.data === "string"
        ) {
            handleAuthenticate(event);
        }
    };

    return (
        <>
            {
                numOfItems > 0 ?
                    <>
                        <ScrollableWrapper>
                            {!isAtTop && <GradientTop/>}
                            <OverFlowContainer onScroll={handleScroll} ref={containerRef}>
                                <CardsContainer
                                    dataFilteredAfterPaginatior={dataFilteredByPaginator}
                                />
                            </OverFlowContainer>
                            {!isAtBottom && <GradientBottom/>}
                        </ScrollableWrapper>

                        {(displayButton || displayPaginator) ?
                            <ButtonPagingContainer>
                                {displayButton &&
                                    <BoxButtonContainer
                                        havePageAndButton={displayPaginator && !displayButton}
                                        isAtBottom={isAtBottom}
                                    >
                                        {isValueInEnum(RateAlertTabEnum, props.selectedType) &&
                                            <Button
                                                style={{width: "90%", height: 48,}}
                                                theme="primary"
                                                onClick={handleCreateNewRateAlert}
                                            >
                                                {intl.formatMessage({
                                                    id: "app.page.createRateAlert.createNewAlert",
                                                })}
                                            </Button>
                                        }
                                        {/*{props.selectedType === OrderStatusEnum.PENDING &&*/}
                                        {/*    <Button*/}
                                        {/*        style={{width: "90%", height: 48}}*/}
                                        {/*        theme="primary"*/}
                                        {/*        onClick={() => handleOrderFutureOrderAgain()}*/}
                                        {/*        className={"setFutureOrderButton1"}*/}
                                        {/*    >*/}
                                        {/*        {intl.formatMessage({*/}
                                        {/*            id: "app.page.orderStatus.button.text.setFutureOrder",*/}
                                        {/*        })}*/}
                                        {/*    </Button>*/}
                                        {/*}*/}
                                    </BoxButtonContainer>
                                }

                                {displayPaginator &&
                                    <PageSelector
                                        firstItemNum={firstItemNum}
                                        lastItemNum={lastItemNum}
                                        totalNumOfItems={numOfItems}
                                        onChangePage={props.onChangePage}
                                        currentPage={props.currentPage}
                                        havePageButNoButton={displayPaginator && !displayButton}
                                    />
                                }
                            </ButtonPagingContainer>
                            :
                            <>
                                <div style={{
                                    background: isAtBottom ? '' : 'linear-gradient(to bottom, rgba(239, 239, 243, 1), transparent)',
                                    height: '50px', // adjust height as needed
                                }}>
                                </div>
                            </>
                        }
                    </>
                    :
                    <div className={"helloWorld"} style={{height: "100%"}}>
                        {isValueInEnum(RateAlertTabEnum, props.selectedType)
                            ? (
                                <NoRateAlertAvailable
                                    selectedType={props.selectedType}
                                    handleCreateNewRateAlert={handleCreateNewRateAlert}
                                />
                            )
                            : (
                                <NoRateAlertAvailable
                                    selectedType={props.selectedType}
                                    handleCreateNewRateAlert={handleCreateNewRateAlert}
                                />
                            )
                        }
                    </div>

            }

            {/*below code exist, to cater that in order status,
            even there is no item, there should be a "Set Future Orde" button r*/}
            {/*{*/}
            {/*    numOfItems === 0 && props.selectedType === OrderStatusEnum.PENDING &&*/}
            {/*    <>*/}
            {/*        <OverFlowContainer ref={containerRef}>*/}
            {/*            <CardsContainer*/}
            {/*                dataFilteredAfterPaginatior={dataFilteredByPaginator}*/}
            {/*            />*/}
            {/*        </OverFlowContainer>*/}
            {/*        <ButtonPagingContainer>*/}
            {/*        {displayButton &&*/}
            {/*                <BoxButtonContainer*/}
            {/*                    havePageAndButton={displayPaginator && !displayButton}*/}
            {/*                    isAtBottom={isAtBottom}*/}
            {/*                >*/}
            {/*                    /!*<Button*!/*/}
            {/*                    /!*    style={{width: "90%", height: 48}}*!/*/}
            {/*                    /!*    className={"setFutureOrderButton2"}*!/*/}
            {/*                    /!*    theme="primary"*!/*/}
            {/*                    /!*    onClick={() => navigate(formatURL("/order/form/step-1"))}*!/*/}
            {/*                    /!*>*!/*/}
            {/*                    /!*    {intl.formatMessage({*!/*/}
            {/*                    /!*        id: "app.page.orderStatus.button.text.setFutureOrder",*!/*/}
            {/*                    /!*    })}*!/*/}
            {/*                    /!*</Button>*!/*/}
            {/*                </BoxButtonContainer>*/}
            {/*            }*/}
            {/*        </ButtonPagingContainer>*/}
            {/*    </>*/}
            {/*}*/}
        </>
    );
};

interface ButtonPagingContainerProps {
    // maxHeight: string; // Making maxHeight optional
}

const ButtonPagingContainer = styled.div<ButtonPagingContainerProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 30px;
    //background-color: var(--background-color-primary);
    /* Media query for newer iPhone models like iPhone SE (2nd gen), iPhone 6/7/8 */

`

const ScrollableWrapper = styled.div`
    position: relative;
    flex: 1 1 auto;
    overflow: hidden;
`;

const GradientTop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to bottom, rgba(239, 239, 243, 1), transparent);
    pointer-events: none;
    z-index: 100;
`;

const GradientBottom = styled.div`
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to top, rgba(239, 239, 243, 1), transparent);
    pointer-events: none;
    z-index: 100;
`;

const OverFlowContainer = styled.div`
    overflow-y: auto;
    position: relative;
    flex: 1 1 auto;
    height: 100%;
`;
