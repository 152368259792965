import { createIntl, createIntlCache } from "react-intl";
import {
	DEFAULT_LANGUAGE,
	DEFAULT_LOCALIZATION,
	LOCALE_LOCAL_STORAGE_KEY,
} from "../constants/common.constant";
import { getLocalStorage } from "./common.utils";

/**
 * Retrieves an Intl object with the specified locale and messages.
 *
 * @function
 *
 * @returns {Intl} The Intl object that is created based on the specified locale and messages.
 *
 * @example
 * // Usage in Redux
 * const localeFromRedux = 'en';
 * const messagesFromRedux = {
 *   en: {
 *     greeting: 'Hello',
 *     goodbye: 'Goodbye'
 *   },
 *   fr: {
 *     greeting: 'Bonjour',
 *     goodbye: 'Au revoir'
 *   }
 * };
 *
 * const getIntlObject = () => {
 *   const cache = createIntlCache();
 *   const intl = createIntl(
 *     { locale: localeFromRedux, messages: messagesFromRedux[localeFromRedux] },
 *     cache
 *   );
 *
 *   return intl;
 * }
 *
 * getIntlObject(); // Returns an Intl object with the 'en' locale and messages
 *
 * // Usage with regular import
 * import enMessages from './locales/en.json';
 * import frMessages from './locales/fr.json';
 *
 * const getIntlObject = () => {
 *   const localStorageLocale = getLocalStorage<string>(
 *     LOCALE_LOCAL_STORAGE_KEY,
 *     false
 *   );
 *   const locale =
 *     (localStorageLocale as keyof typeof DEFAULT_LOCALIZATION) ||
 *     DEFAULT_LANGUAGE;
 *   const cache = createIntlCache();
 *   const intl = createIntl(
 *     { locale: locale, messages: DEFAULT_LOCALIZATION[locale] },
 *     cache
 *   );
 *
 *   return intl;
 * }
 *
 * getIntlObject(); // Returns an Intl object with the locale and messages based on localStorage or default values
 */
export const getIntlObject = () => {
	const localStorageLocale = getLocalStorage<string>(
		LOCALE_LOCAL_STORAGE_KEY,
		false
	);
	const locale =
		(localStorageLocale as keyof typeof DEFAULT_LOCALIZATION) ||
		DEFAULT_LANGUAGE;
	const cache = createIntlCache();
	const intl = createIntl(
		{ locale: locale, messages: DEFAULT_LOCALIZATION[locale] },
		cache
	); //locale and message can come from Redux or regular import

	return intl;
};
