import styled from "styled-components";
import { StyledItem } from "./items/Item";

const List = styled.div`
	${StyledItem} {
		&:not(:first-child) {
			margin-top: 16px;
		}
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
`;

export default List;
