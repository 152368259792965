import { FXObjectAPIResponseType, FXType } from "../../../../../types/fx.type";
import { formatFXSymbolToCurrencies } from "../../../";

/**
 * Format FX object list API response
 * @param {Array<FXObjectAPIResponseType>} response - The API response FX object list
 * @return {Array<FXType>} The formatted array of FX object.
 */
const formatFXObjectList = (response: FXObjectAPIResponseType[]): FXType[] => {
	let formatted: FXType[] = [];

	for (let i = 0; i < response.length; i++) {
		formatted[i] = {
			symbol: response[i].symbol,
			currencies: formatFXSymbolToCurrencies(response[i].symbol),
			bid: response[i].bid || null,
			ask: response[i].ask || null,
			decimalPoint: response[i].dp,
			unitQuote: response[i].uq
		};
	}

	return formatted;
};

export default formatFXObjectList;
